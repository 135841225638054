import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toggleLoader } from '../redux/loader.slicer';
import { GetAdminUser } from '../services/admin.service';
import { toggleModal } from '../redux/modal.slicer';
import { deleteUserAccount } from '../services/user.service';
import ModalComponents from '../components/modal/Modal.Components';

const UserDeletePageComponent = () => {
    const [user, setUser] = useState({});
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
      dispatch(toggleLoader(true));
      GetAdminUser(params.user_id)
        .then((res) => {
          setUser(res.data[0]);
        })
        .catch((err) => toast.error(err.response.data))
        .finally(() => dispatch(toggleLoader(false)));
    }, [dispatch, params.user_id]);

    const onChangeSubmit = (e) => {
      e.preventDefault();
      dispatch(toggleLoader(true));
      deleteUserAccount({user_id: params.user_id})
        .then((res) => {
          toast.success(res.data);
          dispatch(toggleModal(false));
          navigate(`/dashboard/users`);
        })
        .catch((err) => toast.error(err.response.data))
        .finally(() => dispatch(toggleLoader(false)));
    };

  return (
    <ModalComponents>
      <div className="col-12 col-sm-6 m-auto my-3 text-start">
        <form onSubmit={onChangeSubmit}>
          <div className="mb-3">
            <label className="form-label text-center">
              Da li zaista želite da obrišete Korisnika{' '}
              <span className="text-danger">
                {user.first_name} {user.last_name}
              </span>
              <br />
              <span className="text-danger">{user.email}</span>
            </label>
          </div>
          <button type="submit" className="btn btn-danger form-control">
            Obriši korisnika
          </button>
        </form>
        <Link
          className="btn btn-outline-secondary form-control mt-3"
          to={`/dashboard/user/${user.user_id}`}
        >
          Nazad
        </Link>
      </div>
    </ModalComponents>
  );
}

export default UserDeletePageComponent;