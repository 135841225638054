import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserAccount } from '../../services/user.service';
import { toast } from 'react-toastify';
import { toggleModal } from '../../redux/modal.slicer';
import ModalComponents from '../modal/Modal.Components';
import { removeUserFromLocalStorage } from '../../services/auth.service';

const UserDeleteAccount = () => {
  const userStore = useSelector((state) => state.userStore.user); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [isLogged, setIsLogged] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userStore) {
      setIsLogged(true);
    } else {
      navigate('/');
    }
  }, [navigate, userStore]);

  const onChangeSubmit = (e) => {
    e.preventDefault();
    deleteUserAccount({ user_id: userStore.user_id })
      .then((res) => {
        toast.success(res.data);
        removeUserFromLocalStorage();
        dispatch(toggleModal(false));
        navigate(`/`);
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  return (
    <ModalComponents>
      {isLogged && (
        <div className="col-12 col-sm-6 m-auto my-3 text-start">
          <form onSubmit={onChangeSubmit}>
            <div className="mb-3">
              <label htmlFor="city" className="form-label text-center">
                Da li zaista želite da obrišete sve vaše sačuvane podatke i uklonite nalog sa Termosolar sajta?
              </label>
            </div>
            <button type="submit" className="btn btn-danger form-control">
              Obriši nalog
            </button>
          </form>
        </div>
      )}
    </ModalComponents>
  );
};

export default UserDeleteAccount;
