import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { GiShoppingCart } from 'react-icons/gi';
import { toggleLoader } from '../redux/loader.slicer';
import { useDispatch } from 'react-redux';
import { GetAllUserHistoryOrders } from '../services/admin.service';

const AdminUserOrderHistoryPageComponent = () => {
  const [orders, setOrders] = useState([]);
  const [paginationObject, setPaginationObject] = useState({
    offset: 0,
    totalItems: 0,
    limit: 20,
  });
  const [searchValue, setSearchValue] = useState('');
  const limitNum = [5, 10, 15, 20];
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get('keyword')) {
      dispatch(toggleLoader(true));
      GetAllUserHistoryOrders(
        searchParams.get('keyword'),
        paginationObject.limit,
        paginationObject.offset
      )
        .then((res) => {
          setOrders(res.data.orders);
          setPaginationObject(res.data.pagination);
        })
        .catch((err) => console.log(err))
        .finally(() => dispatch(toggleLoader(false)));
    } else {
      dispatch(toggleLoader(true));
      GetAllUserHistoryOrders(
        null,
        paginationObject.limit,
        paginationObject.offset
      )
        .then((res) => {
          setOrders(res.data.orders);
          setPaginationObject(res.data.pagination);
        })
        .catch((err) => console.log(err.response.data))
        .finally(() => dispatch(toggleLoader(false)));
    }
  }, [dispatch, paginationObject.limit, paginationObject.offset, searchParams]);

  const onClickHandle = (num) => {
    setPaginationObject((prevState) => ({ ...prevState, offset: num }));
  };

  const onClickPrevious = () => {
    if (paginationObject.offset > 0) {
      setPaginationObject((prevState) => ({
        ...prevState,
        offset: prevState.offset - 1,
      }));
    }
  };

  const onClickNext = () => {
    let pagesNumber = Math.ceil(
      paginationObject.totalItems / paginationObject.limit
    );
    if (paginationObject.offset + 1 < pagesNumber) {
      setPaginationObject((prevState) => ({
        ...prevState,
        offset: prevState.offset + 1,
      }));
    }
  };

  const renderPaginationNumber = () => {
    let pagesNumber = Math.ceil(
      paginationObject.totalItems / paginationObject.limit
    );
    const numPagesDisplayed = 5;
    let pagesArray = [];
    let currentPage = paginationObject.offset + 1;
    if (pagesNumber <= numPagesDisplayed) {
      pagesArray = [...Array(pagesNumber).keys()].map((num) => num + 1);
    } else {
      let startPage = Math.max(
        1,
        currentPage - Math.floor(numPagesDisplayed / 2)
      );
      let endPage = Math.min(pagesNumber, startPage + numPagesDisplayed - 1);

      if (startPage === 1) {
        endPage = numPagesDisplayed;
      } else if (endPage === pagesNumber) {
        startPage = pagesNumber - numPagesDisplayed + 1;
      }
      pagesArray = [...Array(endPage - startPage + 1).keys()].map(
        (num) => startPage + num
      );
    }

    return (
      <>
        <li
          className="page-item"
          onClick={() => onClickHandle(0)}
          disabled={paginationObject.offset === 0}
        >
          <p className="page-link" aria-label="First">
            <span aria-hidden="true">««</span>
          </p>
        </li>
        {pagesArray.map((num) => (
          <li
            key={'page-num-' + num}
            className={
              paginationObject.offset === num - 1
                ? 'active page-item'
                : 'page-item'
            }
            aria-current="page"
            onClick={() => onClickHandle(num - 1)}
          >
            <p className="page-link">{num}</p>
          </li>
        ))}
        <li
          className="page-item"
          onClick={() => onClickHandle(pagesNumber - 1)}
          disabled={paginationObject.offset === pagesNumber - 1}
        >
          <p className="page-link" aria-label="Last">
            <span aria-hidden="true">»»</span>
          </p>
        </li>
      </>
    );
  };

  const renderLimitDropDown = () => {
    return limitNum.map((num, index) => {
      return (
        <li key={index}>
          <p
            className="dropdown-item"
            onClick={() =>
              setPaginationObject((prevState) => ({
                ...prevState,
                limit: num,
                offset: 0,
              }))
            }
          >
            {num}
          </p>
        </li>
      );
    });
  };

  const onSearchSubmit = (e) => {
    e.preventDefault();
    if (searchValue.keyword === 'null')
      return navigate(`/dashboard/orders-history`);

    navigate(
      `/dashboard/orders-history?keyword=${searchValue.keyword
        .replaceAll(' ', '-')
        .toLowerCase()}&limit=${paginationObject.limit}&offset=${
        paginationObject.offset
      }`
    );
  };

  const onChangeValue = (e) => {
    let inputValue = e.target.value.trim();
    let inputName = e.target.name;
    if (inputValue === '') {
      inputValue = 'null';
    }
    let copySearchValue = { ...searchValue };
    copySearchValue[inputName] = inputValue;
    setSearchValue(copySearchValue);
  };

  const renderTable = () => {
    return orders.map((order, index) => {
      return (
        <tr id={order.user_id} key={index + '_' + order.order_id}>
          <th scope="row">{index + 1}</th>
          <td>{order.created_at}</td>
          <td>{`${order.first_name} ${order.last_name}`}</td>
          <td>{order.email}</td>
          <td>{order.orders_number}</td>
          <td>
            <Link to={`/dashboard/orders-history/${order.user_id}`}>
              <h6 className="text-center text-bg-danger fs-4 m-0">
                <GiShoppingCart />
              </h6>
            </Link>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="container-lg">
      <h1 className="text-center fs-3 my-3">Završene Porudžbine</h1>
      <div>
        <nav className="navbar bg-body-tertiary">
          <div className="container-fluid justify-content-center ">
            <form
              onSubmit={(e) => onSearchSubmit(e)}
              className="d-flex flex-column gap-2"
              role="search"
            >
              <div className="d-flex gap-3">
                <input
                  className="form-control"
                  type="search"
                  name="keyword"
                  placeholder="Pretraži"
                  aria-label="Search"
                  onChange={(e) => onChangeValue(e)}
                />
                <button className="btn btn-sm btn-outline-danger" type="submit">
                  Pretraži
                </button>
              </div>
            </form>
          </div>
        </nav>
      </div>
      <div className="table-responsive">
        <table className="table table-sm">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Od dana</th>
              <th scope="col">Ime</th>
              <th scope="col">Email</th>
              <th scope="col">Količina</th>
              <th scope="col">Detaljnije</th>
            </tr>
          </thead>
          <tbody>{renderTable()}</tbody>
        </table>
      </div>
      <div className="d-flex flex-wrap gap-3 justify-content-center mt-3 pagination-holder">
        <div className="dropdown">
          <button
            className="btn btn-sm btn-outline-secondary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Limit {paginationObject.limit}
          </button>
          <ul className="dropdown-menu">{renderLimitDropDown()}</ul>
        </div>
        <nav aria-label="Page navigation">
          <ul className="pagination pagination-sm justify-content-center">
            <li className="page-item" onClick={() => onClickPrevious()}>
              <p className="page-link" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </p>
            </li>
            {renderPaginationNumber()}
            <li className="page-item" onClick={() => onClickNext()}>
              <p className="page-link" aria-label="Next">
                <span aria-hidden="true">»</span>
              </p>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default AdminUserOrderHistoryPageComponent;
