import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toggleLoader } from '../redux/loader.slicer';
import { saveCategory } from '../redux/category.slicer';
import { toggleModal } from '../redux/modal.slicer';
import { GetMainAdminCategory } from '../services/admin.service';

const CategoryPageComponent = () => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState([]);

  useEffect(() => {
    dispatch(toggleLoader(true));
    GetMainAdminCategory()
      .then((res) => {
        setCategory(res.data);
        dispatch(saveCategory(res.data)); // cuvamo kategorije u redux
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(toggleLoader(false)));
  }, [dispatch]);

  const renderCategory = () => {
    return category.map((cat) => {
        return (
          <tr id={cat.category_id} key={cat.category_id}>
            <th scope="row">{cat.category_id}</th>
            <td>{cat.name}</td>
            <td>{cat.subname}</td>
            <td>{cat.description}</td>
            <td>{cat.parent__category_id}</td>
            <td className="d-flex flex-column gap-3">
              <Link
                className="btn btn-sm btn-outline-info"
                to={`/dashboard/category/${cat.category_id}`}
              >
                Podkategorije
              </Link>
              <Link
                className="btn btn-sm btn-outline-warning"
                to={`/dashboard/category/change/${cat.category_id}`}
              >
                Izmeni
              </Link>
              <Link
                className="btn btn-sm btn-outline-success"
                to={`/dashboard/upload/${cat.category_id}`}
                onClick={() => dispatch(toggleModal(true))}
              >
                Fotografija
              </Link>
              <Link
                className="btn btn-sm btn-outline-danger"
                to={`/dashboard/category/delete/${cat.category_id}`}
                onClick={() => dispatch(toggleModal(true))}
              >
                Obriši
              </Link>
            </td>
          </tr>
        );
    })
  }

  return (
    <>
      <h2 className="fs-3 text-center mt-3">Kategorije</h2>
      <div>
        <Link to="/dashboard/category/add">Dodaj glavnu kategoriju</Link>
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">id</th>
            <th scope="col">Ime</th>
            <th scope="col">Podnaslov</th>
            <th scope="col">Opis</th>
            <th scope="col">id kategorije iznad</th>
            <th scope="col">Akcija</th>
          </tr>
        </thead>
        <tbody>{renderCategory()}</tbody>
      </table>
    </>
  );
}

export default CategoryPageComponent;