import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { toggleLoader } from '../redux/loader.slicer';
import { GetAdminArticleById, GetAllAdminCategory, UpdateAdminArticle } from '../services/admin.service';
import { GetCategory } from '../services/article.service';

const ArticleChangePageComponent = () => {
  const [article, setArticle] = useState({});
  const [category, setCategory] = useState([]);
  const [categoryOne, setCategoryOne] = useState({});
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(toggleLoader(true));
    GetAdminArticleById(params.article_id)
      .then((res) => {
        setArticle(res.data[0]);
        GetAllAdminCategory()
          .then((res) => {
            setCategory(res.data);
          })
          .catch((err) => console.log(err));
        GetCategory(res.data[0].category_id)
          .then((res) => setCategoryOne(res.data[0]))
          .catch((err) => console.log(err));
      })
      .catch((err) => toast.error(err.response.data))
      .finally(() => dispatch(toggleLoader(false)));
  }, [dispatch, params.article_id]);

  const handleChange = (e) => {
    let inputValue = e.target.value;
    let inputName = e.target.name;
    let copyArticle = { ...article };
    copyArticle[inputName] = inputValue;
    setArticle(copyArticle);
  };

  const onChangeSubmit = (e) => {
    e.preventDefault();
    dispatch(toggleLoader(true));
    UpdateAdminArticle(article)
      .then((res) => {
        toast.success(res.data);
        navigate(`/dashboard/article/category/${article.category_id}#${article.article_key}`);
      })
      .catch((err) => toast.error(err.response.data))
      .finally(() => dispatch(toggleLoader(false)));
  };

  const renderCategorySelect = () => {
    return category.map((cat) => {
      return (
        <option
          key={cat.category_id}
          value={cat.category_id}
        >
          {cat.name}
        </option>
      );
    });
  };

  const articleStatus = () => {
    let text = '';
    switch (article.status) {
      case 'available':
        text = 'Na stanju'
        break;
      case 'visible':
        text = 'Nije dostupno'
        break;
      case 'hidden':
        text = 'Sakriveno'
        break;
      default:
        break;
    };
    return text;
  }

  const articleIsPromoted = () => {
    let text = '';
    if (article.is_promoted === 0) {
      text = 'Ne';
    } else {
      text = 'Da';
    };
    return text;
  }

  return (
    <div className="col-12 col-sm-6 m-auto my-3 text-start">
      <h3 className="text-center fs-5 mb-3 text-danger">{article.name}</h3>
      <form onSubmit={onChangeSubmit}>
        <div className="mb-3">
          <label htmlFor="category_id" className="form-label">
            Proizvod je u kategoriji:
            <span className="text-success"> {categoryOne.name}</span>
          </label>
          <br />
          <select
            id="category_id"
            name="category_id"
            value={article.category_id}
            onChange={(e) => handleChange(e)}
          >
            {renderCategorySelect()}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="article_key" className="form-label">
            Šifra proizvoda:
          </label>
          <input
            type="number"
            name="article_key"
            className="form-control"
            id="article_key"
            aria-describedby="article_key"
            onChange={(e) => handleChange(e)}
            defaultValue={article.article_key}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Ime proizvoda:
          </label>
          <input
            type="text"
            name="name"
            className="form-control"
            id="name"
            aria-describedby="nameHelp"
            onChange={(e) => handleChange(e)}
            maxLength={128}
            defaultValue={article.name}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exceptt" className="form-label">
            Kratak opis proizvoda:
          </label>
          <textarea
            className="form-control"
            id="exceptt"
            name="exceptt"
            onChange={(e) => handleChange(e)}
            maxLength={255}
            rows={2}
            defaultValue={article.exceptt}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Opis proizvoda:
          </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            onChange={(e) => handleChange(e)}
            maxLength={255}
            rows={4}
            defaultValue={article.description}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="status" className="form-label me-3">
            Status proizvoda:
            <span className="text-success ms-3">{articleStatus()}</span>
          </label>
          <select
            id="status"
            name="status"
            value={article.status}
            onChange={(e) => handleChange(e)}
          >
            <option value="available">Na stanju</option>
            <option value="visible">Nije dostupno</option>
            <option value="hidden">Sakriveno</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="is_promoted" className="form-label me-3">
            Proizvod je na promociji:
            <span className="text-success ms-3">{articleIsPromoted()}</span>
          </label>
          <select
            id="is_promoted"
            name="is_promoted"
            onChange={(e) => handleChange(e)}
            value={article.is_promoted}
          >
            <option value="0">
              Ne
            </option>
            <option value="1">
              Da
            </option>
          </select>
        </div>
        <div className="d-flex justify-content-between">
          <button type="submit" className="btn btn-danger">
            Sačuvaj promene
          </button>
          <Link
            className="btn btn-outline-secondary "
            to={`/dashboard/article/category/${article.category_id}#${article.article_key}`}
          >
            Nazad
          </Link>
        </div>
      </form>
    </div>
  );
}

export default ArticleChangePageComponent;