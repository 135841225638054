import axios from 'axios';

export const activateAccount = (user_id) => axios.get(`/user/activate-account/${user_id}`);
export const getUserByEmail = (email) => axios.post(`/user/forgot-password`, email);
export const resetPassword = (user_id) => axios.get(`/user/reset-password/${user_id}`);

export const getUserById = (user_id) => axios.get(`/user/${user_id}`);

export const changeUserPassword = (payload) => axios.put(`/user/change-password`, payload);
export const changeUserFirstName = (payload) => axios.put(`/user/change-first-name`, payload);
export const changeUserLastName = (payload) => axios.put(`/user/change-last-name`, payload);
export const changeUserGender = (payload) => axios.put(`/user/change-gender`, payload);
export const changeUserPhone = (payload) => axios.put(`/user/change-phone`, payload);
export const changeUserStreet = (payload) => axios.put(`/user/change-street`, payload);
export const changeUserNumber = (payload) => axios.put(`/user/change-number`, payload);
export const changeUserCity = (payload) => axios.put(`/user/change-city`, payload);
export const changeUserPostal = (payload) => axios.put(`/user/change-postal`, payload);
export const deleteUserAccount = (payload) => axios.post(`/user/delete-account`, payload);

export const setUserCart = (payload) => axios.post('/user/category/:category_id/article/:article_id/key/:article_key/limit/:limit/offset/:offset', payload)
export const findUserCart = (payload) => axios.post('/user/cart', payload);
export const setUserDataCart = (payload) => axios.post('user/cart/user-data', payload);
export const purchaseConfirmation = (payload) => axios.post('user/cart/purchase-confirmation', payload);
export const userOrders = (payload) => axios.post('user/cart/user-orders', payload);
export const userOrdersHistory = (payload) => axios.post('user/cart/user-history', payload);
export const deleteUserCart = (cart_id) => axios.get(`/user/cart/delete/${cart_id}`);

export const changeUserToLocalStorage = (data) => localStorage.setItem('termosolar', JSON.stringify(data));
