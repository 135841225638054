import React from 'react';
import ProInvoiceComponent from '../components/proInvoice/ProInvoice.Component';
//window.location.reload();
const CartProInvoicePageComponent = () => {
  return (
    <div className="container-lg">
      <ProInvoiceComponent />
    </div>
  );
};

export default CartProInvoicePageComponent;
