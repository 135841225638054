import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
const logo = require(`../../assets/img/logo.jpg`);

const ProInvoiceComponent = () => {
  const userCartStore = useSelector((state) => state.userCartStore.userCart); // Uzima sve potrebno za porudzbinu
  const [user, setUser] = useState({});
  const [cart, setCart] = useState([]);
  const [totalPrice, setTotalPrice] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!userCartStore) {
      return navigate('/');
    }
    setUser(userCartStore.user);
    setCart(userCartStore.cart);
    setTotalPrice(userCartStore.totalPrice);
  }, [userCartStore, navigate]);

  const date = () => {
    const date = new Date(Date.now());
    return date.toLocaleDateString('sr-SR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };

  const renderTable = () => {
    return cart.map((art, index) => {
      return (
        <tr key={art.article_key + '_key_' + index}>
          <th scope="row">{index + 1}</th>
          <td>{art.article_key}</td>
          <td>{art.name.substring(0, 50)}</td>
          <td>{String(art.price).replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
          <td>{art.quantity}</td>
          <td>
            {String(art.price * art.quantity).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              '.'
            )}
            ,00
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="pro-invoice">
      <div className="pro-invoice-top d-flex justify-content-between align-items-center  mb-1">
        <img className="img-fluid" src={logo} alt="Logo" />
        <div className="text-center">
          <h6 className="text-secondary m-0">Predračun broj:</h6>
          <p className="m-0">{user.user_id}</p>
          <p className="m-0">Kikinda {date()}</p>
        </div>
      </div>
      <div className="header-line"></div>
      <div className="pro-invoice-user-data d-flex justify-content-between align-items-center my-3">
        <div className="company">
          {/* <h6 className="m-0">Djurdjević Termosolar</h6> */}
          <p className="m-0">Svetosavska 66, 23300 Kikinda</p>
          <p className="m-0">PIB: 113425189</p>
          <p className="m-0">Matični broj: 21864617</p>
          <p className="m-0">Tekući račun: 200-3568260101016-96</p>
          <p className="m-0">Tel. 0691022170; 0230306532</p>
          <p className="m-0">zdravko.djurdjevic66@gmail.com</p>
        </div>
        <div className="user">
          <h6 className="text-secondary m-0">Kupac:</h6>
          <h6 className="m-0">{`${user.first_name} ${user.last_name}`}</h6>
          <p className="m-0">{`${user.email}`}</p>
          <p className="m-0">{`${user.phone_number}`}</p>
          <p className="m-0">{`${user.street} ${user.number}`}</p>
          <p className="m-0">{`${user.postal_code} ${user.city}`}</p>
        </div>
      </div>
      <div className="header-line"></div>
      <div className="table-responsive my-3">
        <table className="table table-sm">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Šifra</th>
              <th scope="col">Ime</th>
              <th scope="col">Cena</th>
              <th scope="col">Kom</th>
              <th scope="col">Ukupno</th>
            </tr>
          </thead>
          <tbody>{renderTable()}</tbody>
        </table>
      </div>
      <div className="text-end">
        <h6 className="my-3">
          <span className="text-secondary">Ukupno za uplatu:</span> {totalPrice}
          ,00 dinara
        </h6>
      </div>
      <div className="pro-invoice-signature text-secondary text-center">
        <p className="m-0">
          Djurdjević Termosolar Kikinda - 21864617 - https://djurdjevic.eu.org
        </p>
        <p className="m-0">
          Tekući račun: 200-3568260101016-96 - Banka Poštanska Štedionica
        </p>
        <p className="m-0">Predračun je važeći bez pečata i potpisa.</p>
      </div>
      <Link
        to="/cart/user-data"
        className="btn btn-sm btn-outline-secondary mb-3"
      >
        Nazad
      </Link>
      <button
        className="btn btn-sm btn-outline-secondary ms-3 mb-3"
        onClick={(e) => {
          window.print();
        }}
      >
        Štampaj
      </button>
      <Link
        to="/cart/payment"
        className="btn btn-sm btn-outline-secondary ms-3 mb-3"
      >
        Odaberi način plaćanja
      </Link>
    </div>
  );
};

export default ProInvoiceComponent;
