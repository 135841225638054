import React from 'react'
import { Link } from 'react-router-dom';

const NavBarMiddleComponent = () => {
  return (
    <div className='header-middle'>
        <Link to='/' className='header-middle-logo'></Link>
    </div>
  )
}

export default NavBarMiddleComponent;