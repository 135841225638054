import React, { useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { SearchArticles } from '../services/article.service';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../redux/loader.slicer';

const SearchPageComponent = () => {
  const [article, setArticle] = useState([]);
  //const [searchParams] = useSearchParams(); // Ako koristimo query params sa (?) u url
  //console.log(searchParams.get('query'));
  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationObject, setPaginationObject] = useState({
    offset: searchParams.has('page') ? searchParams.get('page') - 1 : 0,
    totalItems: 0,
    limit: searchParams.has('limit') ? searchParams.get('limit') : 10,
  });
  const limitNum = [5, 10, 15, 20];
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    setArticle([]);
    if (!params.searchParams) {
      return null;
    }
    dispatch(toggleLoader(true));
    SearchArticles({keyword:params.searchParams, limit:paginationObject.limit, offset:paginationObject.offset})
      .then((res) => {
        setArticle(res.data.article);
        setPaginationObject(res.data.pagination);
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(toggleLoader(false)));
      setSearchParams({
        limit: paginationObject.limit,
        page: paginationObject.offset + 1,
      });
  }, [params.searchParams, dispatch, paginationObject.limit, paginationObject.offset, setSearchParams]);

  const linkPath = (art) => {
    if (art.article_key) {
      return `/category/${art.category_id}#${art.article_key}`;
    }
    return `/category/${art.category_id}`;
  };

  const renderArticle = () => {
    return article.map((art, index) => {
      return (
        <tr
          key={art.article_key ? art.article_key : art.category_id}
          id={art.article_key ? art.article_key : art.category_id}
        >
          <th scope="row">{index + 1}</th>
          <td>{art.article_name ? art.article_name : art.category_name}</td>
          <td>{art.price}</td>
          <td>
            <Link
              to={linkPath(art)}
              className="btn btn-sm btn-outline-secondary"
            >
              Pogledaj
            </Link>
          </td>
        </tr>
      );
    });
  };

  const onClickHandle = (num) => {
    setPaginationObject((prevState) => ({ ...prevState, offset: num }));
  };

  const onClickPrevious = () => {
    if (paginationObject.offset > 0) {
      setPaginationObject((prevState) => ({
        ...prevState,
        offset: prevState.offset - 1,
      }));
    }
  };

  const onClickNext = () => {
    let pagesNumber = Math.ceil(
      paginationObject.totalItems / paginationObject.limit
    );
    if (paginationObject.offset + 1 < pagesNumber) {
      setPaginationObject((prevState) => ({
        ...prevState,
        offset: prevState.offset + 1,
      }));
    }
  };

  const renderPaginationNumber = () => {
    let pagesNumber = Math.ceil(
      paginationObject.totalItems / paginationObject.limit
    );
    const numPagesDisplayed = 5;
    let pagesArray = [];
    let currentPage = paginationObject.offset + 1;
    if (pagesNumber <= numPagesDisplayed) {
      pagesArray = [...Array(pagesNumber).keys()].map((num) => num + 1);
    } else {
      let startPage = Math.max(
        1,
        currentPage - Math.floor(numPagesDisplayed / 2)
      );
      let endPage = Math.min(pagesNumber, startPage + numPagesDisplayed - 1);

      if (startPage === 1) {
        endPage = numPagesDisplayed;
      } else if (endPage === pagesNumber) {
        startPage = pagesNumber - numPagesDisplayed + 1;
      }
      pagesArray = [...Array(endPage - startPage + 1).keys()].map(
        (num) => startPage + num
      );
    }

    return (
      <>
        <li
          className="page-item"
          onClick={() => onClickHandle(0)}
          disabled={paginationObject.offset === 0}
        >
          <p className="page-link" aria-label="First">
            <span aria-hidden="true">««</span>
          </p>
        </li>
        {pagesArray.map((num) => (
          <li
            key={'page-num-' + num}
            className={
              paginationObject.offset === num - 1
                ? 'active page-item'
                : 'page-item'
            }
            aria-current="page"
            onClick={() => onClickHandle(num - 1)}
          >
            <p className="page-link">{num}</p>
          </li>
        ))}
        <li
          className="page-item"
          onClick={() => onClickHandle(pagesNumber - 1)}
          disabled={paginationObject.offset === pagesNumber - 1}
        >
          <p className="page-link" aria-label="Last">
            <span aria-hidden="true">»»</span>
          </p>
        </li>
      </>
    );
  };

  const renderLimitDropDown = () => {
    return limitNum.map((num, index) => {
      return (
        <li key={index}>
          <p
            className="dropdown-item"
            onClick={() =>
              setPaginationObject((prevState) => ({
                ...prevState,
                limit: num,
                offset: 0,
              }))
            }
          >
            {num}
          </p>
        </li>
      );
    });
  };

  return (
    <div className="container-lg">
      {article.length > 0 ? (
        <>
          <h5 className="text-center text-secondary  my-5">
            Rezultati pretrage za:{' '}
            <span className="text-black">
              "{params.searchParams.replace(/-/g, ' ')}"
            </span>
          </h5>
          <div className="table-responsive">
            <table className="table table-hover table-sm">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Naziv</th>
                  <th scope="col">Cena</th>
                  <th scope="col">Akcija</th>
                </tr>
              </thead>
              <tbody>{renderArticle()}</tbody>
            </table>
            <div className="d-flex flex-wrap gap-3 justify-content-center mt-3 pagination-holder">
              <div className="dropdown">
                <button
                  className="btn btn-sm btn-outline-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Limit {paginationObject.limit}
                </button>
                <ul className="dropdown-menu">{renderLimitDropDown()}</ul>
              </div>
              <nav aria-label="Page navigation">
                <ul className="pagination pagination-sm justify-content-center">
                  <li className="page-item" onClick={() => onClickPrevious()}>
                    <p className="page-link" aria-label="Previous">
                      <span aria-hidden="true">«</span>
                    </p>
                  </li>
                  {renderPaginationNumber()}
                  <li className="page-item" onClick={() => onClickNext()}>
                    <p className="page-link" aria-label="Next">
                      <span aria-hidden="true">»</span>
                    </p>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </>
      ) : (
        <h5 className="text-center text-danger mt-5">
          Nema rezultata za uneti pojam "
          {params.searchParams.replace(/-/g, ' ')}"
        </h5>
      )}
    </div>
  );
};

export default SearchPageComponent;
