import React from 'react';
import { Link } from 'react-router-dom';

const AdminHomePageComponent = () => {
  return (
    <div className="d-flex flex-column admin-holder">
      <div className="col-12 col-lg-6 text-center m-auto my-2">
        <Link
          className="btn btn-outline-secondary w-100"
          to="/dashboard/orders"
        >
          Porudžbine
        </Link>
      </div>
      <div className="col-12 col-lg-6 text-center m-auto my-2">
        <Link
          className="btn btn-outline-secondary w-100"
          to="/dashboard/orders-history"
        >
          Završene
        </Link>
      </div>
      <div className="col-12 col-lg-6 text-center m-auto my-2">
        <Link
          className="btn btn-outline-secondary w-100"
          to="/dashboard/users-email-history"
        >
          Poruke
        </Link>
      </div>
      <div className="col-12 col-lg-6 text-center m-auto my-2">
        <Link className="btn btn-outline-secondary w-100" to="/dashboard/users">
          Korisnici
        </Link>
      </div>
      <div className="col-12 col-lg-6 text-center m-auto my-2">
        <Link
          className="btn btn-outline-secondary w-100"
          to="/dashboard/category"
        >
          Kategorije
        </Link>
      </div>
      <div className="col-12 col-lg-6 text-center m-auto my-2">
        <Link
          className="btn btn-outline-secondary w-100"
          to="/dashboard/article/all-article"
        >
          Proizvodi
        </Link>
      </div>
      <div className="col-12 col-lg-6 text-center m-auto my-2">
        <Link
          className="btn btn-outline-secondary w-100"
          to="/dashboard/gallery"
        >
          Galerija
        </Link>
      </div>
    </div>
  );
};

export default AdminHomePageComponent;
