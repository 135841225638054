import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DeletePictureFromGallery, GetPictureFromGallery, UpdatePictureFromGallery } from '../services/admin.service';
import { toggleLoader } from '../redux/loader.slicer';
import { toast } from 'react-toastify';
import imageRoutes from '../routes/imageRoutes';

const AdminGalleryDetailsPageComponent = () => {
  const [picture, setPicture] = useState({});
  const [description, setDescription] = useState('');
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(toggleLoader(true));
    GetPictureFromGallery(params.photo_id)
      .then((res) => {
        setPicture(res.data);
        setDescription(res.data.description);
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(toggleLoader(false)));
  }, [params, dispatch]);

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };
  
  const handleUpload = (e) => {
    e.preventDefault();
    console.log(description);
    dispatch(toggleLoader(true));
    UpdatePictureFromGallery(description, picture.photo_id)
      .then((res) => toast.success(res.data))
      .catch((err) => {
        toast.error(err.response.data);
      })
      .finally(() => dispatch(toggleLoader(false)));
  };

  const deletePhoto = () => {
    DeletePictureFromGallery(picture.photo_id, picture.image_path)
      .then((res) => {
        toast.success(res.data);
        navigate('/dashboard/gallery');
      })
      .catch((err) => toast.error(err.response.data));
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center my-3">
      <h1 className="fs-3 text-secondary">{`Fotografija id: ${picture?.photo_id}`}</h1>
      <div style={{ maxWidth: '800px', height: 'auto' }} className="m-auto">
        <img
          style={{ maxWidth: '100%', height: 'auto' }}
          className="img-fluid img-thumbnail"
          src={`${imageRoutes}${picture?.image_path}`}
          alt={`Img-${picture?.photo_id}`}
        />
      </div>
      <div className="w-100">
        <form onSubmit={handleUpload}>
          <div className="mb-3">
            <textarea
              className="form-control text-center border-0"
              id="description"
              name="description"
              defaultValue={description}
              onChange={(e) => handleDescription(e)}
            ></textarea>
          </div>
          <button type="submit" className="btn btn-danger">
            Sačuvaj izmene
          </button>
        </form>
      </div>
      <div className="mt-3">
        <Link className="btn btn-outline-secondary " to="/dashboard/gallery">
          Nazad
        </Link>
        <button onClick={deletePhoto} className="btn btn-outline-danger ms-5">
          Obriši fotografiju
        </button>
      </div>
    </div>
  );
};

export default AdminGalleryDetailsPageComponent;
