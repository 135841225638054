import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { toggleLoader } from '../redux/loader.slicer';
import { AdminAcceptUserOrder, AdminRejectedUserOrder, AdminUserOrderSent, GetAdminUser, GetAdminUserOrders } from '../services/admin.service';
import { Link, useNavigate, useParams } from 'react-router-dom';

const AdminUserOrdersPageComponent = () => {
  const [orders, setOrders] = useState([]);
  const [user, setUser] = useState({});
  const [status, setStatus] = useState({num:1});
  let priceArray = [];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    dispatch(toggleLoader(true));
    GetAdminUserOrders(params.user_id)
      .then((res) => {
        setOrders(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(toggleLoader(false)));
  }, [dispatch, params.user_id, status]);

  useEffect(() => {
    GetAdminUser(params.user_id)
      .then((res) => {
        setUser(res.data[0]);
      })
      .catch((err) => console.log(err));
  }, [orders, params.user_id]);

  const total = (item) => {
    let newPrice = parseInt(item.price) * parseInt(item.quantity);
    priceArray.push(newPrice);
  };

  const renderTotalPrice = () => {
    let newPrice = null;
    for (let index = 0; index < priceArray.length; index++) {
      let element = priceArray[index];
      newPrice = newPrice + element;
    }
    return String(newPrice).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const orderStatus = (status) => {
    let text = null;
    switch (status) {
      case 'pending':
        text = 'Na čekanju';
        break;
      case 'accepted':
        text = 'Prihvaćeno';
        break;
      default:
    }
    return text;
  };

  const acceptOrder = (params) => {
    let { order_id , status} = params;
    if (status === 'pending') {
      status = 'accepted';
    } else {
      status = 'pending';
    }
    AdminAcceptUserOrder(user.user_id, order_id, status)
      .then((res) => {
        setStatus((prevState)=>({...prevState, num:prevState.num+1}));
        toast.success(res.data);
      })
      .catch((err) => console.log(err));
  };

  const orderSent = (params) => {
    let { order_id, cart_id } = params;
    AdminUserOrderSent(user.user_id, order_id, cart_id)
      .then((res) => {
        setStatus((prevState) => ({ ...prevState, num: prevState.num + 1 }));
        toast.success(res.data);
        if (orders.length < 2) {
          navigate('/dashboard/orders');
        }
      })
      .catch((err) => console.log(err));
  };

  const rejectedOrder = (params) => {
    let { order_id } = params;
    AdminRejectedUserOrder(user.user_id, order_id)
      .then((res) => {
        setStatus((prevState) => ({ ...prevState, num: prevState.num + 1 }));
        toast.success(res.data);
        if (orders.length < 2) {
          navigate('/dashboard/orders');
        }
      })
      .catch((err) => console.log(err));
  };

  const renderUserData = () => {
    return (
      <tbody>
        <tr>
          <td className="text-danger">{`${user.first_name} ${user.last_name}`}</td>
        </tr>
        <tr>
          <td className="text-danger">{user.email}</td>
        </tr>
        <tr>
          <td className="text-danger">{user.phone_number}</td>
        </tr>
        <tr>
          <td className="text-danger">{`${user.street} ${user.street_number}`}</td>
        </tr>
        <tr>
          <td className="text-danger">{`${user.postal_code} ${user.city}`}</td>
        </tr>
      </tbody>
    );
  };

  const renderArticlesData = () => {
    return orders.map((item, index) => {
      total(item);
      return (
        <tr key={item.article_key + '_' + index}>
          <th scope="row">{index + 1}</th>
          <td>{item.created_at}</td>
          <td>{item.article_key}</td>
          <td>{`${item.name.substring(0, 50)}`}</td>
          <td>{String(item.price).replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
          {/* <td>{`${item.price}`}</td> */}
          <td className="print-hide">{`${item.updated_at}`}</td>
          <td>{`${item.quantity}`}</td>
          <td>
            {String(item.price * item.quantity).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              '.'
            )}
            ,00
          </td>
          {/* <td>{parseInt(item.price) * parseInt(item.quantity)},00</td> */}
          <td
            className={
              item.status === 'pending' ? 'text-primary' : 'text-success'
            }
          >
            {orderStatus(item.status)}
          </td>
          <td>
            <button
              onClick={() =>
                acceptOrder({ order_id: item.order_id, status: item.status })
              }
              className="btn btn-sm btn-outline-success print-hide"
            >
              Prihvati
            </button>
          </td>
          <td>
            <button
              onClick={() =>
                orderSent({
                  order_id: item.order_id,
                  status: item.status,
                  cart_id: item.cart_id,
                })
              }
              className="btn btn-sm btn-outline-info print-hide"
            >
              Poslato
            </button>
          </td>
          <td>
            <button
              onClick={() =>
                rejectedOrder({ order_id: item.order_id, status: item.status })
              }
              className="btn btn-sm btn-outline-danger print-hide"
            >
              Odbaci
            </button>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      {orders.length > 0 && (
        <div className="container-lg admin-user-orders">
          <div className="text-center">
            <h1 className="fs-3 my-3">Porudžbenica</h1>
            <h5 className="text-secondary">{orders[0].user_id}</h5>
            <h6 className="text-secondary">{orders[0].created_at}</h6>
            <div className="header-line"></div>
            <div className="table-responsive">
              <table className="table">{renderUserData()}</table>
            </div>
          </div>
          <div className="header-line"></div>
          <h2 className="text-center text-secondary fs-4 my-3">
            Naručeni proizvodi
          </h2>
          <div className="table-responsive">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Od dana</th>
                  <th scope="col">Šifra</th>
                  <th scope="col">Ime</th>
                  <th scope="col">Cena</th>
                  <th scope="col" className="print-hide">
                    Cena od
                  </th>
                  <th scope="col">Kom</th>
                  <th scope="col">Ukupno</th>
                  <th scope="col">Status</th>
                  <th scope="col" className="print-hide">
                    #
                  </th>
                  <th scope="col" className="print-hide">
                    #
                  </th>
                  <th scope="col" className="print-hide">
                    #
                  </th>
                </tr>
              </thead>
              <tbody>{renderArticlesData()}</tbody>
            </table>
          </div>
          <div className="text-end me-3">
            <h6 className="mt-3">
              <span className="text-secondary">Ukupno za uplatu:</span>{' '}
              {renderTotalPrice() === 'null' ? 0 : renderTotalPrice()},00 dinara
            </h6>
          </div>
          <div className="d-flex flex-wrap gap-3 mt-3">
            <Link
              className="btn btn-outline-secondary print-hide"
              to={`/dashboard/orders#${orders[0].user_id}`}
            >
              Nazad
            </Link>
            <button
              className="btn btn-outline-info print-hide"
              onClick={(e) => window.print()}
            >
              Štampaj
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminUserOrdersPageComponent;
