import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toggleLoader } from '../redux/loader.slicer';
import { findUserCart, setUserDataCart } from '../services/user.service';
import { TfiShoppingCartFull } from 'react-icons/tfi';
import { changeUserToLocalStorage } from '../services/user.service';
import { saveUserCart } from '../redux/userCart.slicer';

const CartUserDataPageComponent = () => {
    const userStore = useSelector((state) => state.userStore.user); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
    //const userCartStore = useSelector((state) => state.userCartStore.userCart);// Uzima sve potrebno za porudzbinu
    const [cart, setCart] = useState([]);
    let priceArray = [];
    const [user, setUser] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
      if (!userStore) {
        return navigate('/login');
      }
      dispatch(toggleLoader(true));
      setUser(userStore);
      findUserCart({ user_id: userStore.user_id })
        .then((res) => {
          if (res.status === 243) {
            return setCart([]);
          }
          setCart(res.data);
        })
        .catch((err) => toast.error(err.response.data))
        .finally(() => dispatch(toggleLoader(false)));
    }, [dispatch, navigate, userStore]);

    const total = (art) => {
      let newPrice = parseInt(art.price) * parseInt(art.quantity);
      priceArray.push(newPrice);
    };

    const renderTotalPrice = () => {
      let newPrice = null;
      for (let index = 0; index < priceArray.length; index++) {
        let element = priceArray[index];
        newPrice = newPrice + element;
      }
      return String(newPrice).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    };

    const renderTable = () => {
      return cart.map((art, index) => {
        total(art);
        return (
          <tr key={art.article_key + '_key_' + index}>
            <th scope="row">{index + 1}</th>
            <td>{art.article_key}</td>
            <td>{art.name}</td>
            <td>{String(art.price).replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
            <td>{art.quantity}</td>
            <td>
              {String(art.price * art.quantity).replace(
                /\B(?=(\d{3})+(?!\d))/g,
                '.'
              )}
              ,00
            </td>
          </tr>
        );
      });
    };

    const handleUser = (e) => {
      let inputValue = e.target.value;
      let inputName = e.target.name;
      let copyUser = { ...user };
      copyUser[inputName] = inputValue;
      setUser(copyUser);
    };

    const onLoginSubmit = (e) => {
      e.preventDefault();
      const totalPrice = renderTotalPrice();
      if (
        user.gender === 'unspecified' ||
        !user.street ||
        !user.number ||
        !user.postal_code ||
        !user.city ||
        !user.phone_number
      ) {
        return toast.error('Sva polja moraju biti popunjena.');
      }
      dispatch(toggleLoader(true));
      dispatch(saveUserCart({ user, cart, totalPrice })); // cuvam podatke korpe u redux
      setUserDataCart({user,cart,totalPrice})
        .then((res) => {
          changeUserToLocalStorage(user);
          navigate('/cart/pro-invoice');
        })
        .catch((err) => toast.error(err.response.data))
        .finally(() => dispatch(toggleLoader(false)));
    };

    const renderForm = () => {
      return (
        <>
          <h5 className={'text-secondary text-center'}>
            Popunite vaše podatke
          </h5>
          <form>
            <div className="mb-3">
              <label htmlFor="first_name" className="form-label">
                Ime
              </label>
              <input
                type="text"
                name="first_name"
                className="form-control"
                id="first_name"
                aria-describedby="first_name"
                defaultValue={userStore.first_name}
                disabled
              />
            </div>
            <div className="mb-3">
              <label htmlFor="last_name" className="form-label">
                Prezime
              </label>
              <input
                type="text"
                name="last_name"
                className="form-control"
                id="last_name"
                aria-describedby="last_name"
                defaultValue={userStore.last_name}
                disabled
              />
            </div>
            <div className="mb-3">
              <label className="me-3" htmlFor="gender">
                Pol:
              </label>
              <select
                className="form-control mt-2"
                name="gender"
                id="gender"
                defaultValue={userStore.gender}
                onChange={(e) => handleUser(e)}
              >
                <option value="unspecified">neodredjeno</option>
                <option value="male">muško</option>
                <option value="female">žensko</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="street" className="form-label">
                Ulica
              </label>
              <input
                type="text"
                name="street"
                className="form-control"
                id="street"
                aria-describedby="first_name"
                defaultValue={userStore.street}
                onChange={(e) => handleUser(e)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="number" className="form-label">
                Kućni broj
              </label>
              <input
                type="text"
                name="number"
                className="form-control"
                id="number"
                aria-describedby="number"
                defaultValue={userStore.number}
                onChange={(e) => handleUser(e)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="postal_code" className="form-label">
                Poštanski broj
              </label>
              <input
                type="number"
                name="postal_code"
                className="form-control"
                id="postal_code"
                aria-describedby="postal_code"
                defaultValue={userStore.postal_code}
                onChange={(e) => handleUser(e)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="city" className="form-label">
                Mesto stanovanja
              </label>
              <input
                type="text"
                name="city"
                className="form-control"
                id="city"
                aria-describedby="city"
                defaultValue={userStore.city}
                onChange={(e) => handleUser(e)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone_number" className="form-label">
                Telefon
              </label>
              <input
                type="text"
                name="phone_number"
                className="form-control"
                id="phone_number"
                aria-describedby="phone_number"
                defaultValue={userStore.phone_number}
                onChange={(e) => handleUser(e)}
              />
            </div>
            <button
              onClick={onLoginSubmit}
              type="submit"
              className="btn btn-outline-success form-control"
            >
              Nastavi sa kupovinom
            </button>
          </form>
          <Link
            to="/cart"
            className="btn btn-outline-secondary form-control mt-3 mb-5"
          >
            Nazad
          </Link>
        </>
      );
    };

  return (
    <div className="container-lg">
      <p className="fs-1 text-center text-secondary mt-3">
        <TfiShoppingCartFull />
      </p>
      <h4 className="text-center text-secondary mb-3">{`${userStore.first_name} ${userStore.last_name}`}</h4>
      <div className="table-responsive-sm">
        <table className="table table-striped table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Šifra</th>
              <th scope="col">Ime</th>
              <th scope="col">Cena</th>
              <th scope="col">Komada</th>
              <th scope="col">Ukupno</th>
            </tr>
          </thead>
          <tbody>{renderTable()}</tbody>
        </table>
      </div>
      <div className="text-end me-5">
        <h6 className="my-3">
          <span className="text-secondary">Ukupno za uplatu:</span>{' '}
          {renderTotalPrice() === 'null' ? 0 : renderTotalPrice()},00 dinara
        </h6>
      </div>
      <div className="col-12 col-md-8 col-lg-6 col-xxl-5 m-auto">
        {renderForm()}
      </div>
    </div>
  );
}

export default CartUserDataPageComponent;