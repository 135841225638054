import axios from 'axios';

export const UploadFile = (payload) => axios.post(`/dashboard/upload`, payload);
export const AddFileToGallery = (payload) => axios.post(`/dashboard/gallery`, payload);
export const GetPictureFromGallery = (photo_id) => axios.get(`/dashboard/gallery/${photo_id}`);
export const UpdatePictureFromGallery = (description, photo_id) => axios.put(`/dashboard/gallery-update/${description}/${photo_id}`);
export const DeletePictureFromGallery = (photo_id, image_path) => axios.delete(`/dashboard/gallery-delete/${photo_id}/${image_path}`);

export const GetAllAdminUsers = (keyword, is_active, gender, limit, offset) => axios.get(`/dashboard/users?keyword=${keyword}&is_active=${is_active}&gender=${gender}&limit=${limit}&offset=${offset}`);
export const GetAdminUser = (user_id) => axios.get(`/dashboard/user/${user_id}`);
export const GetAdminUserByEmail = (email) => axios.get(`/dashboard/user/email/${email}`);
export const ChangeAdminUser = (payload) => axios.put('/dashboard/user/:user_id', payload);

export const GetMainAdminCategory = () => axios.get(`/dashboard/category/main`);
export const ChangeAdminUserCategory = (payload) => axios.put('/dashboard/category/change/:category_id', payload);
export const GetAllAdminCategory = () => axios.get(`/dashboard/article/all-category`);
export const DeleteCategory = (category_id) => axios.delete(`/dashboard/category/delete/${category_id}`);
export const AddMainCategory = (payload) => axios.post('/dashboard/category/add', payload);
export const AddChildCategory = (payload) => axios.post('/dashboard/category/add-child', payload);

export const GetAllAdminArticle = (limit, offset) => axios.get(`/dashboard/article/all-article/${limit}/${offset}`);
export const SearchAllAdminArticle = (payload) => axios.post(`/dashboard/article/all-article`, payload);
export const GetAdminArticleByCategoryId = (category_id, limit, offset) => axios.get(`/dashboard/article/category/${category_id}/${limit}/${offset}`);
export const GetAdminArticleById = (article_id) => axios.get(`/dashboard/article/change/${article_id}`);
export const UpdateAdminArticle = (payload) => axios.put('/dashboard/article/change', payload);
export const AddArticle = (payload) => axios.post('/dashboard/article/add', payload);
export const DeleteAdminArticle = (article_id) => axios.delete(`/dashboard/article/delete/${article_id}`);
export const ChangeArticlePrice = (payload) => axios.put('/dashboard/article/price', payload);

export const GetAllUserOrders = () => axios.get('/dashboard/orders');
export const GetAdminUserOrders = (user_id) => axios.get(`/dashboard/orders/${user_id}`);
export const GetAllUserHistoryOrders = (keyword, limit, offset) => axios.get(`/dashboard/orders-history?keyword=${keyword}&limit=${limit}&offset=${offset}`);
export const GetAdminUserHistoryOrders = (user_id) => axios.get(`/dashboard/orders-history/${user_id}`);
export const AdminAcceptUserOrder = (user_id, order_id, status) => axios.get(`/dashboard/order/accept/${user_id}/${order_id}/${status}`);
export const AdminReacceptUserOrder = (user_id, order_id, cart_id) => axios.get(`/dashboard/order/reaccept/${user_id}/${order_id}/${cart_id}`);
export const AdminRejectedUserOrder = (user_id, order_id) => axios.get(`/dashboard/order/rejected/${user_id}/${order_id}`);
export const AdminUserOrderSent = (user_id, order_id, cart_id) => axios.get(`/dashboard/order/sent/${user_id}/${order_id}/${cart_id}`);
export const AdminUserDeleteOrder = (user_id, order_id, cart_id) => axios.get(`/dashboard/order/delete/${user_id}/${order_id}/${cart_id}`);

export const GetAllUserEmails = (keyword, limit, offset) => axios.get(`/dashboard/users-email-history?keyword=${keyword}&limit=${limit}&offset=${offset}`);
export const GetUserEmail = (emails_id) => axios.get(`/dashboard/user-email-details/${emails_id}`);