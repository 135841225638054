import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userCart: null,
};

const userCartSlicer = createSlice({
  name: 'userCart',
  initialState: initialState,
  reducers: {
    saveUserCart(state, data) {
      state.userCart = data.payload;
    },
    removeUserCart(state) {
      state.userCart = null;
    },
  },
});

export const { saveUserCart, removeUserCart } = userCartSlicer.actions;
export default userCartSlicer.reducer;
