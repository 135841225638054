import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { purchaseConfirmation } from '../services/user.service';
import { toggleLoader } from '../redux/loader.slicer';
import { saveCart } from '../redux/cart.slicer';

const PurchaseConfirmationPageComponent = () => {
  const userCartStore = useSelector((state) => state.userCartStore.userCart); // Uzima sve potrebno za porudzbinu
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!userCartStore) {
      return navigate('/');
    }
    dispatch(toggleLoader(true));
    purchaseConfirmation({
      user: userCartStore.user,
      itemNumber: userCartStore.cart.length,
      totalPrice: userCartStore.totalPrice,
      paymentSelected: searchParams.get('paymentSelected'),
    })
      .then((res) => {
        toast.success(res.data);
      })
      .catch((err) => toast.error(err.response.data))
      .finally(() => {
        dispatch(saveCart(null));
        dispatch(toggleLoader(false));
        setTimeout(() => {
          navigate('/cart/user-orders');
        }, 3000);
      });
  }, [userCartStore, navigate, dispatch, searchParams]);

  return (
    <div className="container-lg purchase-confirmation">
      <h5 className='text-center text-secondary mt-5'>Molimo Vas sačekajte...</h5>
    </div>
  );
};

export default PurchaseConfirmationPageComponent;
