import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toggleLoader } from '../redux/loader.slicer';
import { userOrders, userOrdersHistory } from '../services/user.service';
import { BsShop } from 'react-icons/bs';
import { FiShoppingBag } from 'react-icons/fi';
import { BsSignpostSplit } from 'react-icons/bs';
import { GetArticle } from '../services/article.service';

const UserOrdersPageComponent = () => {
  const userStore = useSelector((state) => state.userStore.user); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [orders, setOrders] = useState([]);
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationObject, setPaginationObject] = useState({
    offset: searchParams.has('page') ? searchParams.get('page') - 1 : 0,
    totalItems: 0,
    limit: searchParams.has('limit') ? searchParams.get('limit') : 10,
  });
  const limitNum = [5, 10, 15, 20];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userStore) {
      return navigate('/login');
    }
    dispatch(toggleLoader(true));
    userOrders({ user_id: userStore.user_id })
      .then((res) => {
        setOrders(res.data);
      })
      .catch((err) => toast.error(err.response.data))
      .finally(() => dispatch(toggleLoader(false)));
  }, [userStore, navigate, dispatch]);

  useEffect(() => {
    userOrdersHistory({
      user_id: userStore.user_id,
      limit: paginationObject.limit,
      offset: paginationObject.offset,
    })
      .then((res) => {
        setPurchaseHistory(res.data.orders);
        setPaginationObject(res.data.pagination);
      })
      .catch((err) => console.log(err.response.data));
    setSearchParams({
      limit: paginationObject.limit,
      page: paginationObject.offset + 1,
    });
  }, [
    paginationObject.limit,
    paginationObject.offset,
    userStore.user_id,
    setSearchParams,
  ]);

  const status = (status) => {
    let statusText = null;
    switch (status) {
      case 'pending':
        statusText = 'Na čekanju.';
        break;
      case 'accepted':
        statusText = 'Prihvaćeno.';
        break;
      case 'shipped':
        statusText = 'Isporučeno.';
        break;
      case 'rejected':
        statusText = 'Odbijeno.';
        break;
      default:
    }
    return statusText;
  };

  const showArticle = (article_id) => {
    GetArticle(article_id)
      .then((res) => {
        navigate(`/category/${res.data[0].category_id}`);
      })
      .catch((err) => console.log(err.response.data));
  }

  const renderOrders = () => {
    return orders.map((order, index) => {
      return (
        <tr style={{cursor: 'pointer'}} key={order.article_key + '_key_' + index} onClick={()=>showArticle(order.article_id)}>
          <th scope="row">{index + 1}</th>
          <td>{order.name.substring(0, 50)}</td>
          <td>{order.quantity}</td>
          <td>{order.created_at}</td>
          <td className='text-success'>{status(order.status)}</td>
        </tr>
      );
    });
  };

  const renderHistoryOrders = () => {
    return purchaseHistory.map((order, index) => {
      return (
        <tr style={{cursor: 'pointer'}} key={order.article_key + '_key_' + index} onClick={()=>showArticle(order.article_id)}>
          <th scope="row">{index + 1}</th>
          <td>{order.name.substring(0, 50)}</td>
          <td>{order.quantity}</td>
          <td>{order.created_at}</td>
          <td className="text-primary">{status(order.status)}</td>
        </tr>
      );
    });
  };

  const onClickHandle = (num) => {
    setPaginationObject((prevState) => ({ ...prevState, offset: num }));
  };

  const onClickPrevious = () => {
    if (paginationObject.offset > 0) {
      setPaginationObject((prevState) => ({
        ...prevState,
        offset: prevState.offset - 1,
      }));
    }
  };

  const onClickNext = () => {
    let pagesNumber = Math.ceil(
      paginationObject.totalItems / paginationObject.limit
    );
    if (paginationObject.offset + 1 < pagesNumber) {
      setPaginationObject((prevState) => ({
        ...prevState,
        offset: prevState.offset + 1,
      }));
    }
  };

  const renderPaginationNumber = () => {
    let pagesNumber = Math.ceil(
      paginationObject.totalItems / paginationObject.limit
    );
    const numPagesDisplayed = 5;
    let pagesArray = [];
    let currentPage = paginationObject.offset + 1;
    if (pagesNumber <= numPagesDisplayed) {
      pagesArray = [...Array(pagesNumber).keys()].map((num) => num + 1);
    } else {
      let startPage = Math.max(
        1,
        currentPage - Math.floor(numPagesDisplayed / 2)
      );
      let endPage = Math.min(pagesNumber, startPage + numPagesDisplayed - 1);

      if (startPage === 1) {
        endPage = numPagesDisplayed;
      } else if (endPage === pagesNumber) {
        startPage = pagesNumber - numPagesDisplayed + 1;
      }
      pagesArray = [...Array(endPage - startPage + 1).keys()].map(
        (num) => startPage + num
      );
    }

    return (
      <>
        <li
          className="page-item"
          onClick={() => onClickHandle(0)}
          disabled={paginationObject.offset === 0}
        >
          <p className="page-link" aria-label="First">
            <span aria-hidden="true">««</span>
          </p>
        </li>
        {pagesArray.map((num) => (
          <li
            key={'page-num-' + num}
            className={
              paginationObject.offset === num - 1
                ? 'active page-item'
                : 'page-item'
            }
            aria-current="page"
            onClick={() => onClickHandle(num - 1)}
          >
            <p className="page-link">{num}</p>
          </li>
        ))}
        <li
          className="page-item"
          onClick={() => onClickHandle(pagesNumber - 1)}
          disabled={paginationObject.offset === pagesNumber - 1}
        >
          <p className="page-link" aria-label="Last">
            <span aria-hidden="true">»»</span>
          </p>
        </li>
      </>
    );
  };

  const renderLimitDropDown = () => {
    return limitNum.map((num, index) => {
      return (
        <li key={index}>
          <p
            className="dropdown-item"
            onClick={() =>
              setPaginationObject((prevState) => ({
                ...prevState,
                limit: num,
                offset: 0,
              }))
            }
          >
            {num}
          </p>
        </li>
      );
    });
  };

  return (
    <>
      {orders.length > 0 ? (
        <div className="container-lg">
          <div>
            <h6 className="fs-1 text-center text-danger m-0 mt-3">
              <FiShoppingBag />
            </h6>
            <h5 className="text-center text-secondary my-1">Moje Porudžbine</h5>
            <div className="d-flex flex-wrap justify-content-center align-items-center ">
              <div className="table-responsive my-3">
                <table className="table table-striped table-hover table-sm">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Ime</th>
                      <th scope="col">Kom</th>
                      <th scope="col">Datum</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>{renderOrders()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        purchaseHistory.length === 0 && (
          <div className="container-lg">
            <h6 className="fs-1 text-center text-danger m-0 mt-3">
              <BsSignpostSplit />
            </h6>
            <h4 className="text-center text-danger mt-5">Nema Porudžbina</h4>
          </div>
        )
      )}
      {purchaseHistory.length > 0 && (
        <div className="container-lg">
          <div>
            <h6 className="fs-1 text-center text-danger m-0 mt-3">
              <BsShop />
            </h6>
            <h5 className="text-center text-secondary my-1">
              Istorija Kupovine
            </h5>
            <div className="d-flex flex-wrap justify-content-center align-items-center ">
              <div className="table-responsive my-3">
                <table className="table table-striped table-hover table-sm">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Ime</th>
                      <th scope="col">Kom</th>
                      <th scope="col">Datum</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>{renderHistoryOrders()}</tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap gap-3 justify-content-center mt-3 pagination-holder">
            <div className="dropdown">
              <button
                className="btn btn-sm btn-outline-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Limit {paginationObject.limit}
              </button>
              <ul className="dropdown-menu">{renderLimitDropDown()}</ul>
            </div>
            <nav aria-label="Page navigation">
              <ul className="pagination pagination-sm justify-content-center">
                <li className="page-item" onClick={() => onClickPrevious()}>
                  <p className="page-link" aria-label="Previous">
                    <span aria-hidden="true">«</span>
                  </p>
                </li>
                {renderPaginationNumber()}
                <li className="page-item" onClick={() => onClickNext()}>
                  <p className="page-link" aria-label="Next">
                    <span aria-hidden="true">»</span>
                  </p>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

export default UserOrdersPageComponent;
