import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GetAdminArticleByCategoryId } from '../services/admin.service';
import { toggleLoader } from '../redux/loader.slicer';
import { toggleModal } from '../redux/modal.slicer';
import { GetCategory } from '../services/article.service';

const ArticleCategoryParentPageComponent = () => {
  const [article, setArticle] = useState([]);
  const [category, setCategory] = useState({});
  const [paginationObject, setPaginationObject] = useState({offset:0, totalItems:0, limit:20})
  const limitNum = [5, 10, 15, 20];
  const params = useParams();
  const dispatch = useDispatch();

    useEffect(() => {
      dispatch(toggleLoader(true));
      GetAdminArticleByCategoryId(params.category_id, paginationObject.limit, paginationObject.offset)
        .then((res) => {
          setArticle(res.data.article);
          setPaginationObject(res.data.pagination);
          GetCategory(params.category_id)
            .then((res) => {
              setCategory(res.data[0]);
            })
            .catch((err) => toast.error(err.response.data));
        })
        .catch((err) => toast.error(err.response.data))
        .finally(() => dispatch(toggleLoader(false)));
    }, [dispatch, params.category_id, paginationObject.limit, paginationObject.offset]);

    const renderArticle = () => {
        return article.map((art) => {
            return (
              <tr id={art.article_key} key={art.article_key}>
                <th scope="row">{art.article_key}</th>
                <td>{art.name}</td>
                <td>{art.exceptt}</td>
                <td>{art.description}</td>
                <td>
                  <select
                    className="border-0"
                    id="status"
                    defaultValue={art.status}
                    disabled
                  >
                    <option value="available">Na stanju</option>
                    <option value="visible">Nije dostupno</option>
                    <option value="hidden">Sakriveno</option>
                  </select>
                </td>
                <td>
                  <select
                    className="border-0"
                    id="is_promoted"
                    defaultValue={art.is_promoted}
                    disabled
                  >
                    <option value="0">Ne</option>
                    <option value="1">Da</option>
                  </select>
                </td>
                <td>{art.price}</td>
                <td>{art.article_price_updated_at}</td>
                <td className="d-flex flex-column gap-3">
                  <Link
                    className="btn btn-sm btn-outline-warning"
                    to={`/dashboard/article/change/${art.article_id}`}
                  >
                    Izmeni
                  </Link>
                  <Link
                    className="btn btn-sm btn-outline-info"
                    to={`/dashboard/article/change-price/${art.article_id}`}
                    onClick={() => dispatch(toggleModal(true))}
                  >
                    Cena
                  </Link>
                  <Link
                    className="btn btn-sm btn-outline-danger"
                    to={`/dashboard/article/delete/${art.article_id}`}
                    onClick={() => dispatch(toggleModal(true))}
                  >
                    Obriši
                  </Link>
                </td>
              </tr>
            );
        })
    }

  const onClickHandle = (num) => {
    setPaginationObject((prevState) => ({...prevState, offset: num}));
  }

  const onClickPrevious = () => {
    if (paginationObject.offset > 0) {
      setPaginationObject((prevState) => ({ ...prevState, offset: prevState.offset - 1 }));
    }
  };

  const onClickNext = () => {
    let pagesNumber = Math.ceil(paginationObject.totalItems / paginationObject.limit)
    if (paginationObject.offset + 1 < pagesNumber) {
      setPaginationObject((prevState) => ({ ...prevState, offset: prevState.offset + 1 }));
    }
  };

  const renderPaginationNumber = () => {
    let pagesNumber = Math.ceil(
      paginationObject.totalItems / paginationObject.limit
    );
    const numPagesDisplayed = 5;
    let pagesArray = [];
    let currentPage = paginationObject.offset + 1;
    if (pagesNumber <= numPagesDisplayed) {
      pagesArray = [...Array(pagesNumber).keys()].map((num) => num + 1);
    } else {
      let startPage = Math.max(
        1,
        currentPage - Math.floor(numPagesDisplayed / 2)
      );
      let endPage = Math.min(pagesNumber, startPage + numPagesDisplayed - 1);

      if (startPage === 1) {
        endPage = numPagesDisplayed;
      } else if (endPage === pagesNumber) {
        startPage = pagesNumber - numPagesDisplayed + 1;
      }
      pagesArray = [...Array(endPage - startPage + 1).keys()].map(
        (num) => startPage + num
      );
    }

    return (
      <>
        <li
          className="page-item"
          onClick={() => onClickHandle(0)}
          disabled={paginationObject.offset === 0}
        >
          <p className="page-link" aria-label="First">
            <span aria-hidden="true">««</span>
          </p>
        </li>
        {pagesArray.map((num) => (
          <li
            key={'page-num-' + num}
            className={
              paginationObject.offset === num - 1
                ? 'active page-item'
                : 'page-item'
            }
            aria-current="page"
            onClick={() => onClickHandle(num - 1)}
          >
            <p className="page-link">{num}</p>
          </li>
        ))}
        <li
          className="page-item"
          onClick={() => onClickHandle(pagesNumber - 1)}
          disabled={paginationObject.offset === pagesNumber - 1}
        >
          <p className="page-link" aria-label="Last">
            <span aria-hidden="true">»»</span>
          </p>
        </li>
      </>
    );
  };


  const renderLimitDropDown = () => {
    return limitNum.map((num, index) => {
      return <li key={index}><p className="dropdown-item" onClick={()=>setPaginationObject((prevState)=>({...prevState,limit:num,offset:0}))}>{num}</p></li>
    })
  }

  return (
    <>
      {article.length > 0 ? (
        <div className="table-responsive article-category-parent">
          <h2 className="fs-3 text-center mt-3 text-danger">
            Proizvodi kategorije {category.name}
          </h2>
          <div className="d-flex justify-content-between">
            <Link to="/dashboard/article/add">Dodaj proizvod</Link>
            <Link
              className="btn btn-outline-secondary "
              to={`/dashboard/category/${category.category_id}`}
            >
              Nazad
            </Link>
          </div>
          <table className="table table-sm table-hover">
            <thead>
              <tr>
                <th scope="col">Šifra</th>
                <th scope="col">Ime</th>
                <th scope="col">Kratak opis</th>
                <th scope="col">Opis</th>
                <th scope="col">Status</th>
                <th scope="col">Na promociji</th>
                <th scope="col">Cena</th>
                <th scope="col">Cena promenjena</th>
                <th scope="col">Akcija</th>
              </tr>
            </thead>
            <tbody>{renderArticle()}</tbody>
          </table>
          <div className="d-flex flex-wrap gap-3 justify-content-center mt-3 pagination-holder">
            <div className="dropdown">
              <button
                className="btn btn-sm btn-outline-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Limit {paginationObject.limit}
              </button>
              <ul className="dropdown-menu">{renderLimitDropDown()}</ul>
            </div>
            <nav aria-label="Page navigation">
              <ul className="pagination pagination-sm justify-content-center">
                <li className="page-item" onClick={() => onClickPrevious()}>
                  <p className="page-link" aria-label="Previous">
                    <span aria-hidden="true">«</span>
                  </p>
                </li>
                {renderPaginationNumber()}
                <li className="page-item" onClick={() => onClickNext()}>
                  <p className="page-link" aria-label="Next">
                    <span aria-hidden="true">»</span>
                  </p>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      ) : (
        <div className='text-center mt-5'>
          <h5 className='text-danger'>Nema proizvoda</h5>
        </div>
      )}
    </>
  );
}

export default ArticleCategoryParentPageComponent;