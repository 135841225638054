import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toggleLoader } from '../redux/loader.slicer';
import { GetCategory, GetChildrenCategory } from '../services/article.service';
import { toggleModal } from '../redux/modal.slicer';

const CategoryChildPageComponent = () => {
  const [categoryChild, setCategoryChild] = useState([]);
  const [category, setCategory] = useState({});
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLoader(true));
    GetChildrenCategory(params.category_id)
      .then((res) => {
        setCategoryChild(res.data);
        GetCategory(params.category_id)
          .then((res) => {
            setCategory(res.data[0]);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(toggleLoader(false)));
  }, [dispatch, params.category_id]);

  const renderChildCategory = () => {
    return categoryChild.map((cat) => {
      return (
        <tr key={cat.category_id}>
          <th scope="row">{cat.category_id}</th>
          <td>{cat.name}</td>
          <td>{cat.subname}</td>
          <td>{cat.description}</td>
          <td>{cat.parent__category_id}</td>
          <td className="d-flex flex-column gap-3">
            <Link
              className="btn btn-sm btn-outline-info"
              to={`/dashboard/category/${cat.category_id}`}
            >
              Podkategorije
            </Link>
            <Link
              className="btn btn-sm btn-outline-warning"
              to={`/dashboard/category/change/${cat.category_id}`}
            >
              Izmeni
            </Link>
            <Link
              className="btn btn-sm btn-outline-success"
              to={`/dashboard/upload/${cat.category_id}`}
              onClick={() => dispatch(toggleModal(true))}
            >
              Fotografija
            </Link>
            <Link
              className="btn btn-sm btn-outline-danger"
              to={`/dashboard/category/delete-child/${cat.category_id}`}
              onClick={() => dispatch(toggleModal(true))}
            >
              Obriši
            </Link>
          </td>
        </tr>
      );
    });
  };
  
  return (
    <>
      {categoryChild[0] ? (
        <>
          <h2 className="fs-3 text-center mt-3">Kategorija {category.name}</h2>
          <div className="d-flex justify-content-between ">
            <Link to={`/dashboard/category/add-child/${category.category_id}`}>
              Dodaj podkategoriju {category.name}
            </Link>
            <Link
              className="btn btn-outline-secondary "
              to={
                category.parent__category_id
                  ? `/dashboard/category/${category.parent__category_id}`
                  : `/dashboard/category#${category.category_id}`
              }
            >
              Nazad
            </Link>
          </div>
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">id</th>
                <th scope="col">Ime</th>
                <th scope="col">Podnaslov</th>
                <th scope="col">Opis</th>
                <th scope="col">id kategorije iznad</th>
                <th scope="col">Akcija</th>
              </tr>
            </thead>
            <tbody>{renderChildCategory()}</tbody>
          </table>
        </>
      ) : (
        <div className="d-flex flex-column align-items-center gap-5 mt-5">
          <h5 className="text-center text-danger">Nema više kategorija</h5>
          <Link to={`/dashboard/category/add-child/${category.category_id}`}>
            Dodaj podkategoriju {category.name}
          </Link>
          <Link
            to={`/dashboard/article/category/${category.category_id}`}
            className="btn btn-outline-success"
          >
            Proizvodi u kategoriji {category.name}
          </Link>
          <Link
            className="btn btn-outline-secondary"
            to={
              category.parent__category_id
                ? `/dashboard/category/${category.parent__category_id}`
                : `/dashboard/category#${category.category_id}`
            }
          >
            Nazad
          </Link>
        </div>
      )}
    </>
  );
};

export default CategoryChildPageComponent;
