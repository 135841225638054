import axios from 'axios';

export const loginUser = (payload) => axios.post('/auth/login', payload);
export const registerUser = (payload) => axios.post('/auth/register', payload);

export const removeUserFromLocalStorage = () => localStorage.removeItem('termosolar');

export const getUser = (user_id) => axios.get(`/auth/user/${user_id}`);

export const isUserLoggedIn = () => localStorage.getItem('termosolar');

export const isAdminUser = () => isUserLoggedIn() && (JSON.parse(isUserLoggedIn())?.role === 'admin'); // true or false


//export const isAdminUser = () => isUserLoggedIn() && JSON.parse(isUserLoggedIn())?.isAdmin; // true or false
//export const setUserToLocalStorage = (userObj) => localStorage.setItem('termosolar_user', JSON.stringify(userObj));
//export const setTokenToLocalStorage = (token) => localStorage.setItem('termosolar_token', token);