import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../redux/loader.slicer';
import { AddChildCategory } from '../services/admin.service';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';

const AddChildCategoryPageComponent = () => {
  const [category, setCategory] = useState({});
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let copyCategory = { ...category, parent__category_id: params.category_id };
    setCategory(copyCategory);
  }, [params.category_id, category]);

  const handleChange = (e) => {
    let inputValue = e.target.value;
    let inputName = e.target.name;
    let copyCategory = { ...category };
    copyCategory[inputName] = inputValue;
    setCategory(copyCategory);
  };

  const onChangeSubmit = (e) => {
    e.preventDefault();
    dispatch(toggleLoader(true));
    AddChildCategory(category)
      .then((res) => {
        toast.success(res.data.message);
        navigate(`/dashboard/category/${res.data.parent__category_id}`);
      })
      .catch((err) => toast.error(err.response.data))
      .finally(() => dispatch(toggleLoader(false)));
  };

  return (
    <div className="col-12 col-sm-6 m-auto my-3 text-start">
      <h3 className="text-center fs-5 mb-3 text-danger">
        Kategorija {category.name}
      </h3>
      <form onSubmit={onChangeSubmit}>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Ime
          </label>
          <input
            type="text"
            name="name"
            className="form-control"
            id="name"
            aria-describedby="nameHelp"
            onChange={(e) => handleChange(e)}
            maxLength={128}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="subname" className="form-label">
            Podnaslov
          </label>
          <input
            type="text"
            name="subname"
            className="form-control"
            id="subname"
            aria-describedby="subnameHelp"
            onChange={(e) => handleChange(e)}
            maxLength={128}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Opis
          </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            onChange={(e) => handleChange(e)}
            maxLength={255}
            rows={4}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="image_path" className="form-label">
            Putanja slike
          </label>
          <input
            type="text"
            name="image_path"
            className="form-control"
            id="image_path"
            aria-describedby="image_pathHelp"
            onChange={(e) => handleChange(e)}
            maxLength={128}
            disabled
          />
        </div>
        <div className="mb-3">
          <label htmlFor="parent__category_id" className="form-label">
            id kategorije iznad
          </label>
          <input
            type="number"
            name="parent__category_id"
            className="form-control"
            id="parent__category_id"
            aria-describedby="parent__category_idHelp"
            onChange={(e) => handleChange(e)}
            defaultValue={category.parent__category_id}
            readOnly
          />
        </div>
        <div className='d-flex justify-content-between'>
          <button type="submit" className="btn btn-danger">
            Dodaj podkategoriju
          </button>
          <Link
            className="btn btn-outline-secondary "
            to={
              category.parent__category_id
                ? `/dashboard/category/${category.parent__category_id}`
                : `/dashboard/category#${category.category_id}`
            }
          >
            Nazad
          </Link>
        </div>
      </form>
    </div>
  );
};

export default AddChildCategoryPageComponent;
