import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  article: null,
};

const articleSlicer = createSlice({
  name: 'article',
  initialState: initialState,
  reducers: {
    saveArticle(state, data) {
      state.article = data.payload;
    },
    removeArticle(state) {
      state.article = null;
    },
  },
});

export const { saveArticle, removeArticle } = articleSlicer.actions;
export default articleSlicer.reducer;
