import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ChangeAdminUser, GetAdminUser } from '../services/admin.service';
import { toggleLoader } from '../redux/loader.slicer';
import { toggleModal } from '../redux/modal.slicer';

const UserComponent = () => {
  const userStore = useSelector((state) => state.userStore.user); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    user_id: '',
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    gender: '',
    street: '',
    street_number: '',
    postal_code: '',
    city: '',
    phone_number: '',
    is_active: '',
    role: '',
    updated_at: '',
    created_at: '',
  });

  useEffect(() => {
    dispatch(toggleLoader(true));
    GetAdminUser(params.user_id)
      .then((res) => {
        setUser(res.data[0]);
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(toggleLoader(false)));
  }, [dispatch, params.user_id, navigate, userStore]);

  const changePassword = (e) => {
    e.preventDefault();
    const passwordType = document.getElementById('password');
    if (passwordType.type === 'password') {
      passwordType.type = 'text';
      passwordType.removeAttribute('disabled');
      e.target.innerText = 'Ne menjaj lozinku';
    } else {
      passwordType.type = 'password';
      passwordType.setAttribute('disabled', '');
      e.target.innerText = 'Promeni lozinku';
    }
  };

  const handleUser = (e) => {
    let inputValue = e.target.value;
    let inputName = e.target.name;
    let copyUser = { ...user };
    copyUser[inputName] = inputValue;
    setUser(copyUser);
  };

  const onLoginSubmit = (e) => {
    e.preventDefault();
    const inputPasswordDisabled = document
      .getElementById('password')
      .hasAttribute('disabled');
    let copyUser = {};
    if (inputPasswordDisabled) {
      copyUser = { ...user, password: '' };
    } else {
      copyUser = user;
    }
    ChangeAdminUser(copyUser)
      .then((res) => {
        toast.success(res.data);
        navigate(`/dashboard/users#${user.user_id}`);
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  const renderForm = () => {
    return (
      <>
        <h5
          className={
            user.role === 'admin'
              ? 'text-primary text-center'
              : 'text-danger text-center '
          }
        >{`${user.role === 'user' ? 'Korisnik' : 'Admin'}: ${user.first_name} ${
          user.last_name
        }`}</h5>
        <form>
          <div className="mb-3">
            <label htmlFor="user_id" className="form-label">
              Korisnikov id
            </label>
            <input
              type="text"
              name="user_id"
              className="form-control"
              id="user_id"
              aria-describedby="user_id"
              disabled
              defaultValue={user.user_id}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email address
            </label>
            <input
              type="email"
              name="email"
              className={
                user.role === 'admin'
                  ? 'form-control text-primary'
                  : 'form-control'
              }
              id="email"
              aria-describedby="emailHelp"
              defaultValue={user.email}
              disabled
              onChange={(e) => handleUser(e)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="first_name" className="form-label">
              Ime
            </label>
            <input
              type="text"
              name="first_name"
              className="form-control"
              id="first_name"
              aria-describedby="first_name"
              defaultValue={user.first_name}
              onChange={(e) => handleUser(e)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="last_name" className="form-label">
              Prezime
            </label>
            <input
              type="text"
              name="last_name"
              className="form-control"
              id="last_name"
              aria-describedby="last_name"
              defaultValue={user.last_name}
              onChange={(e) => handleUser(e)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label me-3">
              Lozinka
            </label>
            <button
              onClick={(e) => changePassword(e)}
              className="btn btn-sm btn-outline-secondary "
            >
              Promeni lozinku
            </button>
            <input
              type="password"
              name="password"
              className="form-control"
              id="password"
              disabled
              defaultValue={user.password}
              onChange={(e) => handleUser(e)}
            />
          </div>
          <div className="mb-3">
            <label className="me-3" htmlFor="gender">
              Pol
            </label>
            <select
              value={user.gender}
              name="gender"
              id="gender"
              onChange={(e) => handleUser(e)}
            >
              <option value="unspecified">neodredjeno</option>
              <option value="male">muško</option>
              <option value="female">žensko</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="street" className="form-label">
              Ulica
            </label>
            <input
              type="text"
              name="street"
              className="form-control"
              id="street"
              aria-describedby="first_name"
              defaultValue={user.street}
              onChange={(e) => handleUser(e)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="street_number" className="form-label">
              Kućni broj
            </label>
            <input
              type="text"
              name="street_number"
              className="form-control"
              id="street_number"
              aria-describedby="street_number"
              defaultValue={user.street_number}
              onChange={(e) => handleUser(e)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="postal_code" className="form-label">
              Poštanski broj
            </label>
            <input
              type="number"
              name="postal_code"
              className="form-control"
              id="postal_code"
              aria-describedby="postal_code"
              defaultValue={user.postal_code}
              onChange={(e) => handleUser(e)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="city" className="form-label">
              Mesto stanovanja
            </label>
            <input
              type="text"
              name="city"
              className="form-control"
              id="city"
              aria-describedby="city"
              defaultValue={user.city}
              onChange={(e) => handleUser(e)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="phone_number" className="form-label">
              Telefon
            </label>
            <input
              type="text"
              name="phone_number"
              className="form-control"
              id="phone_number"
              aria-describedby="phone_number"
              defaultValue={user.phone_number}
              onChange={(e) => handleUser(e)}
            />
          </div>
          <div
            className={
              user.is_active === 'inactive' ? 'text-danger mb-3' : 'mb-3'
            }
          >
            <label className="me-3" htmlFor="is_active">
              {user.is_active === 'inactive'
                ? 'Korisnički nalog je blokiran'
                : 'Korisnički nalog'}
            </label>
            <select
              name="is_active"
              id="is_active"
              onChange={(e) => handleUser(e)}
              value={user.is_active}
            >
              <option value="pending">čeka validaciju</option>
              <option value="active">aktivan</option>
              <option value="inactive">neaktivan</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="me-3" htmlFor="role">
              Uloga naloga
            </label>
            <select
              name="role"
              id="role"
              disabled={userStore?.user_id === user.user_id ? 'disabled' : null}
              onChange={(e) => handleUser(e)}
              value={user.role}
            >
              <option value="user">korisnik</option>
              <option value="admin">administrator</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="updated_at" className="form-label">
              Nalog je ažuriran
            </label>
            <input
              type="text"
              name="updated_at"
              className="form-control"
              id="updated_at"
              aria-describedby="updated_at"
              disabled
              defaultValue={user.updated_at}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="created_at" className="form-label">
              Nalog je kreiran
            </label>
            <input
              type="text"
              name="created_at"
              className="form-control"
              id="created_at"
              aria-describedby="created_at"
              disabled
              defaultValue={user.created_at}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="created_at" className="form-label">
              <Link
                className="btn btn-sm btn-warning"
                to={`/dashboard/user/delete/${user.user_id}`}
                onClick={() => dispatch(toggleModal(true))}
              >
                Obriši korisnika
              </Link>
            </label>
          </div>
          <button
            onClick={onLoginSubmit}
            type="submit"
            className="btn btn-outline-danger form-control"
          >
            Sačuvaj promene
          </button>
        </form>
        <Link
          to={`/dashboard/users#${user.user_id}`}
          className="btn btn-outline-secondary form-control mt-3"
        >
          Nazad
        </Link>
      </>
    );
  };

  return (
    <div className="col-12 col-md-8 col-lg-6 col-xxl-5 m-auto">
      {renderForm()}
    </div>
  );
};

export default UserComponent;
