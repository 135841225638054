import React from 'react';
import App from '../App';
import ErrorPageComponent from '../pages/ErrorPage.Component';
import HomePageComponent from '../pages/HomePage.Component';
import LoginPageComponent from '../pages/LoginPage.Component';
import RegisterPageComponent from '../pages/RegisterPage.Component';
import ContactPageComponent from '../pages/ContactPage.Component';
import ActivationAccountPageComponent from '../pages/ActivationAccountPage.Component';
import UserPageComponent from '../pages/UserPage.Component';
import CategoryMainChildPageComponent from '../pages/CategoryMainChildPage.Component';
import CategoryChildrenComponent from '../components/categories/CategoryChildren.Component';
import AdminPageComponent from '../admin/AdminPage.Component';
import AdminHomePageComponent from '../admin/AdminHomePage.Component';
import UsersComponents from '../admin/Users.Component';
import UserComponents from '../admin/User.Component';
import UserChangeFirstName from '../components/user/UserChangeFirstName';
import UserChangeLastName from '../components/user/UserChangeLastName';
import UserChangePassword from '../components/user/UserChangePassword';
import UserChangePhone from '../components/user/UserChangePhone';
import UserChangeStreet from '../components/user/UserChangeStreet';
import UserChangeNumber from '../components/user/UserChangeNumber';
import UserChangeCity from '../components/user/UserChangeCity';
import UserChangePostal from '../components/user/UserChangePostal';
import UserChangeGender from '../components/user/UserChangeGender';
import ForgotPasswordPageComponent from '../pages/ForgotPasswordPage.Component';
import ResetPasswordPageComponent from '../pages/ResetPasswordPage.Component';
import UserDeleteAccount from '../components/user/UserDeleteAccount';
import CategoryPageComponent from '../admin/CategoryPage.Component';
import CategoryChildPageComponent from '../admin/CategoryChildPage.Component';
import CategoryChangePageComponent from '../admin/CategoryChangePage.Component';
import AddMainCategoryPageComponent from '../admin/AddMainCategoryPage.Component';
import CategoryDeletePageComponent from '../admin/CategoryDeletePage.Component';
import UserDeletePageComponent from '../admin/UserDeletePage.Component';
import CategoryDeleteChildPageComponent from '../admin/CategoryDeleteChildPage.Component';
import AddChildCategoryPageComponent from '../admin/AddChildCategoryPage.Component';
import ArticlePageComponent from '../admin/ArticlePage.Component';
import ArticleCategoryParentPageComponent from '../admin/ArticleCategoryParentPage.Component';
import ArticleChangePageComponent from '../admin/ArticleChangePage.Component';
import ArticleAddPageComponent from '../admin/ArticleAddPage.Component';
import ArticleChangePricePageComponent from '../admin/ArticleChangePricePage.Component';
import ArticleDeletePageComponent from '../admin/ArticleDeletePage.Component';
import UploadFilePageComponent from '../admin/UploadFilePage.Component';
import SearchPageComponent from '../pages/SearchPage.Component';
import AuthGuardComponent from '../utils/AuthGuard.Component';
import AdminGuardComponent from '../utils/AdminGuard.Component';
import AddToCartPageComponent from '../pages/AddToCartPage.Component';
import CartPageComponent from '../pages/CartPage.Component';
import CartUserDataPageComponent from '../pages/CartUserDataPage.Component';
import CartProInvoicePageComponent from '../pages/CartProInvoicePage.Component';
import PaymentPageComponent from '../pages/PaymentPage.Component';
import PaymentBankPageComponent from '../pages/PaymentBankPage.Component';
import PaymentDeliveryServicePageComponent from '../pages/PaymentDeliveryServicePage.Component';
import PaymentCardPageComponent from '../pages/PaymentCardPage.Component';
import PurchaseConfirmationPageComponent from '../pages/PurchaseConfirmationPage.Component';
import UserOrdersPageComponent from '../pages/UserOrdersPage.Component';
import AdminOrdersPageComponent from '../admin/AdminOrdersPage.Component';
import AdminUserOrdersPageComponent from '../admin/AdminUserOrdersPage.Component';
import AdminUserOrderHistoryPageComponent from '../admin/AdminUserOrderHistoryPage.Component';
import AdminUserOrderHistoryDetailsPageComponent from '../admin/AdminUserOrderHistoryDetailsPage.Component';
import AdminUsersEmailPageComponent from '../admin/AdminUsersEmailPage.Component';
import AdminUserEmailDetailsPageComponent from '../admin/AdminUserEmailDetailsPage.Component';
import AboutUsPageComponent from '../pages/AboutUsPage.Component';
import GalleryPageComponent from '../pages/GalleryPage.Component';
import AdminGalleryPageComponent from '../admin/AdminGalleryPage.Component';
import AdminGalleryDetailsPageComponent from '../admin/AdminGalleryDetailsPage.Component';
import TermsAndConditionsPageComponent from '../pages/TermsAndConditionsPage.Component';

const routes = [
  {
    path: '/',
    element: <App />, // da bi prikazao children u app se mora upisati <Outlet />
    errorElement: <ErrorPageComponent />,
    children: [
      {
        path: '/',
        element: <HomePageComponent />,
      },
      {
        path: 'terms-and-conditions',
        element: <TermsAndConditionsPageComponent />,
      },
      {
        path: 'search/:searchParams', // Za URLParams registrujemo rutu za query params ne treba
        element: <SearchPageComponent />,
      },
      {
        path: 'about-us',
        element: <AboutUsPageComponent />,
      },
      {
        path: 'gallery',
        element: <GalleryPageComponent />,
      },
      {
        path: 'contact',
        element: <ContactPageComponent />,
      },
      {
        path: 'login',
        element: <LoginPageComponent />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordPageComponent />,
      },
      {
        path: 'reset-password/:user_id',
        element: <ResetPasswordPageComponent />,
      },
      {
        path: 'register',
        element: <RegisterPageComponent />,
      },
      {
        path: 'activate-account/:user_id',
        element: <ActivationAccountPageComponent />,
      },
      {
        path: 'user/:user_id', // AuthGuardComponent komponenta proverava da li je korisnik ulogovan iz localStorage
        element: (
          <AuthGuardComponent>
            <UserPageComponent />
          </AuthGuardComponent>
        ),
        children: [
          {
            path: 'change-password',
            element: <UserChangePassword />,
          },
          {
            path: 'change-first-name',
            element: <UserChangeFirstName />,
          },
          {
            path: 'change-last-name',
            element: <UserChangeLastName />,
          },
          {
            path: 'change-gender',
            element: <UserChangeGender />,
          },
          {
            path: 'change-phone',
            element: <UserChangePhone />,
          },
          {
            path: 'change-street',
            element: <UserChangeStreet />,
          },
          {
            path: 'change-number',
            element: <UserChangeNumber />,
          },
          {
            path: 'change-city',
            element: <UserChangeCity />,
          },
          {
            path: 'change-postal',
            element: <UserChangePostal />,
          },
          {
            path: 'delete-account',
            element: <UserDeleteAccount />,
          },
        ],
      },
      {
        path: 'category',
        element: <HomePageComponent />,
      },
      {
        path: 'category/:category_id',
        element: <CategoryMainChildPageComponent />,
      },
      {
        path: 'category/:category_id/article/:article_id/key/:article_key/limit/:limit/offset/:offset',
        // AuthGuardComponent komponenta proverava da li je korisnik ulogovan iz localStorage
        element: (
          <AuthGuardComponent>
            <AddToCartPageComponent />
          </AuthGuardComponent>
        ),
      },
      {
        path: 'category/children/:category_id',
        element: <CategoryChildrenComponent />,
      },
      {
        path: 'get/category/:category_id',
        element: <CategoryChildrenComponent />,
      },
      {
        path: 'cart',
        element: (
          <AuthGuardComponent>
            <CartPageComponent />
          </AuthGuardComponent>
        ),
      },
      {
        path: 'cart/delete/:cart_id',
        element: (
          <AuthGuardComponent>
            <CartPageComponent />
          </AuthGuardComponent>
        ),
      },
      {
        path: 'cart/user-data',
        element: (
          <AuthGuardComponent>
            <CartUserDataPageComponent />
          </AuthGuardComponent>
        ),
      },
      {
        path: 'cart/payment',
        element: (
          <AuthGuardComponent>
            <PaymentPageComponent />
          </AuthGuardComponent>
        ),
      },
      {
        path: 'cart/delivery-service',
        element: (
          <AuthGuardComponent>
            <PaymentDeliveryServicePageComponent />
          </AuthGuardComponent>
        ),
      },
      {
        path: 'cart/card',
        element: (
          <AuthGuardComponent>
            <PaymentCardPageComponent />
          </AuthGuardComponent>
        ),
      },
      {
        path: 'cart/purchase-confirmation',
        element: (
          <AuthGuardComponent>
            <PurchaseConfirmationPageComponent />
          </AuthGuardComponent>
        ),
      },
      {
        path: 'cart/user-orders',
        element: (
          <AuthGuardComponent>
            <UserOrdersPageComponent />
          </AuthGuardComponent>
        ),
      },
    ],
  },
  {
    path: 'cart/pro-invoice',
    element: (
      <AuthGuardComponent>
        <CartProInvoicePageComponent />
      </AuthGuardComponent>
    ),
  },
  {
    path: 'cart/payment-bank',
    element: (
      <AuthGuardComponent>
        <PaymentBankPageComponent />
      </AuthGuardComponent>
    ),
  },
  {
    path: '/dashboard',
    errorElement: <ErrorPageComponent />,
    element: (
      <AdminGuardComponent>
        <AdminPageComponent />
      </AdminGuardComponent>
    ),
    children: [
      {
        path: '',
        element: <AdminHomePageComponent />,
      },
      {
        path: 'upload/:category_id',
        element: <UploadFilePageComponent />,
      },
      {
        path: 'users',
        element: <UsersComponents />,
      },
      {
        path: 'user/:user_id',
        element: <UserComponents />,
      },
      {
        path: 'user/delete/:user_id',
        element: <UserDeletePageComponent />,
      },
      {
        path: 'category',
        element: <CategoryPageComponent />,
      },
      {
        path: 'category/add',
        element: <AddMainCategoryPageComponent />,
      },
      {
        path: 'category/add-child/:category_id',
        element: <AddChildCategoryPageComponent />,
      },
      {
        path: 'category/:category_id',
        element: <CategoryChildPageComponent />,
      },
      {
        path: 'category/change/:category_id',
        element: <CategoryChangePageComponent />,
      },
      {
        path: 'category/delete/:category_id',
        element: <CategoryDeletePageComponent />,
      },
      {
        path: 'category/delete-child/:category_id',
        element: <CategoryDeleteChildPageComponent />,
      },
      {
        path: 'article/all-article',
        element: <ArticlePageComponent />,
      },
      {
        path: 'article/all-article/:searchParams',
        element: <ArticlePageComponent />,
      },
      {
        path: 'article/change-price/:article_id',
        element: <ArticleChangePricePageComponent />,
      },
      {
        path: 'article/category/:category_id',
        element: <ArticleCategoryParentPageComponent />,
      },
      {
        path: 'article/change/:article_id',
        element: <ArticleChangePageComponent />,
      },
      {
        path: 'article/add',
        element: <ArticleAddPageComponent />,
      },
      {
        path: 'article/delete/:article_id',
        element: <ArticleDeletePageComponent />,
      },
      {
        path: 'orders',
        element: <AdminOrdersPageComponent />,
      },
      {
        path: 'orders/:user_id',
        element: <AdminUserOrdersPageComponent />,
      },
      {
        path: 'orders-history',
        element: <AdminUserOrderHistoryPageComponent />,
      },
      {
        path: 'orders-history/:user_id',
        element: <AdminUserOrderHistoryDetailsPageComponent />,
      },
      {
        path: 'users-email-history',
        element: <AdminUsersEmailPageComponent />,
      },
      {
        path: 'user-email-details/:emails_id',
        element: <AdminUserEmailDetailsPageComponent />,
      },
      {
        path: 'gallery',
        element: <AdminGalleryPageComponent />,
      },
      {
        path: 'gallery/:photo_id',
        element: <AdminGalleryDetailsPageComponent />,
      },
    ],
  },
];

export default routes;
