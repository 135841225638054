import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { sendContactMail } from '../services/mail.service';
import { FaPhoneAlt, FaHome } from 'react-icons/fa';

const ContactPageComponent = () => {
  const [inputData, setInputData] = useState({});
  const [isSend, setIsSend] = useState(false);
  const [responseMsg, setResponseMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();

  const onInputHandler = (e) => {
    let copyInputData = { ...inputData };
    copyInputData[e.target.name] = e.target.value;
    setInputData(copyInputData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!inputData.email) {
      setErrMsg('Email adresa je obavezna!');
    } else if (!inputData.subject) {
      setErrMsg('Naslov je obavezan!');
    } else if (!inputData.message) {
      setErrMsg('Poruka je obavezna!');
    } else {
      setErrMsg('');
      //console.log(inputData);
      sendContactMail(inputData)
        .then((res) => {
          //console.log(res.data);
          setResponseMsg('Poruka je uspešno poslata.');
        })
        .catch((err) => {
          setResponseMsg('Poruka nije poslata.');
          console.log(err);
        })
        .finally(() => {
          setIsSend(true)
          setTimeout(() => {
            navigate('/');
          }, 3000);
        });
    }
  };

  return (
    <>
      <div className="container-lg">
        <h1 className="text-center text-secondary fs-3 my-3">Pišite nam</h1>
        <p className="text-secondary my-3">
          Ako Vam nešto nije jasno, u nedoumici ste oko nečega, niste sigurni
          koja snaga je potrebna za grejanje ili hladjenje prostorije koju imate
          ili imate neko drugo pitanje. Slobodno nam pišite, odgovorićemo Vam u
          najkraćem roku.
        </p>
      </div>
      <div className="col-sm-8 col-md-6 col-xl-4 px-3 px-sm-0 mt-3 m-auto ">
        {!isSend ? (
          <form onSubmit={submitHandler}>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email adresa
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                name="email"
                onInput={onInputHandler}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="subject" className="form-label">
                Naslov
              </label>
              <input
                type="text"
                className="form-control"
                id="subject"
                name="subject"
                onInput={onInputHandler}
              />
            </div>
            <div className="mb-3">
              <textarea
                className="form-control"
                name="message"
                placeholder="message"
                cols="30"
                rows="10"
                onInput={onInputHandler}
              ></textarea>
            </div>
            <button type="submit" className="btn btn-danger form-control">
              Send
            </button>
          </form>
        ) : (
          <h4 className="text-center">{responseMsg}</h4>
        )}

        <div className="text-danger my-3">{errMsg ? errMsg : null}</div>
      </div>
      <div className="container-lg mt-5">
        <div className="bg-danger rounded text-white py-1">
          <h3 className="fs-5 text-center mt-3">
            Za sve informacije pozovite <FaPhoneAlt />{' '}
            <Link
              className="text-white link-underline link-underline-opacity-0"
              to="tel:+0691022170"
            >
              0691022170
            </Link>
          </h3>
          <h3 className="fs-5 text-center">
            Naša adresa je <FaHome />{' '}
            <Link
              className="text-white link-underline link-underline-opacity-0"
              to="https://maps.app.goo.gl/Vqey1srQaKgTecap8"
              target="_blank"
            >
              Svetosavska 66, Kikinda
            </Link>
          </h3>
        </div>
        <div
          style={{ maxWidth: '100%', height: '400px' }}
          className="m-auto my-5"
        >
          <iframe
            title="Termosolar location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2780.2782193043595!2d20.45554167670699!3d45.825711909244845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4744ded44c976141%3A0x5b1936a7bfe6888e!2z0KHQstC10YLQvtGB0LDQstGB0LrQsCA2Niwg0JrQuNC60LjQvdC00LAgMjMzMDA!5e0!3m2!1ssr!2srs!4v1701253152388!5m2!1ssr!2srs"
            style={{ width: '100%', height: '100%', border: '0' }}
            allowFullScreen
            loading="lazy"
          />
        </div>
      </div>
    </>
  );
};

export default ContactPageComponent;
