import axios from 'axios';

export const GetArticleByCategoryId = (category_id, limit, offset) => axios.get(`/article/get/category/${category_id}/${limit}/${offset}`);
export const GetArticleInPromotion = (limit, offset) => axios.get(`/article/get/promotion/${limit}/${offset}`);
export const GetArticle = (article_id) => axios.get(`/article/category/:category_id/article/${article_id}/key/:article_key/limit/:limit/offset/:offset`);
export const SearchArticles = (payload) => axios.post('/article/search', payload);

export const GetMainCategory = () => axios.get(`/article/category/main`);
export const GetCategory = (category_id) => axios.get(`/article/category/${category_id}`);
export const GetChildrenCategory = (category_id) => axios.get(`/article/category/children/${category_id}`);

export const GetAllPhotosFromGallery = (limit, offset) => axios.get(`article/gallery/${limit}/${offset}`);