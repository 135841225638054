import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toggleLoader } from '../../redux/loader.slicer';
import { GetChildrenCategory } from '../../services/article.service';
import { useDispatch } from 'react-redux';

const CategoryDropMenuComponent = ({ category_id }) => {
  const dispatch = useDispatch();
  const [categoryChildMenu, setCategoryChildMenu] = useState({});

  useEffect(() => {
    dispatch(toggleLoader(true));
    GetChildrenCategory(category_id)
      .then((res) => {
        setCategoryChildMenu(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(toggleLoader(false)));
  }, [dispatch, category_id]);

  const renderCategoryMenu = () => {
    return categoryChildMenu.map((cat) => {
      return (
        <li key={`${cat.name.split(' ', 1)}_${cat.category_id}`}>
          <Link
            className="dropdown-item"
            to={`/category/${cat.category_id}`}
          >
            {cat.name}
          </Link>
        </li>
      );
    });
  };

  return (
    <>
      {categoryChildMenu.length > 0 && (
        <div className="p-holder collapse d-lg-block">
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              to="#"
              role="button"
              aria-expanded="false"
            >
              Izaberite
            </Link>
            <ul className="dropdown-menu">{renderCategoryMenu()}</ul>
          </li>
        </div>
      )}
    </>
  );
};

export default CategoryDropMenuComponent;
