import { Link } from 'react-router-dom';

const FooterComponent = () => {
  return (
    <footer className="my-footer print-hide">
      <small>
        &copy; 2023 <Link to="/">Termosolar</Link> by
        <Link target="noreferrer" to="https://webdevelopermaxi.eu.org">
          <span> Maxi </span>
        </Link>
        . <Link to="/terms-and-conditions">All Rights Reserved.</Link>
      </small>
    </footer>
  );
};

export default FooterComponent;
