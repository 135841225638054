import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toggleLoader } from '../redux/loader.slicer';
import { useDispatch } from 'react-redux';
import { AddArticle, GetAllAdminCategory } from '../services/admin.service';

const ArticleAddPageComponent = () => {
  const [article, setArticle] = useState({
    article_key: '',
    name: '',
    category_id: '',
    exceptt: '',
    description: '',
    status: 'available',
    is_promoted: 0,
    price: '',
  });
  const [category, setCategory] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(toggleLoader(true));
    GetAllAdminCategory()
      .then((res) => {
        setCategory(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(toggleLoader(false)));
  }, [dispatch]);

  const handleChange = (e) => {
    let inputValue = e.target.value;
    let inputName = e.target.name;
    let copyArticle = { ...article };
    copyArticle[inputName] = inputValue;
    setArticle(copyArticle);
  };

  const onChangeSubmit = (e) => {
    e.preventDefault();
    dispatch(toggleLoader(true));
    AddArticle(article)
      .then((res) => {
        toast.success(res.data);
        navigate(`/dashboard/article/all-article#${article.article_key}`);
      })
      .catch((err) => toast.error(err.response.data))
      .finally(() => dispatch(toggleLoader(false)));
  };

  const categorySelect = () => {
    return category.map((cat) => {
      return (
        <option key={cat.category_id} value={cat.category_id}>
          {cat.name}
        </option>
      );
    });
  };

  return (
    <div className="col-12 col-sm-6 m-auto my-3 text-start">
      <h3 className="text-center fs-5 mb-3 text-danger">
        Proizvod {article.name}
      </h3>
      <form onSubmit={onChangeSubmit}>
        <div className="mb-3">
          <label htmlFor="category_id" className="form-label">
            Izaberi kategoriju:
          </label>
          <br />
          <select
            id="category_id"
            name="category_id"
            onChange={(e) => handleChange(e)}
          >
            {categorySelect()}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="article_key" className="form-label">
            Šifra proizvoda:
          </label>
          <input
            type="number"
            name="article_key"
            className="form-control"
            id="article_key"
            aria-describedby="article_key"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Ime proizvoda:
          </label>
          <input
            type="text"
            name="name"
            className="form-control"
            id="name"
            aria-describedby="nameHelp"
            onChange={(e) => handleChange(e)}
            maxLength={128}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exceptt" className="form-label">
            Kratak opis proizvoda:
          </label>
          <textarea
            className="form-control"
            id="exceptt"
            name="exceptt"
            onChange={(e) => handleChange(e)}
            maxLength={255}
            rows={2}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Opis proizvoda:
          </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            onChange={(e) => handleChange(e)}
            maxLength={255}
            rows={4}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="status" className="form-label me-3">
            Status proizvoda:
          </label>
          <select id="status" name="status" onChange={(e) => handleChange(e)}>
            <option value="available">Na stanju</option>
            <option value="visible">Nije dostupno</option>
            <option value="hidden">Sakriveno</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="is_promoted" className="form-label me-3">
            Da li je proizvod na promociji:
          </label>
          <select
            id="is_promoted"
            name="is_promoted"
            onChange={(e) => handleChange(e)}
          >
            <option value="0">Ne</option>
            <option value="1">Da</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="price" className="form-label">
            Cena proizvoda:
          </label>
          <input
            type="number"
            name="price"
            className="form-control"
            id="price"
            aria-describedby="price"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="d-flex justify-content-between">
          <button type="submit" className="btn btn-danger">
            Dodaj proizvod
          </button>
          <Link className="btn btn-outline-secondary " to="/dashboard/article/all-article">
            Nazad
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ArticleAddPageComponent;
