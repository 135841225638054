import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalComponents from '../components/modal/Modal.Components';
import { useDispatch } from 'react-redux';
import { toggleModal } from '../redux/modal.slicer';
import { changeUserPassword } from '../services/user.service';
import { removeUserFromLocalStorage } from '../services/auth.service';

const ResetPasswordPageComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userPassword, setUserPassword] = useState('');
    const { user_id } = useParams();
    let isLogged = true;

    useEffect(() => {
      dispatch(toggleModal(true));
    }, [dispatch]);

    const handleChangePassword = (e) => {
      let inputValue = e.target.value;
      let copyUserPassword = {...userPassword};
      copyUserPassword = inputValue;
      setUserPassword(copyUserPassword);
    };

    const onChangeSubmit = (e) => {
      e.preventDefault();
      const regex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})';
      if (!userPassword) {
          return toast.error('Polje ne sme biti prazno.');
        }
      if (!userPassword.match(regex)) {
        return toast.error(
            'Min 6 karaktera (1 vel slovo, 1 malo slovo, 1 spec. karakter )'
            );
        }
        changeUserPassword({ user_id: user_id, password: userPassword })
          .then((res) => {
            toast.success(res.data);
            dispatch(toggleModal(false));
            removeUserFromLocalStorage(); // Ukljanjamo korisnika iz local storage
            navigate('/login');
          })
          .catch((err) => {
            toast.error(err.response.data);
          });
    };

  return (
    <div>
      <ModalComponents>
        {isLogged && (
          <div className="col-12 col-sm-6 m-auto my-3 text-start">
            <form onSubmit={onChangeSubmit}>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Nova lozinka:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  aria-describedby="password"
                  placeholder="Unesi lozinku"
                  onChange={(e) => handleChangePassword(e)}
                  minLength={3}
                  maxLength={50}
                />
              </div>
              <button type="submit" className="btn btn-danger form-control">
                Izmeni
              </button>
            </form>
          </div>
        )}
      </ModalComponents>
    </div>
  );
}

export default ResetPasswordPageComponent;