import { Link } from 'react-router-dom';
import { BsTelephone } from 'react-icons/bs';
import {
  TfiFacebook,
  TfiLinkedin,
  TfiInstagram,
  TfiYoutube,
  TfiShoppingCartFull,
  TfiEmail,
} from 'react-icons/tfi';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findUserCart } from '../../../services/user.service';
import { saveCart } from '../../../redux/cart.slicer';

const NavBarTopComponent = () => {
  const userStore = useSelector((state) => state.userStore.user); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const cartStore = useSelector((state) => state.cartStore.cart);
  const [cart, setCart] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userStore) {
      findUserCart({ user_id: userStore.user_id })
        .then((res) => {
          if (res.status === 243) {
            dispatch(saveCart(null));
            return setCart([]);
          }
          setCart(res.data);
          dispatch(saveCart(res.data.length));
        })
        .catch((err) => console.log(err));
    }
  }, [userStore, cartStore, dispatch]);

  return (
    <div className="header-top">
      <Link
        to="https://www.facebook.com/profile.php?id=100039361004065"
        className="header-top-facebook icons"
        target="noreferrer"
      >
        <h5>
          <TfiFacebook />
        </h5>
      </Link>
      <Link
        to="https://www.linkedin.com"
        className="header-top-linkedin icons"
        target="noreferrer"
      >
        <h5>
          <TfiLinkedin />
        </h5>
      </Link>
      <Link
        to="https://www.instagram.com"
        className="header-top-instagram icons"
        target="noreferrer"
      >
        <h5>
          <TfiInstagram />
        </h5>
      </Link>
      <Link
        to="https://www.youtube.com/"
        className="header-top-instagram icons"
        target="noreferrer"
      >
        <h5>
          <TfiYoutube />
        </h5>
      </Link>
      <Link
        to="mailto:zdravko.djurdjevic66@gmail.com"
        className="header-top-email icons"
      >
        <h5>
          <TfiEmail />
        </h5>
      </Link>
      <Link to="tel:+381691022170" className="header-top-email icons">
        <h5>
          <BsTelephone />
        </h5>
      </Link>
      <Link to="/cart" className="header-top-cart icons">
        <h4>
          <TfiShoppingCartFull />
        </h4>
        {cart.length > 0 && cart.length < 100 ? (
          <div className="header-top-cart-num">
            <h5 className="m-0">{cartStore}</h5>
          </div>
        ) : null}
      </Link>
    </div>
  );
};

export default NavBarTopComponent;
