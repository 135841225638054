import React, { useEffect, useState } from 'react';
import { GetAllUserOrders } from '../services/admin.service';
import { toggleLoader } from '../redux/loader.slicer';
import { useDispatch } from 'react-redux';
import { GiShoppingCart } from 'react-icons/gi';
import { Link } from 'react-router-dom';

const AdminOrdersPageComponent = () => {
  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLoader(true));
    GetAllUserOrders()
      .then((res) => {
        setOrders(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(toggleLoader(false)));
  }, [dispatch]);

  const renderTBody = () => {
    return orders.map((order, index) => {
        return (
          <tr id={order.user_id} key={index + '_' + order.order_id}>
            <th scope="row">{index + 1}</th>
            <td>{order.created_at}</td>
            <td>{`${order.first_name} ${order.last_name}`}</td>
            <td>{order.email}</td>
            <td>{order.orders_number}</td>
            <td>
              <Link to={`/dashboard/orders/${order.user_id}`}>
                <h6 className="text-center text-bg-danger fs-4 m-0">
                  <GiShoppingCart />
                </h6>
              </Link>
            </td>
          </tr>
        );
    })
    
  };

  return (
    <>
      {orders.length > 0 ? (
        <div>
          <h2 className="text-center fs-3 my-3">Porudžbine</h2>
          <div className="table-responsive">
            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Od dana</th>
                  <th scope="col">Ime</th>
                  <th scope="col">Email</th>
                  <th scope="col">Količina</th>
                  <th scope="col">Detaljnije</th>
                </tr>
              </thead>
              <tbody>{renderTBody()}</tbody>
            </table>
          </div>
        </div>
      ) : (
        <h2 className="text-center fs-3 my-3">Nema porudžbina</h2>
      )}
    </>
  );
};

export default AdminOrdersPageComponent;
