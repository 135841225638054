import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toggleLoader } from '../redux/loader.slicer';
import { AddFileToGallery } from '../services/admin.service';
import { GetAllPhotosFromGallery } from '../services/article.service';
import imageRoutes from '../routes/imageRoutes';

const AdminGalleryPageComponent = () => {
  const [images, setImages] = useState([]);
  const [description, setDescription] = useState('');
  const [file, setFile] = useState();
  const [paginationObject, setPaginationObject] = useState({offset:0, totalItems:0, limit:4})//Paginacija
  const limitNum = [2, 4, 6, 8, 10];
  const [addPhoto, setAddPhoto] = useState({next:1});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLoader(true));
    GetAllPhotosFromGallery(paginationObject.limit, paginationObject.offset)
      .then((res) => {
        setImages(res.data.images);
        setPaginationObject(res.data.pagination);
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(toggleLoader(false)));
  }, [dispatch, paginationObject.limit, paginationObject.offset, addPhoto]);

  const onClickHandle = (num) => {
    setPaginationObject((prevState) => ({...prevState, offset: num}));
  }

  const onClickPrevious = () => {
    if (paginationObject.offset > 0) {
      setPaginationObject((prevState) => ({ ...prevState, offset: prevState.offset - 1 }));
    }
  };

  const onClickNext = () => {
    let pagesNumber = Math.ceil(paginationObject.totalItems / paginationObject.limit)
    if (paginationObject.offset + 1 < pagesNumber) {
      setPaginationObject((prevState) => ({ ...prevState, offset: prevState.offset + 1 }));
    }
  };

  const renderPaginationNumber = () => {
    let pagesNumber = Math.ceil(
      paginationObject.totalItems / paginationObject.limit
    );
    const numPagesDisplayed = 5;
    let pagesArray = [];
    let currentPage = paginationObject.offset + 1;
    if (pagesNumber <= numPagesDisplayed) {
      pagesArray = [...Array(pagesNumber).keys()].map((num) => num + 1);
    } else {
      let startPage = Math.max(
        1,
        currentPage - Math.floor(numPagesDisplayed / 2)
      );
      let endPage = Math.min(pagesNumber, startPage + numPagesDisplayed - 1);

      if (startPage === 1) {
        endPage = numPagesDisplayed;
      } else if (endPage === pagesNumber) {
        startPage = pagesNumber - numPagesDisplayed + 1;
      }
      pagesArray = [...Array(endPage - startPage + 1).keys()].map(
        (num) => startPage + num
      );
    }

    return (
      <>
        <li
          className="page-item"
          onClick={() => onClickHandle(0)}
          disabled={paginationObject.offset === 0}
        >
          <p className="page-link" aria-label="First">
            <span aria-hidden="true">««</span>
          </p>
        </li>
        {pagesArray.map((num) => (
          <li
            key={'page-num-' + num}
            className={
              paginationObject.offset === num - 1
                ? 'active page-item'
                : 'page-item'
            }
            aria-current="page"
            onClick={() => onClickHandle(num - 1)}
          >
            <p className="page-link">{num}</p>
          </li>
        ))}
        <li
          className="page-item"
          onClick={() => onClickHandle(pagesNumber - 1)}
          disabled={paginationObject.offset === pagesNumber - 1}
        >
          <p className="page-link" aria-label="Last">
            <span aria-hidden="true">»»</span>
          </p>
        </li>
      </>
    );
  };


  const renderLimitDropDown = () => {
    return limitNum.map((num, index) => {
      return <li key={index}><p className="dropdown-item" onClick={()=>setPaginationObject((prevState)=>({...prevState,limit:num,offset:0}))}>{num}</p></li>
    })
  }

  const handleDescription = (e) => {
    setDescription(e.target.value);
  }

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    dispatch(toggleLoader(true));
    const formData = new FormData();
    formData.append('image', file);
    formData.append('description', description);
    AddFileToGallery(formData)
      .then((res) =>  {
        toast.success(res.data);
        setAddPhoto((prevState) =>({...prevState, next: prevState.next++}))
      })
      .catch((err) => toast.error(err.response.data))
      .finally(() => dispatch(toggleLoader(false)));
  };

  const renderCards = () => {
    return images.map((img, index) => {
      return (
        <div
          key={`${index}-${img.photo_id}`}
          className="card"
          style={{ width: '18rem' }}
        >
          <img
            src={`${imageRoutes}${img.image_path}`}
            className="card-img-top img-thumbnail"
            alt={`Img-${img.photo_id}`}
          />
          <div className="card-body">
            <p className="card-text">
              {img.description}
            </p>
            <Link to={`/dashboard/gallery/${img.photo_id}`} className="btn btn-danger">
              Detaljnije
            </Link>
          </div>
        </div>
      );
    })
  }

  return (
    <>
      <div className="col-12 col-sm-6 m-auto my-3 text-start">
        <form onSubmit={handleUpload} encType="multipart/form-data">
          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              <p className="text-danger">Kratak opis:</p>
            </label>
            <textarea
              className="form-control"
              id="description"
              name="description"
              onChange={(e) => handleDescription(e)}
            ></textarea>
          </div>
          <Link
            className="btn btn-sm btn-outline-secondary mb-3"
            to="https://imageresizer.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Promeni veličinu fotografije
          </Link>
          <div className="mb-3">
            <label htmlFor="image_path" className="form-label">
              <p className="text-danger">Dodaj fotografiju:</p>
            </label>
            <input
              type="file"
              multiple={false}
              className="form-control"
              id="image_path"
              aria-describedby="image_path"
              name="image_path"
              accept="image/*"
              onChange={handleFile}
            />
          </div>
          <button type="submit" className="btn btn-danger form-control">
            Sačuvaj
          </button>
        </form>
      </div>
      <div className="d-flex justify-content-center flex-wrap gap-3 mt-5">
        {renderCards()}
      </div>
      <div className="d-flex flex-wrap gap-3 justify-content-center mt-3 pagination-holder">
        <div className="dropdown">
          <button
            className="btn btn-sm btn-outline-secondary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Limit {paginationObject.limit}
          </button>
          <ul className="dropdown-menu">{renderLimitDropDown()}</ul>
        </div>
        <nav aria-label="Page navigation">
          <ul className="pagination pagination-sm justify-content-center">
            <li className="page-item" onClick={() => onClickPrevious()}>
              <p className="page-link" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </p>
            </li>
            {renderPaginationNumber()}
            <li className="page-item" onClick={() => onClickNext()}>
              <p className="page-link" aria-label="Next">
                <span aria-hidden="true">»</span>
              </p>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default AdminGalleryPageComponent;
