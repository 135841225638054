import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { removeUserFromLocalStorage } from '../../services/auth.service';
import { removeUser } from '../../redux/user.slicer';

const TokenExpComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const userStore = useSelector((state) => state.userStore.user); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
    const [isLogged, setIsLogged] = useState(false);
    const [showHide, setShowHide] = useState(false);

    useEffect(() => {
      if (userStore) {
        setIsLogged(true);
        setShowHide(true);
        setTimeout(() => {
          setShowHide(false);
        }, 10000);
      }
    }, [userStore]);

    const signOut = () => {
      removeUserFromLocalStorage();
      dispatch(removeUser());
      setIsLogged(false);
      navigate('/login');
      window.location.reload();
    }

    const renderTokenExp = () => {
      const date = new Date(userStore.token_exp);
      const tokenExp = date.toLocaleDateString('sr-SR', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'});
       if (Date.now() > userStore.token_exp) {
         toast.error(`Vaša prijava je istekla ${tokenExp}`);
         signOut();
      } else {
         toast.success(`Vaša prijava ističe ${tokenExp}`);
       }
    }

  return (
    <>
      {showHide && isLogged && renderTokenExp()}
    </>
  );
}

export default TokenExpComponent;