import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GetChildrenCategory } from '../../services/article.service';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../../redux/loader.slicer';
import CategoryDropMenuComponent from './CategoryDropMenu.Component';
import ArticleComponent from '../article/Article.Component';
import imageRoutes from '../../routes/imageRoutes';

const CategoryChildrenComponent = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [categoryChild, setCategoryChild] = useState({});
  const [parentCategoryId, setParentCategoryId] = useState('');

  useEffect(() => {
    dispatch(toggleLoader(true));
    GetChildrenCategory(params.category_id)
      .then((res) => {
        setCategoryChild(res.data);
        if (res.data.length > 0) {
          setParentCategoryId(res.data[0].parent__category_id);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(toggleLoader(false)));
  }, [dispatch, params.category_id]);

  const renderCategoryChildren = () => {
    return categoryChild.map((cat) => {
      return (
        <div
          key={cat.category_id}
          className="category-child-box text-center col-12 col-sm-6 col-md-4"
        >
          <div className="category-child-img">
            <Link to={`/category/${cat.category_id}`}>
              <img
                src={`${imageRoutes}${cat.image_path}`}
                className="img-fluid "
                alt={cat.name}
              />
            </Link>
          </div>
          <div className="category-child-text">
            <h6>
              <Link to={`/category/${cat.category_id}`}>
                {cat.name.replace(/^./, cat.name[0].toUpperCase())}
              </Link>
            </h6>
            <p className="text-secondary">{cat.subname}</p>
            <CategoryDropMenuComponent category_id={cat.category_id} />
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {categoryChild.length > 0 && (
        <div className="category-child my-4">{renderCategoryChildren()}</div>
      )}
      {categoryChild.length === 0 && (
        <ArticleComponent parentCategory={parentCategoryId} />
      )}
    </>
  );
};

export default CategoryChildrenComponent;
