import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { toggleLoader } from '../../redux/loader.slicer';
import { GetArticleByCategoryId } from '../../services/article.service';
import { TfiShoppingCartFull } from 'react-icons/tfi';
import { toggleModal } from '../../redux/modal.slicer';

const ArticleComponent = ({ parentCategory }) => {
  const [article, setArticle] = useState([]);
  const [parentCategoryId, setParentCategoryId] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationObject, setPaginationObject] = useState({
    offset: searchParams.has('page') ? searchParams.get('page') - 1 : 0,
    totalItems: 0,
    limit: searchParams.has('limit') ? searchParams.get('limit') : 5,
  });
  const limitNum = [5, 10];
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    setParentCategoryId(parentCategory);
    dispatch(toggleLoader(true));
    GetArticleByCategoryId(
      params.category_id,
      paginationObject.limit,
      paginationObject.offset
    )
      .then((res) => {
        setArticle(res.data.article);
        if (res.data.pagination) {
          setPaginationObject(res.data.pagination);
        }
      })
      .catch((err) => console.log(err.response.data))
      .finally(() => dispatch(toggleLoader(false)));
    setSearchParams({
      limit: paginationObject.limit,
      page: paginationObject.offset + 1,
    });
  }, [
    dispatch,
    params.category_id,
    paginationObject.offset,
    paginationObject.limit,
    setSearchParams,
    parentCategory,
  ]);

  const renderArticle = () => {
    return article.map((art) => {
      return (
        <tr key={art.article_key} id={art.article_key}>
          <td>{art.article_key}</td>
          <td className="fw-light">{art.name.toUpperCase()}</td>
          <td>{art.price}</td>
          <td>
            <Link
              className="btn btn-danger "
              to={`/category/${art.category_id}/article/${art.article_id}/key/${art.article_key}/limit/${paginationObject.limit}/offset/${paginationObject.offset}`}
              onClick={() => dispatch(toggleModal(true))}
            >
              <h4 className="text-light">
                <TfiShoppingCartFull />
              </h4>
            </Link>
          </td>
        </tr>
      );
    });
  };

  const onClickHandle = (num) => {
    setPaginationObject((prevState) => ({ ...prevState, offset: num }));
  };

  const onClickPrevious = () => {
    if (paginationObject.offset > 0) {
      setPaginationObject((prevState) => ({
        ...prevState,
        offset: prevState.offset - 1,
      }));
    }
  };

  const onClickNext = () => {
    let pagesNumber = Math.ceil(
      paginationObject.totalItems / paginationObject.limit
    );
    if (paginationObject.offset + 1 < pagesNumber) {
      setPaginationObject((prevState) => ({
        ...prevState,
        offset: prevState.offset + 1,
      }));
    }
  };

  const renderPaginationNumber = () => {
    let pagesNumber = Math.ceil(
      paginationObject.totalItems / paginationObject.limit
    );
    const numPagesDisplayed = 5;
    let pagesArray = [];
    let currentPage = paginationObject.offset + 1;
    if (pagesNumber <= numPagesDisplayed) {
      pagesArray = [...Array(pagesNumber).keys()].map((num) => num + 1);
    } else {
      let startPage = Math.max(
        1,
        currentPage - Math.floor(numPagesDisplayed / 2)
      );
      let endPage = Math.min(pagesNumber, startPage + numPagesDisplayed - 1);

      if (startPage === 1) {
        endPage = numPagesDisplayed;
      } else if (endPage === pagesNumber) {
        startPage = pagesNumber - numPagesDisplayed + 1;
      }
      pagesArray = [...Array(endPage - startPage + 1).keys()].map(
        (num) => startPage + num
      );
    }

    return (
      <>
        <li
          className="page-item"
          onClick={() => onClickHandle(0)}
          disabled={paginationObject.offset === 0}
        >
          <p className="page-link" aria-label="First">
            <span aria-hidden="true">««</span>
          </p>
        </li>
        {pagesArray.map((num) => (
          <li
            key={'page-num-' + num}
            className={
              paginationObject.offset === num - 1
                ? 'active page-item'
                : 'page-item'
            }
            aria-current="page"
            onClick={() => onClickHandle(num - 1)}
          >
            <p className="page-link">{num}</p>
          </li>
        ))}
        <li
          className="page-item"
          onClick={() => onClickHandle(pagesNumber - 1)}
          disabled={paginationObject.offset === pagesNumber - 1}
        >
          <p className="page-link" aria-label="Last">
            <span aria-hidden="true">»»</span>
          </p>
        </li>
      </>
    );
  };

  const renderLimitDropDown = () => {
    return limitNum.map((num, index) => {
      return (
        <li key={index}>
          <p
            className="dropdown-item"
            onClick={() =>
              setPaginationObject((prevState) => ({
                ...prevState,
                limit: num,
                offset: 0,
              }))
            }
          >
            {num}
          </p>
        </li>
      );
    });
  };

  return (
    <>
      <Link
        className="btn btn-outline-secondary article-back-btn"
        to={`/category/${parentCategoryId}`}
      >
        Nazad
      </Link>
      {article && article.length > 0 && (
        <div className="article my-4">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Šifra</th>
                <th scope="col">Naziv</th>
                <th scope="col">MP cena</th>
                <th scope="col">Poruči</th>
              </tr>
            </thead>
            <tbody>{renderArticle()}</tbody>
          </table>
          <div className="d-flex flex-wrap gap-3 justify-content-center mt-3 pagination-holder">
            <div className="dropdown">
              <button
                className="btn btn-sm btn-outline-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Limit {paginationObject.limit}
              </button>
              <ul className="dropdown-menu">{renderLimitDropDown()}</ul>
            </div>
            <nav aria-label="Page navigation">
              <ul className="pagination pagination-sm justify-content-center">
                <li className="page-item" onClick={() => onClickPrevious()}>
                  <p className="page-link" aria-label="Previous">
                    <span aria-hidden="true">«</span>
                  </p>
                </li>
                {renderPaginationNumber()}
                <li className="page-item" onClick={() => onClickNext()}>
                  <p className="page-link" aria-label="Next">
                    <span aria-hidden="true">»</span>
                  </p>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

export default ArticleComponent;