import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { GetArticleInPromotion } from '../../services/article.service';
import imageRoutes from '../../routes/imageRoutes';

const ArticleInPromotionComponent = () => {
  const [article, setArticle] = useState([]);
  const [paginationObject, setPaginationObject] = useState({offset:0, totalItems:0, limit:2})//Paginacija
  const limitNum = [2, 4, 6, 8, 10];

  // useEffect(() => {// Ovo povecava limit na 4 preko 1200 rezolucije ekrana (standardno je 2)
  //   if (window.screen.width > 1200) {
  //     setPaginationObject((prevState) => ({ ...prevState, limit: 4 }));
  //   }
  // }, []);

  useEffect(() => {
    GetArticleInPromotion(paginationObject.limit, paginationObject.offset) //Saljemo podatke bekendu o paginaciji
      .then((res) => {
        setArticle(res.data.article);
        if (res.data.pagination) {
          setPaginationObject(res.data.pagination);
        }
      })
      .catch((err) => console.log(err.response.data));
  }, [paginationObject.limit, paginationObject.offset]);

  const renderArticle = () => {
    return article.map((item) => {
      return (
        <div key={item.article_key} className="card" style={{ width: '15rem' }}>
          <img
            src={`${imageRoutes}${item.image_path}`}
            className="img-fluid "
            alt={item.name}
          />
          <div className="card-body">
            <h6 className="card-title">{item.name}</h6>
            <p className="card-text">{item.description}</p>
            <p className="card-text">Cena: {item.price} dinara</p>
            <Link
              to={`/category/${item.category_id}#${item.article_key}`}
              className="btn btn-outline-danger"
            >
              Pogledaj detaljnije
            </Link>
          </div>
        </div>
      );
    })
  }

  const onClickHandle = (num) => {
    setPaginationObject((prevState) => ({...prevState, offset: num}));
  }

  const onClickPrevious = () => {
    if (paginationObject.offset > 0) {
      setPaginationObject((prevState) => ({ ...prevState, offset: prevState.offset - 1 }));
    }
  };

  const onClickNext = () => {
    let pagesNumber = Math.ceil(paginationObject.totalItems / paginationObject.limit)
    if (paginationObject.offset + 1 < pagesNumber) {
      setPaginationObject((prevState) => ({ ...prevState, offset: prevState.offset + 1 }));
    }
  };

  const renderPaginationNumber = () => {
    let pagesNumber = Math.ceil(
      paginationObject.totalItems / paginationObject.limit
    );
    const numPagesDisplayed = 5;
    let pagesArray = [];
    let currentPage = paginationObject.offset + 1;
    if (pagesNumber <= numPagesDisplayed) {
      pagesArray = [...Array(pagesNumber).keys()].map((num) => num + 1);
    } else {
      let startPage = Math.max(
        1,
        currentPage - Math.floor(numPagesDisplayed / 2)
      );
      let endPage = Math.min(pagesNumber, startPage + numPagesDisplayed - 1);

      if (startPage === 1) {
        endPage = numPagesDisplayed;
      } else if (endPage === pagesNumber) {
        startPage = pagesNumber - numPagesDisplayed + 1;
      }
      pagesArray = [...Array(endPage - startPage + 1).keys()].map(
        (num) => startPage + num
      );
    }

    return (
      <>
        <li
          className="page-item"
          onClick={() => onClickHandle(0)}
          disabled={paginationObject.offset === 0}
        >
          <p className="page-link" aria-label="First">
            <span aria-hidden="true">««</span>
          </p>
        </li>
        {pagesArray.map((num) => (
          <li
            key={'page-num-' + num}
            className={
              paginationObject.offset === num - 1
                ? 'active page-item'
                : 'page-item'
            }
            aria-current="page"
            onClick={() => onClickHandle(num - 1)}
          >
            <p className="page-link">{num}</p>
          </li>
        ))}
        <li
          className="page-item"
          onClick={() => onClickHandle(pagesNumber - 1)}
          disabled={paginationObject.offset === pagesNumber - 1}
        >
          <p className="page-link" aria-label="Last">
            <span aria-hidden="true">»»</span>
          </p>
        </li>
      </>
    );
  };


  const renderLimitDropDown = () => {
    return limitNum.map((num, index) => {
      return <li key={index}><p className="dropdown-item" onClick={()=>setPaginationObject((prevState)=>({...prevState,limit:num,offset:0}))}>{num}</p></li>
    })
  }

  return (
    <>
      {article && article.length > 0 && (
        <div className="article-promotion mb-3">
          <h4 className="text-center text-secondary my-5">
            {article.length === 1
              ? 'Proizvod na akciji'
              : 'Proizvodi na akciji'}
          </h4>
          <div className="d-flex flex-wrap gap-3 justify-content-center align-items-center">
            {renderArticle()}
          </div>
          <div className="d-flex flex-wrap gap-3 justify-content-center mt-3 pagination-holder">
            <div className="dropdown">
              <button
                className="btn btn-sm btn-outline-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Limit {paginationObject.limit}
              </button>
              <ul className="dropdown-menu">{renderLimitDropDown()}</ul>
            </div>
            <nav aria-label="Page navigation">
              <ul className="pagination pagination-sm justify-content-center">
                <li className="page-item" onClick={() => onClickPrevious()}>
                  <p className="page-link" aria-label="Previous">
                    <span aria-hidden="true">«</span>
                  </p>
                </li>
                {renderPaginationNumber()}
                <li className="page-item" onClick={() => onClickNext()}>
                  <p className="page-link" aria-label="Next">
                    <span aria-hidden="true">»</span>
                  </p>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </>
  );
}

export default ArticleInPromotionComponent;