import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeUserPassword } from '../../services/user.service';
import { toast } from 'react-toastify';
import { toggleModal } from '../../redux/modal.slicer';
import ModalComponents from '../modal/Modal.Components';
import { removeUserFromLocalStorage } from '../../services/auth.service';

const UserChangePassword = () => {
  const userStore = useSelector((state) => state.userStore.user); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState(userStore);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userStore) {
      setIsLogged(true);
    } else {
      navigate('/');
    }
  }, [navigate, userStore]);

  const handleChangePassword = (e) => {
    let inputValue = e.target.value;
    let copyUser = { ...user, password: inputValue };
    setUser(copyUser);
  };

  const onChangeSubmit = (e) => {
    e.preventDefault();
    const regex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})';
    if (!user.password) {
       return toast.error('Lozinka je obavezna.'); 
    }
    if (!user.password.match(regex)) {
      return toast.error('Min 6 karaktera (1 vel slovo, 1 malo slovo, 1 spec. karakter )');
    }
    changeUserPassword({ user_id: user.user_id, password: user.password })
      .then((res) => {
        toast.success(res.data);
        dispatch(toggleModal(false));
        navigate('/login');
        removeUserFromLocalStorage(); // Ukljanjamo korisnika iz local storage
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  return (
    <ModalComponents>
      {isLogged && (
        <div className="col-12 col-sm-6 m-auto my-3 text-start">
          <form onSubmit={onChangeSubmit}>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Nova lozinka:
              </label>
              <input
                type="text"
                className="form-control"
                id="password"
                aria-describedby="password"
                placeholder='Unesi novu lozinku'
                onChange={(e) => handleChangePassword(e)}
                minLength={3}
                maxLength={50}
              />
            </div>
            <button type="submit" className="btn btn-danger form-control">
              Izmeni
            </button>
          </form>
        </div>
      )}
    </ModalComponents>
  );
};

export default UserChangePassword;
