import React, { useState } from 'react'
import { BsSearch } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const NavBarSearchComponent = () => {
    const [searchValue, setSearchValue] = useState('');
    const navigate = useNavigate();

    const onSearchSubmit = e => {
        e.preventDefault();
        if (searchValue) {
            navigate(`/search/${searchValue.replace(/\s+/g, '-').toLowerCase()}`);
            //navigate(`/search/${searchValue.replaceAll(' ', '-').toLowerCase()}`);
            setSearchValue('');
        }
    };

  return (
    <form onSubmit={(e) => onSearchSubmit(e)} className="d-flex" role="search">
      <input
        className="form-control me-2"
        type="search"
        placeholder="Pretraži"
        aria-label="Search"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <button className="btn btn-outline-danger" type="submit">
        <BsSearch />
      </button>
    </form>
  );
}

export default NavBarSearchComponent;