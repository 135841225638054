import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../services/auth.service';
import { saveUser } from '../../redux/user.slicer';
import { toast } from 'react-toastify';
import useLocalStorage from '../../hooks/useLocalStorage';
import { toggleModal } from '../../redux/modal.slicer';

const LoginSectionComponent = () => {
  const [signInObj, setSignInObj] = useState({
    email: '',
    password: '',
  });
  const [validationMsg, setValidationMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [loggedUser, setLoggedUser] = useLocalStorage('termosolar'); // Set Local Storage from ../../hooks/useLocalStorage
  const [token, setToken] = useLocalStorage('termosolar_token');

  const navigate = useNavigate();
  // hook from redux
  const dispatch = useDispatch();

  const handleSignInObj = (e) => {
    let inputValue = e.target.value;
    let inputName = e.target.name;
    let copySignInObj = { ...signInObj };
    copySignInObj[inputName] = inputValue;
    setSignInObj(copySignInObj);
  };

  const onLoginSubmit = (e) => {
    e.preventDefault();
    if (!signInObj.email & !signInObj.password) {
      return setValidationMsg('Email i lozinka su obavezni.');
    } else if (!signInObj.email) {
      return setValidationMsg('Email je obavezan.');
    } else if (!signInObj.password) {
      return setValidationMsg('Lozinka je obavezna.');
    }

    loginUser(signInObj)
      .then((res) => {
        if (res.status === 207) {
          //Korisnik ne postoji.
          toast.error(res.data);
          return setErrMsg(res.data);
        }
        if (res.status === 208) {
          //Pogresna lozinka.
          toast.error(res.data);
          return setErrMsg(res.data);
        }
        if (res.status === 221) {
          //Korisnički nalog je blokiran.
          toast.error(res.data);
          return setErrMsg(res.data);
        }
        if (res.status === 209) {
          //Korisnički nalog nije aktiviran.
          toast.error(res.data);
          return setErrMsg(res.data);
        }
        //console.log('Data--->', res.data); // ovde su svi podaci o korisniku sa tokenom u obj{message, user, token}
        //setUserToLocalStorage(res.data.user); // drugi nacin cuvanja u loc storage(iz auth.service.js)
        //setTokenToLocalStorage(res.data.token);

        setToken(res.data.token);
        setLoggedUser(res.data.user); // Set user to Local Storage from ../../hooks/useLocalStorage
        dispatch(saveUser(res.data.user));

        // if (res.data.user.role === 'admin') { // user, admin
        //   return navigate('/dashboard');
        // }
        toast.success(res.data.message);
        if (res.data.user.role === 'admin') {
          navigate('/dashboard');
        } else {
          navigate('/');
        }
      })
      .catch((err) => {
        //console.log('error...', err.message);
        if (err) {
          setErrMsg('Nešto nije u redu. Molimo vas pokušajte ponovo.');
          toast.error(errMsg);
        }
      })
      .finally(() => {
        //console.log('Anything')
      });
  };

  const showHidePassword = () => {
    const passwordType = document.querySelector('input[name="password"]');
    const btnShowHide = document.getElementById('button-addon2');

    if (passwordType.type === 'password') {
      passwordType.type = 'text';
      btnShowHide.innerHTML = 'sakrij';
    } else {
      passwordType.type = 'password';
      btnShowHide.innerHTML = 'prikaži';
    }
  };

  return (
    <>
      <div className="container col-sm-10 col-md-8 col-lg-6 col-xl-4">
        <h1 className="my-3">Prijavi se</h1>
        <div className="login-form">
          <form onSubmit={onLoginSubmit}>
            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                name="email"
                maxLength="60"
                //required
                onChange={(e) => handleSignInObj(e)}
                //onBlur={(e) => handleSignInObj(e)}
              />
              <label htmlFor="floatingInput">email</label>
            </div>
            <div className="input-group mb-3 pass">
              <button
                className="btn btn-outline-danger"
                type="button"
                id="button-addon2"
                onClick={showHidePassword}
              >
                prikaži
              </button>
              <input
                type="password"
                className="form-control"
                placeholder="lozinka"
                name="password"
                aria-label="Password"
                aria-describedby="button-addon2"
                onChange={(e) => handleSignInObj(e)}
                //onBlur={(e) => handleSignInObj(e)}
              />
            </div>
            <button
              type="submit"
              className="form-control mt-3 btn btn-outline-danger"
              //onClick={onLoginSubmit} // If not in form tag
            >
              Prijavi se
            </button>
          </form>

          {validationMsg ? (
            <p className="text-danger my-2 text-center">{validationMsg}</p>
          ) : null}

          {errMsg ? (
            <p className="text-danger my-2 text-center">{errMsg}</p>
          ) : null}
        </div>
        <div className="text-center mt-5">
          <Link
            onClick={() => dispatch(toggleModal(true))}
            className="text-danger"
            to="/forgot-password"
          >
            Zaboravljena lozinka?
          </Link>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3 mt-5">
          <p className="m-0">Nemate nalog?</p>
          <Link className="btn-register btn btn-outline-danger " to="/register">
            Kreirajte ga ovde
          </Link>
        </div>
      </div>
    </>
  );
};

export default LoginSectionComponent;
