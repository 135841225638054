import React from 'react'
import NavBarTopComponent from './components/NavBarTop.Component';
import NavBarMiddleComponent from './components/NavBarMiddle.Component';
import NavBarNavigationComponent from './components/NavBarNavigation.Component';
import '../../assets/scss/nav.scss'

const NavComponent = () => {
  return (
    <header className="header container-lg print-hide">
      <NavBarTopComponent />
      <div className="header-line"></div>
      <NavBarMiddleComponent />
      <NavBarNavigationComponent />
    </header>
  );
}

export default NavComponent;