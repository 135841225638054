import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cart: null,
};

const cartSlicer = createSlice({
  name: 'cart',
  initialState: initialState,
  reducers: {
    saveCart(state, data) {
      state.cart = data.payload;
    },
    removeCart(state) {
      state.cart = null;
    },
  },
});

export const { saveCart, removeCart } = cartSlicer.actions;
export default cartSlicer.reducer;
