import React, { useEffect, useState } from 'react';
import { GetMainCategory } from '../../services/article.service';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../../redux/loader.slicer';
import { Link } from 'react-router-dom';
import { saveCategory } from '../../redux/category.slicer';
import imageRoutes from '../../routes/imageRoutes';

const CategoryMainComponent = () => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState([]);

  useEffect(() => {
    dispatch(toggleLoader(true));
    GetMainCategory()
      .then((res) => {
        setCategory(res.data);
        dispatch(saveCategory(res.data));// cuvamo kategorije u redux
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(toggleLoader(false)));
  }, [dispatch]);

  const renderCategory = () => {
    return category.map((cat) => {
      //const img = require(`../../assets/img/${cat.image_path}.svg`);
      return (
        <div
          key={cat.category_id}
          className="category-box text-center col-6 col-sm-4 col-md-3"
        >
          <div className="category-img">
            <Link to={`/category/${cat.category_id}`}>
              <img
                src={`${imageRoutes}${cat.image_path}`}
                className="img-fluid "
                alt={cat.name}
              />
            </Link>
          </div>
          <div className="category-text">
            <h6>
              <Link to={`/category/${cat.category_id}`}>
                {cat.name.replace(/^./, cat.name[0].toUpperCase())}
              </Link>
            </h6>
            <div className="p-holder collapse d-lg-block">
              <p className="mt-3 mb-0 text-secondary">{cat.subname}</p>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="category">
      {category.length > 0 && renderCategory()}
    </div>
  );
};

export default CategoryMainComponent;
