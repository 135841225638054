import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toggleLoader } from '../redux/loader.slicer';
import { DeleteAdminArticle, GetAdminArticleById } from '../services/admin.service';
import ModalComponents from '../components/modal/Modal.Components';
import { toggleModal } from '../redux/modal.slicer';

const ArticleDeletePageComponent = () => {
    const [article, setArticle] = useState({});
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
      dispatch(toggleLoader(true));
      GetAdminArticleById(params.article_id)
        .then((res) => {
          setArticle(res.data[0]);
        })
        .catch((err) => console.log(err))
        .finally(() => dispatch(toggleLoader(false)));
    }, [dispatch, params.article_id]);

    const onChangeSubmit = (e) => {
      e.preventDefault();
      dispatch(toggleLoader(true));
      DeleteAdminArticle(params.article_id)
        .then((res) => {
          toast.success(res.data);
          dispatch(toggleModal(false));
          navigate(`/dashboard/article/category/${article.category_id}`);
        })
        .catch((err) => {
          toast.error(err.response.data);
        })
        .finally(() => dispatch(toggleLoader(false)));
    };

  return (
    <ModalComponents>
      <div className="col-12 col-sm-6 m-auto my-3 text-start">
        <form onSubmit={onChangeSubmit}>
          <div className="mb-3">
            <label htmlFor="city" className="form-label text-center">
              <p className="text-center">
                Da li zaista želite da obrišete proizvod?
              </p>
              <p className="text-center text-danger">{article.name}</p>
              <p className="text-center text-success">
                Šifra: {article.article_key}
              </p>
              <p className="text-center text-secondary">
                {article.price ? `Cena: ${article.price} dinara` : 'Bez cene'}
              </p>
            </label>
          </div>
          <button type="submit" className="btn btn-danger form-control">
            Obriši proizvod
          </button>
          <Link
            to={`/dashboard/article/category/${article.category_id}#${article.article_key}`}
            className="btn btn-outline-secondary form-control mt-3"
          >
            Nazad
          </Link>
        </form>
      </div>
    </ModalComponents>
  );
}

export default ArticleDeletePageComponent;