import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeUserNumber, changeUserToLocalStorage } from '../../services/user.service';
import { toast } from 'react-toastify';
import { toggleModal } from '../../redux/modal.slicer';
import ModalComponents from '../modal/Modal.Components';

const UserChangeNumber = () => {
  const userStore = useSelector((state) => state.userStore.user); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState(userStore);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userStore) {
      setIsLogged(true);
    } else {
      navigate('/');
    }
  }, [navigate, userStore]);

  const handleChangeName = (e) => {
    let inputValue = e.target.value;
    let copyUser = { ...user, number: inputValue };
    setUser(copyUser);
  };

  const onChangeSubmit = (e) => {
    e.preventDefault();
    if (!user.number) {
      return toast.error('Polje ne sme biti prazno.');
    }
    if (user.street.length < 1 || user.street.length > 50) {
      return toast.error('Polje mora imati od 1 do 50 karaktera');
    }
    changeUserNumber({ user_id: user.user_id, number: user.number })
      .then((res) => {
        toast.success(res.data);
        changeUserToLocalStorage(user);
        navigate(`/user/${user.user_id}`);
        dispatch(toggleModal(false));
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  return (
    <ModalComponents>
      {isLogged && (
        <div className="col-12 col-sm-6 m-auto my-3 text-start">
          <form onSubmit={onChangeSubmit}>
            <div className="mb-3">
              <label htmlFor="number" className="form-label">
                Promeni kućni broj:
              </label>
              <input
                type="text"
                className="form-control"
                id="number"
                aria-describedby="number"
                placeholder='128A'
                defaultValue={userStore.number}
                onChange={(e) => handleChangeName(e)}
                minLength={1}
                maxLength={50}
              />
            </div>
            <button type="submit" className="btn btn-danger form-control">
              Izmeni
            </button>
          </form>
        </div>
      )}
    </ModalComponents>
  );
};

export default UserChangeNumber;
