import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { registerUser } from '../../services/auth.service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useState } from 'react';

const RegisterSchema = Yup.object().shape({
  email: Yup.string().email('neispravan email').required('email je obavezan!'),
  first_name: Yup.string()
    .required('ime je obavezno!')
    .min(3, 'prekratko ime')
    .max(50, 'ime je predugačko'),
  last_name: Yup.string()
    .required('prezime je obavezno!')
    .min(3, 'prezime je kratko')
    .max(50, 'prezme je predugačko'),
  password: Yup.string()
    .required('lozinka je obavezna!')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
      'kreirajte jaku lozinku'
    ),
  cpassword: Yup.string()
    .required('obavezno ponovite lozinku!')
    .oneOf([Yup.ref('password'), null], 'lozinke moraju biti iste'),
});

const RegisterComponent = () => {
  const [showHidePassword, setShowHidePassword] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="container col-sm-10 col-md-8 col-lg-6 col-xl-4">
      <h1 className="my-3">Registracija</h1>
      <Formik
        initialValues={{
          email: '',
          first_name: '',
          last_name: '',
          password: '',
          cpassword: '',
        }}
        validationSchema={RegisterSchema}
        onSubmit={(values, action) => {
          // same shape as initial values
          //console.log(values);
          registerUser(values)
            .then((response) => {
              toast.success(
                'Uspešno ste se registrovali. Molimo Vas proverite svoju email poštu.'
              );
              //action.resetForm();// Reset form *******
              navigate('/login');
            })
            .catch((error) => {
              console.log(error);
              toast.error(error?.response.data);
            });
        }}
      >
        {({ errors, touched }) => (
          <Form autoComplete="off">
            <Field
              name="email"
              type="email"
              placeholder="email"
              className="form-control my-3"
            />
            <div className="text-danger">
              <ErrorMessage name="email" />
            </div>

            <Field
              name="first_name"
              type="text"
              placeholder="ime"
              className="form-control my-3"
            />
            <div className="text-danger">
              <ErrorMessage name="first_name" />
            </div>

            <Field
              name="last_name"
              type="text"
              placeholder="prezime"
              className="form-control my-3"
            />
            <div className="text-danger">
              <ErrorMessage name="last_name" />
            </div>

            <Field
              name="password"
              type={showHidePassword ? 'text' : 'password'}
              placeholder="lozinka"
              className="form-control my-3"
              //component={PasswordShowHide}
            />
            <div className="text-danger">
              <ErrorMessage name="password" />
            </div>

            <Field
              name="cpassword"
              type={showHidePassword ? 'text' : 'password'}
              placeholder="ponovite lozinku"
              className="form-control my-3"
              //component={PasswordShowHide}
            />
            <div className="text-danger">
              <ErrorMessage name="cpassword" />
            </div>

            <div
              onClick={() => setShowHidePassword(!showHidePassword)}
              className={
                showHidePassword
                  ? 'btn btn-secondary my-1 hide-pass'
                  : 'btn btn-secondary my-1 show-pass'
              }
            >
              {showHidePassword ? <FaEye /> : <FaEyeSlash />}{' '}
              {showHidePassword ? 'Sakrij lozinku' : 'Prikaži lozinku'}
            </div>

            <button
              className="btn btn-outline-danger form-control my-3"
              type="submit"
            >
              Registruj se
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegisterComponent;
