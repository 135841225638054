import React, { useEffect } from 'react';
import postExpressLogo from '../assets/img/logo-Post-Express.jpg';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

const PaymentDeliveryServicePageComponent = () => {
  const userCartStore = useSelector((state) => state.userCartStore.userCart); // Uzima sve potrebno za porudzbinu
  const navigate = useNavigate();

  useEffect(() => {
    if (!userCartStore) {
      return navigate('/');
    }
  }, [userCartStore, navigate]);

  return (
    <div className="container-lg payment-delivery-service">
      <h5 className="text-center mt-5 text-secondary">
        Plaćanje preko kurirske službe.
      </h5>
      <div className="post-express-logo d-flex justify-content-center mt-3">
        <img
          className="img-fluid"
          src={postExpressLogo}
          alt="Post Express Logo"
        />
      </div>
      <div className="text-holder text-center">
        <p className="mt-3">
          Dostavu naručenih proizvoda vršimo preko kurirske službe{' '}
          <Link
            to="https://www.posta.rs/cir/stanovnistvo/usluga.aspx?usluga=postanske-usluge/ekspres-usluge-srbija/post-ekspres-post-express"
            target="_blank"
            rel="noopener noreferrer"
          >
            "Post Express"
          </Link>
          .
        </p>
        <p>
          Po važećem cenovniku usluga{' '}
          <Link
            to="https://www.posta.rs/cir/alati/KalkulatorCena.aspx?vrPos=peSrb"
            target="_blank"
            rel="noopener noreferrer"
          >
            vidi cenovnik
          </Link>
          .
        </p>
        <h5 className="mb-3">{`Vaš ukupan iznos za plaćanje je ${userCartStore?.totalPrice},00 dinara + cena dostave.`}</h5>
        <p>
          Ako se slažete sa ovim uslovima kliknite ispod na "
          <span className="text-success fw-bold">Potvrdi kupovinu</span>".
        </p>
        <p>U suprotnom kliknite na dugme "Nazad".</p>
      </div>
      <div className="d-flex justify-content-center gap-3 flex-wrap mt-3">
        <Link to="/cart/payment" className="btn btn-outline-secondary">
          Nazad
        </Link>
        <Link
          to="/cart/purchase-confirmation?paymentSelected=delivery-service"
          className="btn btn-success"
        >
          Potvrdi kupovinu
        </Link>
      </div>
    </div>
  );
};

export default PaymentDeliveryServicePageComponent;
