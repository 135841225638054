import React, { useEffect, useState } from 'react';
import { toggleLoader } from '../redux/loader.slicer';
import { GetAllAdminUsers } from '../services/admin.service';
import { useDispatch } from 'react-redux';
import { GrUserSettings } from 'react-icons/gr';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

const UsersComponent = () => {
  //const userStore = useSelector((state) => state.userStore.user); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [paginationObject, setPaginationObject] = useState({
    offset: 0,
    totalItems: 0,
    limit: 50,
  });
  const limitNum = [10, 20, 30, 40, 50];
  const [users, setUsers] = useState([]);
  const [searchValue, setSearchValue] = useState({
    keyword: 'null',
    is_active: 'null',
    gender: 'null',
  });
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get('keyword') || searchParams.get('is_active') || searchParams.get('gender')) {
      dispatch(toggleLoader(true));
      GetAllAdminUsers(searchParams.get('keyword'),searchParams.get('is_active'),searchParams.get('gender'),paginationObject.limit,paginationObject.offset)
        .then((res) => {
          setUsers(res.data.users);
          setPaginationObject(res.data.pagination);
        })
        .catch((err) => console.log(err))
        .finally(() => dispatch(toggleLoader(false)));
    } else {
      dispatch(toggleLoader(true));
    GetAllAdminUsers(null,null,null,paginationObject.limit, paginationObject.offset)
      .then((res) => {
        setUsers(res.data.users);
        setPaginationObject(res.data.pagination);
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(toggleLoader(false)));
    }
  }, [dispatch, paginationObject.limit, paginationObject.offset, searchParams]);

  const userStatus = (isActive) => {
    let text = '';
    switch (isActive) {
      case 'active':
        text = 'text-success';
        break;
      case 'pending':
        text = 'text-warning';
        break;
      case 'inactive':
        text = 'text-danger';
        break;
      default:
    }
    return text;
  };

  const renderUsers = () => {
    return users.map((user) => {
      return (
        <tr id={user.user_id} key={user.user_id}>
          <td>{user.first_name}</td>
          <td>{user.last_name}</td>
          <td className={user.role === 'admin' ? 'text-primary' : null}>
            {user.email}
          </td>
          <td className={userStatus(user.is_active)}>{user.is_active}</td>
          <td>
            <Link to={`/dashboard/user/${user.user_id}`}>
              <h6 className="m-0">
                <GrUserSettings />
              </h6>
            </Link>
          </td>
        </tr>
      );
    });
  };

  const onClickHandle = (num) => {
    setPaginationObject((prevState) => ({ ...prevState, offset: num }));
  };

  const onClickPrevious = () => {
    if (paginationObject.offset > 0) {
      setPaginationObject((prevState) => ({
        ...prevState,
        offset: prevState.offset - 1,
      }));
    }
  };

  const onClickNext = () => {
    let pagesNumber = Math.ceil(
      paginationObject.totalItems / paginationObject.limit
    );
    if (paginationObject.offset + 1 < pagesNumber) {
      setPaginationObject((prevState) => ({
        ...prevState,
        offset: prevState.offset + 1,
      }));
    }
  };

  const renderPaginationNumber = () => {
    let pagesNumber = Math.ceil(
      paginationObject.totalItems / paginationObject.limit
    );
    const numPagesDisplayed = 5;
    let pagesArray = [];
    let currentPage = paginationObject.offset + 1;
    if (pagesNumber <= numPagesDisplayed) {
      pagesArray = [...Array(pagesNumber).keys()].map((num) => num + 1);
    } else {
      let startPage = Math.max(
        1,
        currentPage - Math.floor(numPagesDisplayed / 2)
      );
      let endPage = Math.min(pagesNumber, startPage + numPagesDisplayed - 1);

      if (startPage === 1) {
        endPage = numPagesDisplayed;
      } else if (endPage === pagesNumber) {
        startPage = pagesNumber - numPagesDisplayed + 1;
      }
      pagesArray = [...Array(endPage - startPage + 1).keys()].map(
        (num) => startPage + num
      );
    }

    return (
      <>
        <li
          className="page-item"
          onClick={() => onClickHandle(0)}
          disabled={paginationObject.offset === 0}
        >
          <p className="page-link" aria-label="First">
            <span aria-hidden="true">««</span>
          </p>
        </li>
        {pagesArray.map((num) => (
          <li
            key={'page-num-' + num}
            className={
              paginationObject.offset === num - 1
                ? 'active page-item'
                : 'page-item'
            }
            aria-current="page"
            onClick={() => onClickHandle(num - 1)}
          >
            <p className="page-link">{num}</p>
          </li>
        ))}
        <li
          className="page-item"
          onClick={() => onClickHandle(pagesNumber - 1)}
          disabled={paginationObject.offset === pagesNumber - 1}
        >
          <p className="page-link" aria-label="Last">
            <span aria-hidden="true">»»</span>
          </p>
        </li>
      </>
    );
  };


  const renderLimitDropDown = () => {
    return limitNum.map((num, index) => {
      return (
        <li key={index}>
          <p
            className="dropdown-item"
            onClick={() =>
              setPaginationObject((prevState) => ({
                ...prevState,
                limit: num,
                offset: 0,
              }))
            }
          >
            {num}
          </p>
        </li>
      );
    });
  };

  const onSearchSubmit = (e) => {
    e.preventDefault();
    if (searchValue.keyword === 'null' && searchValue.is_active === 'null' && searchValue.gender === 'null') return navigate(`/dashboard/users`);
    
    navigate(`/dashboard/users?keyword=${searchValue.keyword.replaceAll(' ', '-').toLowerCase()}&is_active=${searchValue.is_active}&gender=${searchValue.gender}&limit=${paginationObject.limit}&offset=${paginationObject.offset}`);
  };

  const onChangeValue = (e) => {
    let inputValue = e.target.value.trim();
    let inputName = e.target.name;
    if (inputValue === '') {
      inputValue = 'null';
    }
    let copySearchValue = { ...searchValue };
    copySearchValue[inputName] = inputValue;
    setSearchValue(copySearchValue);
  };

  return (
    <>
      <nav className="navbar bg-body-tertiary">
        <div className="container-fluid justify-content-center ">
          <form
            onSubmit={(e) => onSearchSubmit(e)}
            className="d-flex flex-column gap-2"
            role="search"
          >
            <div className="d-flex gap-3">
              <input
                className="form-control"
                type="search"
                name="keyword"
                placeholder="Pretraži"
                aria-label="Search"
                onChange={(e) => onChangeValue(e)}
              />
              <button className="btn btn-sm btn-outline-danger" type="submit">
                Pretraži
              </button>
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex gap-2 flex-wrap">
                <div className="form-check flex-fill">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="is_active"
                    id="is_active"
                    value="active"
                    onChange={(e) => onChangeValue(e)}
                  />
                  <label className="form-check-label" htmlFor="is_active">
                    aktivan
                  </label>
                </div>
                <div className="form-check flex-fill">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="is_active"
                    id="is_active2"
                    value="inactive"
                    onChange={(e) => onChangeValue(e)}
                  />
                  <label className="form-check-label" htmlFor="is_active2">
                    neaktivan
                  </label>
                </div>
                <div className="form-check flex-fill">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="is_active"
                    id="is_active3"
                    value="pending"
                    onChange={(e) => onChangeValue(e)}
                  />
                  <label className="form-check-label" htmlFor="is_active3">
                    čeka validaciju
                  </label>
                </div>
                <div className="form-check flex-fill">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="is_active"
                    id="is_active4"
                    value=""
                    onChange={(e) => onChangeValue(e)}
                  />
                  <label className="form-check-label" htmlFor="is_active4">
                    Poništi
                  </label>
                </div>
              </div>
              <div className="d-flex gap-3 flex-wrap">
                <div className="form-check flex-fill">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gender"
                    id="gender"
                    value="male"
                    onChange={(e) => onChangeValue(e)}
                  />
                  <label className="form-check-label" htmlFor="gender">
                    muško
                  </label>
                </div>
                <div className="form-check flex-fill">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gender"
                    id="gender2"
                    value="female"
                    onChange={(e) => onChangeValue(e)}
                  />
                  <label className="form-check-label" htmlFor="gender2">
                    žensko
                  </label>
                </div>
                <div className="form-check flex-fill">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gender"
                    id="gender3"
                    value="unspecified"
                    onChange={(e) => onChangeValue(e)}
                  />
                  <label className="form-check-label" htmlFor="gender3">
                    neodredjeno
                  </label>
                </div>
                <div className="form-check flex-fill">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gender"
                    id="gender4"
                    value=""
                    onChange={(e) => onChangeValue(e)}
                  />
                  <label className="form-check-label" htmlFor="gender4">
                    Poništi
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </nav>
      <div className="users d-flex table-responsive col-lg-8 m-auto mt-4">
        <table className="table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">Ime</th>
              <th scope="col">Prezime</th>
              <th scope="col">Email</th>
              <th scope="col">Nalog</th>
              <th scope="col">Detalji</th>
            </tr>
          </thead>
          <tbody>{renderUsers()}</tbody>
        </table>
      </div>
      <div className="d-flex flex-wrap gap-3 justify-content-center mt-3 pagination-holder">
        <div className="dropdown">
          <button
            className="btn btn-sm btn-outline-secondary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Limit {paginationObject.limit}
          </button>
          <ul className="dropdown-menu">{renderLimitDropDown()}</ul>
        </div>
        <nav aria-label="Page navigation">
          <ul className="pagination pagination-sm justify-content-center">
            <li className="page-item" onClick={() => onClickPrevious()}>
              <p className="page-link" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </p>
            </li>
            {renderPaginationNumber()}
            <li className="page-item" onClick={() => onClickNext()}>
              <p className="page-link" aria-label="Next">
                <span aria-hidden="true">»</span>
              </p>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default UsersComponent;