import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GetCategory } from '../services/article.service';
import { DeleteCategory } from '../services/admin.service';
import { toggleLoader } from '../redux/loader.slicer';
import ModalComponents from '../components/modal/Modal.Components';
import { toggleModal } from '../redux/modal.slicer';

const CategoryDeleteChildPageComponent = () => {
    const [category, setCategory] = useState({});
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
      dispatch(toggleLoader(true));
      GetCategory(params.category_id)
        .then((res) => {
          setCategory(res.data[0]);
        })
        .catch((err) => console.log(err))
        .finally(() => dispatch(toggleLoader(false)));
    }, [dispatch, params.category_id]);

    const onChangeSubmit = (e) => {
      e.preventDefault();
      DeleteCategory(params.category_id)
        .then((res) => {
          toast.success(res.data);
          dispatch(toggleModal(false));
          if (category.parent__category_id) {
            navigate(`/dashboard/category/${category.parent__category_id}`);
          } else {
            navigate(`/dashboard/category#${category.category_id}`);
          }
        })
        .catch((err) => {
          toast.error(err.response.data);
        });
    };

  return (
    <ModalComponents>
      <div className="col-12 col-sm-6 m-auto my-3 text-start">
        <form onSubmit={onChangeSubmit}>
          <div className="mb-3">
            <label className="form-label text-center">
              Da li zaista želite da obrišete Kategoriju{' '}
              <span className="text-danger">{category.name}</span>
            </label>
          </div>
          <button type="submit" className="btn btn-danger form-control">
            Obriši kategoriju
          </button>
        </form>
        <Link
          className="btn btn-outline-secondary form-control mt-3"
          to={`/dashboard/category/${category.parent__category_id}#${category.category_id}`}
        >
          Nazad
        </Link>
      </div>
    </ModalComponents>
  );
};

export default CategoryDeleteChildPageComponent;
