import { Link, NavLink, useNavigate } from 'react-router-dom';
import { PiUserLight } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { removeUserFromLocalStorage } from '../../../services/auth.service';
import { removeUser } from '../../../redux/user.slicer';
import { GetMainCategory } from '../../../services/article.service';
import NavBarSearchComponent from './NavBarSearch.Component';

const NavBarNavigationComponent = () => {
  const userStore = useSelector((state) => state.userStore.user); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [isLogged, setIsLogged] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [category, setCategory] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userStore) {
      setIsLogged(true);
      if (userStore.role === 'admin') {
        setIsAdmin(true);
      }
    }

    GetMainCategory()
      .then((res) => {
        setCategory(res.data);
      })
      .catch((err) => console.log(err))
  }, [userStore, dispatch]);

  const signOut = () => {
    removeUserFromLocalStorage(); // Ukljanjamo korisnika iz local storage
    dispatch(removeUser()); // Ukljanjamo korisnika iz redux-a
    setIsLogged(false);
    setIsAdmin(false);
    navigate('/login');
  }

  const renderCategory = () => {
    return category.map((cat) => {
      const uuid = () => String(Math.random().toString(36)).replace(/\./g,'');
      return (
        <li key={`${uuid()}${cat.category_id}`}>
          <Link className="dropdown-item" to={`/category/${cat.category_id}`}>
            {cat.name}
          </Link>
        </li>
      );
    })
  }

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          Termosolar
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="nav-link" aria-current="page" to="/">
                Početna
              </NavLink>
            </li>

            <li className="nav-item dropdown">
              <NavLink
                className="nav-link dropdown-toggle"
                to="/"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Proizvodi
              </NavLink>
              <ul className="dropdown-menu">{renderCategory()}</ul>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/about-us">
                O nama
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/gallery">
                Galerija
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact">
                Kontakt
              </NavLink>
            </li>
            <li className="nav-item dropdown">
              <NavLink
                className="nav-link dropdown-toggle"
                to="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <PiUserLight />
              </NavLink>
              <ul className="dropdown-menu">
                <li>
                  <p className="dropdown-item m-0">Korisnik</p>
                </li>
                {isLogged ? (
                  <p className="dropdown-item text-danger m-0">
                    {`${userStore.first_name} ${userStore.last_name}`}
                  </p>
                ) : null}
                {isLogged ? (
                  <Link
                    className="dropdown-item"
                    to={`/user/${userStore.user_id}`}
                  >
                    Moji podaci
                  </Link>
                ) : null}
                {isLogged ? (
                  <Link className="dropdown-item" to="/cart">
                    Moja korpa
                  </Link>
                ) : null}
                {isLogged ? (
                  <Link className="dropdown-item" to="/cart/user-orders">
                    Moje porudžbine
                  </Link>
                ) : null}
                {isAdmin ? (
                  <li>
                    <Link
                      className="dropdown-item text-primary"
                      to="/dashboard"
                    >
                      Admin panel
                    </Link>
                  </li>
                ) : null}
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  {isLogged ? (
                    <button className="dropdown-item" onClick={() => signOut()}>
                      Odjavi se
                    </button>
                  ) : (
                    <>
                      <Link className="dropdown-item" to="/login">
                        Prijavi se
                      </Link>
                      <Link className="dropdown-item" to="/register">
                        Registruj se
                      </Link>
                    </>
                  )}
                </li>
              </ul>
            </li>
          </ul>
          <NavBarSearchComponent />
        </div>
      </div>
    </nav>
  );
};

export default NavBarNavigationComponent;
