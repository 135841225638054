import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toggleLoader } from '../redux/loader.slicer';
import { deleteUserCart, findUserCart } from '../services/user.service';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { TfiShoppingCartFull } from 'react-icons/tfi';
import { saveCart } from '../redux/cart.slicer';

const CartPageComponent = () => {
  const userStore = useSelector((state) => state.userStore.user); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [cart, setCart] = useState([]);
  let priceArray = [];
  const [productRemoved, setProductRemoved] = useState(false);
  // article_id: 1;
  // article_key: 1123909;
  // cart_id: 16;
  // created_at: '08.11.2023 - 19:17:13';
  // name: 'KAMIN NA PELET VISION CALDO 15kW crvena';
  // price: '194601.00';
  // quantity: 1;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userStore) {
      return navigate('/login');
    }
    dispatch(toggleLoader(true));
    findUserCart({ user_id: userStore.user_id })
      .then((res) => {
        if (res.status === 243) {
          setCart([]);
          return toast.success(res.data);
        }
        setCart(res.data);
      })
      .catch((err) => toast.error(err.response.data))
      .finally(() => dispatch(toggleLoader(false)));
  }, [dispatch, navigate, userStore, productRemoved]);

  const deleteArticleFromCart = (cart_id) => {
    deleteUserCart(cart_id)
      .then((res) => {
        toast.success(res.data);
        findUserCart({ user_id: userStore.user_id })
          .then((res) => {
            if (res.status === 243) {
              return dispatch(saveCart(null));
            }
            dispatch(saveCart(res.data.length));
          })
          .catch((err) => console.log(err));
        setProductRemoved(!productRemoved);
      })
      .catch((err) => toast.error(err.response.data));
  };

  const total = (art) => {
    let newPrice = parseInt(art.price) * parseInt(art.quantity);
    priceArray.push(newPrice);
  };

  const renderTotalPrice = () => {
    let newPrice = null;
    for (let index = 0; index < priceArray.length; index++) {
      let element = priceArray[index];
      //newPrice = String(newPrice + element).replace(/\B(?=(\d{3})+(?!\d))/g);
      newPrice = newPrice + element;
    }
    return String(newPrice).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  const renderTable = () => {
    return cart.map((art, index) => {
      total(art);
      return (
        <tr key={art.article_key + '_key_' + index}>
          <th scope="row">{index + 1}</th>
          <td>{art.article_key}</td>
          <td>{art.name}</td>
          <td>{String(art.price).replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
          <td>{art.quantity}</td>
          <td>{String(art.price * art.quantity).replace(/\B(?=(\d{3})+(?!\d))/g, '.')},00</td>
          <td onClick={() => deleteArticleFromCart(art.cart_id)}>
            <h5 className="text-center m-0 text-danger">
              <RiDeleteBin5Line />
            </h5>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      {cart.length > 0 ? (
        <div className="container-lg">
          <p className="fs-1 text-center text-secondary mt-3">
            <TfiShoppingCartFull />
          </p>
          <h4 className="text-center text-secondary mb-3">{`${userStore.first_name} ${userStore.last_name}`}</h4>
          <div className="table-responsive-sm">
            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Šifra</th>
                  <th scope="col">Ime</th>
                  <th scope="col">Cena</th>
                  <th scope="col">Komada</th>
                  <th scope="col">Ukupno</th>
                  <th scope="col">Obriši</th>
                </tr>
              </thead>
              <tbody>{renderTable()}</tbody>
            </table>
          </div>
          <div className="text-end me-5">
            <h6 className="mt-5">
              <span className="text-secondary">Ukupno za uplatu:</span>{' '}
              {renderTotalPrice() === 'null' ? 0 : renderTotalPrice()},00 dinara
            </h6>
          </div>
          <div className="d-flex justify-content-center my-5">
            <Link to="/cart/user-data" className="btn btn-outline-success">
              Nastavi sa kupovinom
            </Link>
          </div>
        </div>
      ) : (
        <>
          <p className="fs-1 text-center text-danger">
            <TfiShoppingCartFull />
          </p>
          <h4 className="text-center text-danger mt-5">
            Vaša korpa je prazna.
          </h4>
        </>
      )}
    </>
  );
};

export default CartPageComponent;
