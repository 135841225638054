import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import paymentCard from '../assets/img/payment-card.jpg';

const PaymentCardPageComponent = () => {
  const userCartStore = useSelector((state) => state.userCartStore.userCart); // Uzima sve potrebno za porudzbinu
  const navigate = useNavigate();

  useEffect(() => {
    if (!userCartStore) {
      return navigate('/');
    }
  }, [userCartStore, navigate]);

  return (
    <div className="container-lg payment-card">
      <h5 className="text-center text-secondary mt-5 mb-1">
        Plaćanje bankovnim karticama.
      </h5>
      <div className="payment-logo">
        <img className="img-fluid" src={paymentCard} alt="Payment" />
      </div>
      <div className='text-danger text-center mb-5'>
        <h5>Izvinjavamo se ali za sada još nije moguće plaćati bankovnim karticama.</h5>
        <p className='text-secondary'>Odlučite se za jedan od predhodna dva ponudjena načina.</p>
      </div>
      <div className="d-flex justify-content-center gap-3 flex-wrap mt-3">
        <Link to="/cart/payment" className="btn btn-outline-secondary">
          Nazad
        </Link>
        <Link to="/cart/payment" className="btn btn-success">
          Potvrdi kupovinu
        </Link>
      </div>
    </div>
  );
};

export default PaymentCardPageComponent;
