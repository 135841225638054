import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  cart: {
    totalPrice: 0,
    articles: [],
    article_id: [],
  },
};

const userSlicer = createSlice({
  name: 'user',
  // exported reducer
  initialState: initialState,
  // define all actions
  reducers: {
    saveUser(state, data) {
      //console.log('data from userSlicer-->', data.payload);
      state.user = data.payload;
    },
    removeUser(state) {
      state.user = null; // umesto null mozemo da pisemo initialState (od gore)
    },
    // addToCart: (state, action) => {
    //   let article = action.payload;
    //   let foundIndex = null;
    //   let foundArticle = state.cart.articles.find((el, index) => {
    //     foundIndex = index;
    //     return el.article_id === article.article_id;
    //   });
    // },
  },
});

export const { saveUser, removeUser, addToCart } = userSlicer.actions;
export default userSlicer.reducer;
