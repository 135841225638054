import React from 'react'
import CategoryMainPageComponent from './CategoryMainPage.Component';
import ImageCarouselPageComponent from './ImageCarouselPage.Component';
import TokenExpComponent from '../components/tokenExp/TokenExp.Component';
import ArticleInPromotionPageComponent from './ArticleInPromotionPage.Component';

const HomePageComponent = () => {
  
  return (
    <div className="container-lg">
      <TokenExpComponent />
      <ImageCarouselPageComponent />
      <CategoryMainPageComponent />
      <ArticleInPromotionPageComponent />
    </div>
  );
}

export default HomePageComponent