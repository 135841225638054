import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../redux/modal.slicer';

const UserPageComponent = () => {
  const userStore = useSelector((state) => state.userStore.user); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const dispatch = useDispatch();

  const userGender = () => {
    let text = '';
    switch (userStore.gender) {
      case 'unspecified':
        text = '';
        break;
      case 'male':
        text = 'Muško';
        break;
      case 'female':
        text = 'Žensko';
        break;
      default:
        break;
    }
    return text;
  };

  const renderUserData = () => {
    return (
      <ul className="list-group list-group-flush">
        <li className="list-group-item d-flex align-items-center gap-1">
          <span className="text-secondary">Nalog kreiran:</span>
          {userStore.created_at}
        </li>
        <li className="list-group-item d-flex align-items-center gap-1">
          <span className="text-secondary">E-mail:</span>
          {userStore.email}
        </li>
        <li className="list-group-item d-flex align-items-center gap-1">
          <span className="text-secondary">Lozinka:</span>
          &#9734;&#9734;&#9734;&#9734;&#9734;&#9734;
          <Link
            className="btn btn-sm btn-outline-secondary ms-auto"
            onClick={() => dispatch(toggleModal(true))}
            to={`/user/${userStore.user_id}/change-password`}
          >
            Uredi
          </Link>
        </li>
        <li className="list-group-item d-flex align-items-center gap-1">
          <span className="text-secondary">Ime:</span>
          {userStore.first_name}
          <Link
            className="btn btn-sm btn-outline-secondary ms-auto"
            onClick={() => dispatch(toggleModal(true))}
            to={`/user/${userStore.user_id}/change-first-name`}
          >
            Uredi
          </Link>
        </li>
        <li className="list-group-item d-flex align-items-center gap-1">
          <span className="text-secondary">Prezime:</span>
          {userStore.last_name}
          <Link
            className="btn btn-sm btn-outline-secondary ms-auto"
            onClick={() => dispatch(toggleModal(true))}
            to={`/user/${userStore.user_id}/change-last-name`}
          >
            Uredi
          </Link>
        </li>
        <li className="list-group-item d-flex align-items-center gap-1">
          <span className="text-secondary">Pol:</span>
          {userGender()}
          <Link
            className="btn btn-sm btn-outline-secondary ms-auto"
            onClick={() => dispatch(toggleModal(true))}
            to={`/user/${userStore.user_id}/change-gender`}
          >
            Uredi
          </Link>
        </li>
        <li className="list-group-item d-flex align-items-center gap-1">
          <span className="text-secondary">Telefon:</span>
          {userStore.phone_number}
          <Link
            className="btn btn-sm btn-outline-secondary ms-auto"
            onClick={() => dispatch(toggleModal(true))}
            to={`/user/${userStore.user_id}/change-phone`}
          >
            Uredi
          </Link>
        </li>
        <li className="list-group-item d-flex align-items-center gap-1">
          <span className="text-secondary">Ulica:</span>
          {userStore.street}
          <Link
            className="btn btn-sm btn-outline-secondary ms-auto"
            onClick={() => dispatch(toggleModal(true))}
            to={`/user/${userStore.user_id}/change-street`}
          >
            Uredi
          </Link>
        </li>
        <li className="list-group-item d-flex align-items-center gap-1">
          <span className="text-secondary">Broj:</span>
          {userStore.number}
          <Link
            className="btn btn-sm btn-outline-secondary ms-auto"
            onClick={() => dispatch(toggleModal(true))}
            to={`/user/${userStore.user_id}/change-number`}
          >
            Uredi
          </Link>
        </li>
        <li className="list-group-item d-flex align-items-center gap-1">
          <span className="text-secondary">Grad:</span>
          {userStore.city}
          <Link
            className="btn btn-sm btn-outline-secondary ms-auto"
            onClick={() => dispatch(toggleModal(true))}
            to={`/user/${userStore.user_id}/change-city`}
          >
            Uredi
          </Link>
        </li>
        <li className="list-group-item d-flex align-items-center gap-1">
          <span className="text-secondary">Poštanski broj:</span>
          {userStore.postal_code}
          <Link
            className="btn btn-sm btn-outline-secondary ms-auto"
            onClick={() => dispatch(toggleModal(true))}
            to={`/user/${userStore.user_id}/change-postal`}
          >
            Uredi
          </Link>
        </li>
        <li className="list-group-item d-flex align-items-center gap-1">
          <span className="text-secondary">Nalog ažuriran:</span>
          {userStore.updated_at}
        </li>
        <li className="list-group-item d-flex align-items-center gap-1">
          <span className="text-secondary">Obriši nalog:</span>
          <p className="m-0">Trajno obriši (ukloni) nalog</p>
          <Link
            className="btn btn-sm btn-light ms-auto text-danger"
            onClick={() => dispatch(toggleModal(true))}
            to={`/user/${userStore.user_id}/delete-account`}
          >
            Obriši
          </Link>
        </li>
      </ul>
    );
  };

  return (
    <div className="container text-center ">
      <h1 className="text-danger fs-4 my-3">{`Nalog: ${userStore.first_name} ${userStore.last_name}`}</h1>
      <div className="col-12 col-lg-9 col-xxl-6 m-auto">
        {renderUserData()}
        <Outlet />
      </div>
    </div>
  );
};

export default UserPageComponent;
