import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeUserPhone, changeUserToLocalStorage } from '../../services/user.service';
import { toast } from 'react-toastify';
import { toggleModal } from '../../redux/modal.slicer';
import ModalComponents from '../modal/Modal.Components';

const UserChangePhone = () => {
  const userStore = useSelector((state) => state.userStore.user); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState(userStore);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userStore) {
      setIsLogged(true);
    } else {
      navigate('/');
    }
  }, [navigate, userStore]);

  const handleChangeName = (e) => {
    let inputValue = e.target.value;
    let copyUser = { ...user, phone_number: inputValue };
    setUser(copyUser);
  };

  const onChangeSubmit = (e) => {
    e.preventDefault();
    if (!user.phone_number) {
      return toast.error('Polje ne sme biti prazno.');
    }
    if (user.phone_number.length < 8 || user.phone_number.length > 24) {
      return toast.error('Telefon može imati od 8 do 24 karaktera.');
    }
    changeUserPhone({ user_id: user.user_id, phone_number: user.phone_number })
      .then((res) => {
        toast.success(res.data);
        changeUserToLocalStorage(user);
        dispatch(toggleModal(false));
        navigate(`/user/${user.user_id}`);
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  return (
    <ModalComponents>
      {isLogged && (
        <div className="col-12 col-sm-6 m-auto my-3 text-start">
          <form onSubmit={onChangeSubmit}>
            <div className="mb-3">
              <label htmlFor="phone_number" className="form-label">
                Promeni telefon:
              </label>
              <input
                type="text"
                className="form-control"
                id="phone_number"
                aria-describedby="phone_number"
                placeholder='063543789'
                defaultValue={userStore.phone_number}
                onChange={(e) => handleChangeName(e)}
                minLength={8}
                maxLength={24}
              />
            </div>
            <button type="submit" className="btn btn-danger form-control">
              Izmeni
            </button>
          </form>
        </div>
      )}
    </ModalComponents>
  );
};

export default UserChangePhone;
