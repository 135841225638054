import React from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { removeUserFromLocalStorage } from '../services/auth.service';
import { removeUser } from '../redux/user.slicer';
import { useDispatch } from 'react-redux';

const AdminNavbarComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const signOut = () => {
    removeUserFromLocalStorage(); // Ukljanjamo korisnika iz local storage
    dispatch(removeUser()); // Ukljanjamo korisnika iz redux-a
    navigate('/login');
  };

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary print-hide">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/dashboard">
          Administratorski Panel
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <NavLink className="nav-link" to="/dashboard/orders">
              Porudžbine
            </NavLink>
            <NavLink className="nav-link" to="/dashboard/orders-history">
              Završene
            </NavLink>
            <NavLink className="nav-link" to="/dashboard/users-email-history">
              Poruke
            </NavLink>
            <NavLink className="nav-link" to="/dashboard/users">
              Korisnici
            </NavLink>
            <NavLink className="nav-link" to="/dashboard/category">
              Kategorije
            </NavLink>
            <NavLink className="nav-link" to="/dashboard/article/all-article">
              Proizvodi
            </NavLink>
            <NavLink className="nav-link" to="/dashboard/gallery">
              Galerija
            </NavLink>
            <Link className="nav-link" to="/">
              Termosolar
            </Link>
            <Link className="nav-link" onClick={() => signOut()}>
              Odjava
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default AdminNavbarComponent;