import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  category: null,
};

const categorySlicer = createSlice({
    name: 'category',
    initialState: initialState,
    reducers: {
        saveCategory(state, data) {
            state.category = data.payload;
        },
        removeCategory(state) {
            state.category = null;
        }
    }
});

export const { saveCategory, removeCategory } = categorySlicer.actions;
export default categorySlicer.reducer;