import { configureStore } from '@reduxjs/toolkit';
import userSlicer from './user.slicer';
import loaderSlicer from './loader.slicer';
import modalSlicer from './modal.slicer';
import categorySlicer from './category.slicer';
import articleSlicer from './article.slicer';
import cartSlicer from './cart.slicer';
import userCartSlicer from './userCart.slicer';

const storeRedux = configureStore({
  reducer: {
    userStore: userSlicer,
    loaderStore: loaderSlicer,
    modalStore: modalSlicer,
    categoryStore: categorySlicer,
    articleStore: articleSlicer,
    cartStore: cartSlicer,
    userCartStore: userCartSlicer
  },
});

export default storeRedux;
