import React, { useEffect, useState } from 'react'
import { UploadFile } from '../services/admin.service';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalComponents from '../components/modal/Modal.Components';
import { useDispatch } from 'react-redux';
import { toggleModal } from '../redux/modal.slicer';
import { toggleLoader } from '../redux/loader.slicer';
import { GetCategory } from '../services/article.service';
import imageRoutes from '../routes/imageRoutes';


const UploadFilePageComponent = () => {
    const [file, setFile] = useState();
    const [category, setCategory] = useState({});
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
      dispatch(toggleLoader(true));
      GetCategory(params.category_id)
        .then((res) => {
          setCategory(res.data[0]);
        })
        .catch((err) => console.log(err))
        .finally(() => dispatch(toggleLoader(false)));
    }, [params.category_id, dispatch]);

    const handleFile = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = (e) => {
      e.preventDefault();
      dispatch(toggleLoader(true));
      const formData = new FormData();
      formData.append('image', file);
      formData.append('category_id', params.category_id);
      UploadFile(formData)
        .then((res) => {
          toast.success(res.data);
          dispatch(toggleModal(false));
          if (category.parent__category_id) {
            navigate(
              `/dashboard/category/${category.parent__category_id}#${category.category_id}`
            );
          } else {
            navigate(`/dashboard/category#${category.category_id}`);
          }
        })
        .catch((err) => {
          //toast.error(err.response.data)
          toast.error('Greška kod dodavanja fajla.')
        })
        .finally(() => dispatch(toggleLoader(false)));
    }

  return (
    <ModalComponents>
      <div className="col-12 col-sm-6 m-auto my-3 text-start">
        <h5 className="text-center mb-3">{category.name}</h5>
        <div className="w-50 mb-3">
          <p className="text-danger">Fotografija:</p>
          <img
            src={`${imageRoutes}${category.image_path}`}
            className="img-fluid img-thumbnail rounded mx-auto d-block"
            alt="{cat.name}"
          />
        </div>
        <form onSubmit={handleUpload} encType="multipart/form-data">
          <div className="mb-3">
            <label htmlFor="imageRoute" className="form-label">
              <p className="text-danger">Putanja do Fotografije:</p>
            </label>
            <input
              type="text"
              className="form-control"
              id="imageRoute"
              aria-describedby="imageRoute"
              defaultValue={imageRoutes}
              disabled
            />
          </div>
          <div className="mb-3">
            <label htmlFor="image" className="form-label">
              <p className="text-danger">Naziv fotografije:</p>
            </label>
            <input
              type="text"
              className="form-control"
              id="image"
              aria-describedby="image"
              defaultValue={category.image_path}
              disabled
            />
          </div>
          <Link
            className="btn btn-sm btn-outline-secondary mb-3"
            to="https://reduceimagesize.net/compress-image-to-10kb"
            target="_blank"
          >
            Promenite veličinu fotografije
          </Link>
          <div className="mb-3">
            <label htmlFor="image_path" className="form-label">
              <p className="text-danger">Dodaj/izmeni fotografiju:</p>
            </label>
            <input
              type="file"
              multiple={false}
              className="form-control"
              id="image_path"
              aria-describedby="image_path"
              name="image_path"
              accept="image/*"
              onChange={handleFile}
            />
          </div>
          <button type="submit" className="btn btn-danger form-control">
            Sačuvaj izmene
          </button>
          <Link
            className="btn btn-outline-secondary form-control mt-3"
            to={
              category.parent__category_id
                ? `/dashboard/category/${category.parent__category_id}#${category.category_id}`
                : `/dashboard/category#${category.category_id}`
            }
          >
            Nazad
          </Link>
        </form>
      </div>
    </ModalComponents>
  );
}

export default UploadFilePageComponent;