import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalComponents from '../components/modal/Modal.Components';
import { GetArticle } from '../services/article.service';
import { findUserCart, setUserCart } from '../services/user.service';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLoader } from '../redux/loader.slicer';
import { toggleModal } from '../redux/modal.slicer';
import { saveCart } from '../redux/cart.slicer';

const AddToCartPageComponent = () => {
  const userStore = useSelector((state) => state.userStore.user); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [article, setArticle] = useState({});
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userStore) {
      navigate(
        `/category/${params.category_id}?limit=${parseInt(params.limit)}&page=${
          parseInt(params.offset) + 1
        }`
      );
      toast.error('Morate biti prijavljeni.');
    }
    dispatch(toggleLoader(true));
    GetArticle(params.article_id)
      .then((res) => {
        setArticle(res.data[0]);
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(toggleLoader(false)));
  }, [params, dispatch, navigate, userStore]);

  const onChangeSubmit = (e) => {
    e.preventDefault();
    dispatch(toggleLoader(true));
    setUserCart({article_id:article.article_id, quantity:article.quantity, user_id:userStore.user_id})
      .then((res) => {
        toast.success(res.data);
        findUserCart({ user_id: userStore.user_id })
          .then((res) => {
            if (res.status === 243) {
              return dispatch(saveCart(null));
            }
            dispatch(saveCart(res.data.length));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => toast.error(err.response.data))
      .finally(() => {
        dispatch(toggleLoader(false));
        dispatch(toggleModal(false));
        navigate(
          `/category/${article.category_id}?limit=${parseInt(
            params.limit
          )}&page=${parseInt(params.offset) + 1}`
        );
      });
  };

  const handleChangeTotalPrice = (e) => {
    const minValue = parseInt(e.target.min);
    const maxValue = parseInt(e.target.max);
    let inputValue = parseInt(e.target.value);
    //let inputName = e.target.name;
    if (inputValue > maxValue) inputValue = maxValue;
    if (inputValue < minValue) inputValue = minValue;
    let total_price = inputValue * article.price;
    setArticle((prevState) => ({ ...prevState, totalPrice: total_price, quantity: inputValue}));
  };

  return (
    <ModalComponents>
      <div className="col-12 col-sm-6 m-auto my-3 text-start">
        <h5 className="text-secondary">Proizvod:</h5>
        <h6 className="text-danger">{article.name}</h6>
        <form onSubmit={onChangeSubmit}>
          <div className="mb-3">
            <p className="text-secondary mb-0">Šifra:</p>
            <p className="text-danger mb-0">{article.article_key}</p>
          </div>
          <div className="mb-3">
            <p className="text-secondary mb-0">Cena po komadu:</p>
            <p className="text-danger mb-0">{`${String(article.price).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              '.'
            )} dinara`}</p>
          </div>
          <div className="mb-3">
            <label htmlFor="quantity" className="form-label">
              <span className="text-secondary">Komada:</span>
            </label>
            <input
              type="number"
              className="form-control"
              id="quantity"
              name="quantity"
              aria-describedby="quantity"
              defaultValue="1"
              min={1}
              max={1000}
              onChange={(e) => handleChangeTotalPrice(e)}
            />
          </div>
          <div className="mb-3">
            <p className="text-secondary mb-0">Ukupno za uplatu:</p>
            <h3 className="text-danger">
              {article.totalPrice
                ? `${String(article.totalPrice).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    '.'
                  )}.00 dinara`
                : `${String(article.price).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    '.'
                  )} dinara`}
            </h3>
          </div>
          <button type="submit" className="btn btn-danger form-control mt-3">
            Dodaj u korpu
          </button>
          <Link
            className="btn btn-secondary form-control mt-3"
            to={`/category/${params.category_id}?limit=${parseInt(
              params.limit
            )}&page=${parseInt(params.offset) + 1}`}
          >
            Nazad
          </Link>
        </form>
      </div>
    </ModalComponents>
  );
};

export default AddToCartPageComponent;
