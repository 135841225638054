import React, { useEffect, useState } from 'react'
import { GetAllPhotosFromGallery } from '../services/article.service';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../redux/loader.slicer';
import imageRoutes from '../routes/imageRoutes';

const GalleryPageComponent = () => {
  const [images, setImages] = useState([]);
  const [paginationObject, setPaginationObject] = useState({offset:0, totalItems:0, limit:2})//Paginacija
  const limitNum = [1, 2, 4, 6, 8, 10];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLoader(true));
    GetAllPhotosFromGallery(paginationObject.limit, paginationObject.offset)
      .then((res) => {
        setImages(res.data.images);
        setPaginationObject(res.data.pagination);
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(toggleLoader(false)));
  }, [dispatch, paginationObject.limit, paginationObject.offset]);

  const onClickHandle = (num) => {
    setPaginationObject((prevState) => ({...prevState, offset: num}));
  }

  const onClickPrevious = () => {
    if (paginationObject.offset > 0) {
      setPaginationObject((prevState) => ({ ...prevState, offset: prevState.offset - 1 }));
    }
  };

  const onClickNext = () => {
    let pagesNumber = Math.ceil(paginationObject.totalItems / paginationObject.limit)
    if (paginationObject.offset + 1 < pagesNumber) {
      setPaginationObject((prevState) => ({ ...prevState, offset: prevState.offset + 1 }));
    }
  };

  const renderPaginationNumber = () => {
    let pagesNumber = Math.ceil(
      paginationObject.totalItems / paginationObject.limit
    );
    const numPagesDisplayed = 5;
    let pagesArray = [];
    let currentPage = paginationObject.offset + 1;
    if (pagesNumber <= numPagesDisplayed) {
      pagesArray = [...Array(pagesNumber).keys()].map((num) => num + 1);
    } else {
      let startPage = Math.max(
        1,
        currentPage - Math.floor(numPagesDisplayed / 2)
      );
      let endPage = Math.min(pagesNumber, startPage + numPagesDisplayed - 1);

      if (startPage === 1) {
        endPage = numPagesDisplayed;
      } else if (endPage === pagesNumber) {
        startPage = pagesNumber - numPagesDisplayed + 1;
      }
      pagesArray = [...Array(endPage - startPage + 1).keys()].map(
        (num) => startPage + num
      );
    }

    return (
      <>
        <li
          className="page-item"
          onClick={() => onClickHandle(0)}
          disabled={paginationObject.offset === 0}
        >
          <p className="page-link" aria-label="First">
            <span aria-hidden="true">««</span>
          </p>
        </li>
        {pagesArray.map((num) => (
          <li
            key={'page-num-' + num}
            className={
              paginationObject.offset === num - 1
                ? 'active page-item'
                : 'page-item'
            }
            aria-current="page"
            onClick={() => onClickHandle(num - 1)}
          >
            <p className="page-link">{num}</p>
          </li>
        ))}
        <li
          className="page-item"
          onClick={() => onClickHandle(pagesNumber - 1)}
          disabled={paginationObject.offset === pagesNumber - 1}
        >
          <p className="page-link" aria-label="Last">
            <span aria-hidden="true">»»</span>
          </p>
        </li>
      </>
    );
  };


  const renderLimitDropDown = () => {
    return limitNum.map((num, index) => {
      return <li key={index}><p className="dropdown-item" onClick={()=>setPaginationObject((prevState)=>({...prevState,limit:num,offset:0}))}>{num}</p></li>
    })
  }

  const renderPictures = () => {
    return images.map((img, index) => {
      return (
        <div key={`${index}-${img.photo_id}`} className="gallery-box m-auto">
          <img
            src={`${imageRoutes}${img.image_path}`}
            alt={`Img-${img.photo_id}`}
            className="img-fluid img-thumbnail gallery-box-img"
          />
          <p className="text-center text-danger my-1">{img.description}</p>
        </div>
      );
    })
  }

  return (
    <div className="container-lg gallery">
      <h1 className="text-center text-secondary fs-3 my-3">Galerija</h1>
      <div>{renderPictures()}</div>
      <div className="d-flex flex-wrap gap-3 justify-content-center mt-3 pagination-holder">
        <div className="dropdown">
          <button
            className="btn btn-sm btn-outline-secondary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Limit {paginationObject.limit}
          </button>
          <ul className="dropdown-menu">{renderLimitDropDown()}</ul>
        </div>
        <nav aria-label="Page navigation">
          <ul className="pagination pagination-sm justify-content-center">
            <li className="page-item" onClick={() => onClickPrevious()}>
              <p className="page-link" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </p>
            </li>
            {renderPaginationNumber()}
            <li className="page-item" onClick={() => onClickNext()}>
              <p className="page-link" aria-label="Next">
                <span aria-hidden="true">»</span>
              </p>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default GalleryPageComponent;