import React from 'react';
import { Outlet } from 'react-router-dom';
import Logo from '../assets/img/logo.jpg';
import FooterComponent from '../components/footer/Footer.Component';
import AdminNavbarComponent from './AdminNavbar.Component';
import { ToastContainer } from 'react-toastify';
import LoaderComponent from '../components/loader/Loader.Component';

const AdminPageComponent = () => {
  return (
    <div className="container admin">
      <div className="admin-img">
        <img className="img-fluid" src={Logo} alt="Logo" />
      </div>
      <ToastContainer />
      <LoaderComponent />
      <AdminNavbarComponent />
      <Outlet />
      <FooterComponent />
    </div>
  );
};

export default AdminPageComponent;
