import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeUserGender,
  changeUserToLocalStorage,
} from '../../services/user.service';
import { toast } from 'react-toastify';
import { toggleModal } from '../../redux/modal.slicer';
import ModalComponents from '../modal/Modal.Components';

const UserChangeGender = () => {
  const userStore = useSelector((state) => state.userStore.user); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState(userStore);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userStore) {
      setIsLogged(true);
    } else {
      navigate('/');
    }
  }, [navigate, userStore]);

  const handleChangeGender = (e) => {
    let inputValue = e.target.value;
    let copyUser = { ...user, gender: inputValue };
    setUser(copyUser);
  };

  const onChangeSubmit = (e) => {
    e.preventDefault();
    if (!user.gender) {
      return toast.error('Polje ne sme biti prazno.');
    }
    changeUserGender({ user_id: user.user_id, gender: user.gender })
      .then((res) => {
        toast.success(res.data);
        changeUserToLocalStorage(user);
        navigate(`/user/${user.user_id}`);
        dispatch(toggleModal(false));
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  return (
    <ModalComponents>
      {isLogged && (
        <div className="col-12 col-sm-6 m-auto my-3 text-start">
          <form onSubmit={onChangeSubmit}>
            <div className="mb-3">
              <label className="me-3" htmlFor="gender">
                Izaberi pol:
              </label>
              <select
                className="form-control mt-2"
                name="gender"
                id="gender"
                defaultValue={userStore.gender}
                onChange={(e) => handleChangeGender(e)}
              >
                <option value="unspecified">neodredjeno</option>
                <option value="male">muško</option>
                <option value="female">žensko</option>
              </select>
            </div>
            <button type="submit" className="btn btn-danger form-control">
              Izmeni
            </button>
          </form>
        </div>
      )}
    </ModalComponents>
  );
};

export default UserChangeGender;
