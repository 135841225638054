import { Outlet } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { saveUser } from './redux/user.slicer';
import useLocalStorage from './hooks/useLocalStorage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderComponent from "./components/loader/Loader.Component";
import NavComponent from './components/nav/Nav.Component';
import NoticeCookieComponent from "./components/NoticeCookie/NoticeCookie.Component";
import FooterComponent from './components/footer/Footer.Component';

// Base URL
// axios.defaults.baseURL = 'http://localhost:5000/api';
//On host - Contra Team
axios.defaults.baseURL = 'https://shop-be.djurdjevic.eu.org/api';

axios.interceptors.request.use((config) => { // Ova metoda presrece i request i response
  // TOKEN ************************************
  //console.log('Axios Interceptor--->', config);
  if (localStorage.hasOwnProperty('termosolar_token')) {
    config.headers.Authorization = JSON.parse(localStorage.termosolar_token);
  }
  return config;
});

function App() {
  const dispatch = useDispatch();
  const [isFinish, setIsFinish] = useState(false);
  let userLocalStorageStr = useLocalStorage('termosolar')[0];

  useEffect(() => {
    if (userLocalStorageStr) {
      dispatch(saveUser(userLocalStorageStr)); // svaki put kad se stranica rlda iz loc storage se ucita u redux
    }
    setIsFinish(true);
  }, [userLocalStorageStr, dispatch]);

  return isFinish && (
    <>
      <ToastContainer />
      <LoaderComponent />
      <NavComponent />
      <Outlet />
      <NoticeCookieComponent />
      <FooterComponent />
    </>
  );
}

export default App;
