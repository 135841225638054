import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { getUserByEmail } from '../../services/user.service';
import { sendResetPasswordMail } from '../../services/mail.service';
import { Link, useNavigate } from 'react-router-dom';

const ForgotPasswordComponent = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleEmail = (e) => {
    let inputEmail = e.target.value;
    let copyEmail = {...email};
    copyEmail = inputEmail;
    setEmail(copyEmail);
  };

  const onLoginSubmit = (e) => {
    e.preventDefault();
    const regex = /^\S+@\S+\.\S+$/;
    if (!email) {
      return toast.error('Polje ne sme biti prazno.');
    }
    if (!email.match(regex)) {
      return toast.error('Unesite isravnu e-mail adresu.');
    }
    getUserByEmail({email})
      .then((res) => {
        if (!res.data) {
          return toast.error('Ne postoji korisnik sa ovom e-mail adresom.');
        }
        let userData = {};
        userData = res.data; //user_id , email
        sendResetPasswordMail(userData)
          .then((params) => {
            toast.success('Zahtev je uspešno poslat.');
          })
          .catch((error) => {
            console.log(error);
            toast.error('Zahtev nije poslat.');
          })
          .finally(() => {
            setTimeout(() => {
              navigate('/login');
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data);
      })
  };

  return (
    <div className="container">
      <div className="col-12 col-sm-8 col-md-6 col-xl-4 m-auto mt-5">
        <h4 className="text-center">Zatražite promenu vaše lozinke</h4>
        <form onSubmit={onLoginSubmit}>
          <div className="my-3">
            <label htmlFor="user-email" className="form-label">
              Unesite vašu e-mail adresu
            </label>
            <input
              type="email"
              name="email"
              className="form-control"
              id="user-email"
              aria-describedby="user-email"
              onChange={(e) => handleEmail(e)}
            />
            <div id="emailHelp" className="form-text">
              Nakon ovoga proverite vaše e-mail poruke.
            </div>
          </div>
          <button type="submit" className="btn btn-danger">
            Pošalji
          </button>
        </form>
        <Link to="/login" className="btn btn-light mt-3">
          Nazad
        </Link>
      </div>
    </div>
  );
}

export default ForgotPasswordComponent;