import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GetCategory, GetChildrenCategory } from '../services/article.service';
import { useState } from 'react';
import CategoryChildrenComponent from '../components/categories/CategoryChildren.Component';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../redux/loader.slicer';
import imageRoutes from '../routes/imageRoutes';

const CategoryMainChildPageComponent = () => {
  const [category, setCategory] = useState({});
  const [categoryChild, setCategoryChild] = useState({});
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(toggleLoader(true));
    GetCategory(params.category_id)
      .then((res) => {
        setCategory(res.data[0]);
        GetChildrenCategory(params.category_id)
          .then((res) => {
            setCategoryChild(res.data);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(toggleLoader(false)));
  }, [params.category_id, dispatch]);

  // category.name.replace(/^./, category.name[0].toUpperCase()) // Prvo slovo menja u veliko
  return (
    <div className="container-lg position-relative">
      <Link
        className="btn btn-outline-secondary article-back-btn"
        to={category.parent__category_id?`/category/${category.parent__category_id}`:'/'}
      >
        Nazad
      </Link>
      {category.name && (
        <h1 className="text-center fs-5 text-secondary my-4">
          {category.name.replace(/^./, category.name[0].toUpperCase())}
        </h1>
      )}
      {categoryChild.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="category-child-img ">
            <img
              src={`${imageRoutes}${category.image_path}`}
              className="img-fluid "
              alt={category.name}
            />
          </div>
        </div>
      ) : null}
      {category.subname && (
        <h2 className="text-center fs-4 m-3 text-danger ">
          {category.subname}
        </h2>
      )}
      {category.description && (
        <p className="text-secondary">{category.description}</p>
      )}
      <CategoryChildrenComponent />
    </div>
  );
};

export default CategoryMainChildPageComponent;
