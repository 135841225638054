import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toggleLoader } from '../redux/loader.slicer';
import { GetAdminUserByEmail, GetUserEmail } from '../services/admin.service';

const AdminUserEmailDetailsPageComponent = () => {
  const [email, setEmail] = useState({});
const [user, setUser] = useState();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLoader(true));
    GetUserEmail(params.emails_id)
      .then((res) => {
        setEmail(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(toggleLoader(false)));
  }, [params.emails_id, dispatch]);

  useEffect(() => {
    GetAdminUserByEmail(email.email)
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => console.log(err));
  }, [email]);

  return (
    <div className="container-lg">
      {user ? (
        <>
          <h1 className="fs-5 text-center my-3">Od registrovanog korisnika</h1>
          <div className="text-center">
            <h2 className="fs-3 text-secondary m-0">{`${user.first_name} ${user.last_name}`}</h2>
            <p className="text-secondary fs-5 m-0">{user.user_id}</p>
            <p className="text-danger fs-5 m-0">{user.email}</p>
            <Link
              className="btn btn-sm btn-outline-secondary my-3"
              to={`/dashboard/user/${user.user_id}`}
            >
              Pogledaj detalje korisnika
            </Link>
          </div>
        </>
      ) : (
        <div className="text-center">
          <h1 className="fs-5 text-center my-3">
            Od neregistrovanog korisnika
          </h1>
          <p className="text-danger fs-5 m-0">{email?.email}</p>
        </div>
      )}
      <div className="text-center">
        <p className="text-secondary fs-5 m-0">{email?.created_at}</p>
        <p className="fs-5 mt-3 mb-0">Naslov:</p>
        <p className="text-primary fs-5 m-0">{email?.subject}</p>
        <p className="fs-5 mt-3 mb-0">Tekst poruke:</p>
        <p className="text-primary fs-5 m-0">{email?.message}</p>
        <div
          className="d-flex flex-column align-items-center gap-3 m-auto mt-5"
          style={{ width: '300px' }}
        >
          <Link
            className="btn btn-outline-success"
            to={`mailto:${email?.email}`}
          >
            Ogdovori korisniku
          </Link>
          <Link
            className="btn btn-outline-secondary"
            to={`/dashboard/users-email-history#${email?.emails_id}`}
          >
            Nazad
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminUserEmailDetailsPageComponent;
