import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

const PaymentBankPageComponent = () => {
  const userCartStore = useSelector((state) => state.userCartStore.userCart); // Uzima sve potrebno za porudzbinu
  const navigate = useNavigate();

  useEffect(() => {
    if (!userCartStore) {
      return navigate('/');
    }
  }, [userCartStore, navigate]);

  return (
    <div className="container-lg bank">
      <div className="bankslip__wrapper">
        <form id="bankslip" className="bankslip">
          <div className="bankslip__inner">
            <div className="bankslip-title">Налог за уплату</div>

            <div className="bankslip__form-item--payer">
              <label>уплатилац</label>
              <textarea
                name=""
                className="bankslip__field-size--xxxl"
                defaultValue={`${userCartStore?.user.first_name} ${userCartStore?.user.last_name}, ${userCartStore?.user.street} ${userCartStore?.user.number}, ${userCartStore?.user.postal_code} ${userCartStore?.user.city}`}
              />
            </div>

            <div className="bankslip__form-item--purpose">
              <label>сврха уплате</label>
              <textarea
                name=""
                className="bankslip__field-size--xxxl"
                defaultValue={`Uplata po predračunu broj: ${userCartStore?.user.user_id}`}
                readOnly
              />
            </div>

            <div className="bankslip__form-item--recipient">
              <label>прималац</label>
              <textarea
                name=""
                className="bankslip__field-size--xxxl"
                defaultValue={`Djurdjević Termosolar, Svetosavska 66, 23300 Kikinda`}
                readOnly
              />
            </div>

            <div className="bankslip__form-item--code">
              <label>шифра плаћања</label>
              <input
                type="text"
                name=""
                className="bankslip__field-size--xxs bankslip-border-double"
              />
            </div>

            <div className="bankslip__form-item--currency">
              <label>валута</label>
              <input
                type="text"
                name=""
                className="bankslip__field-size--xxs bankslip-border-double"
              />
            </div>

            <div className="bankslip__form-item--value">
              <label>износ</label>
              <input
                type="text"
                name=""
                className="bankslip__field-size--s bankslip-border-double"
                defaultValue={`=${userCartStore?.totalPrice},00`}
                readOnly
              />
            </div>

            <div className="bankslip__form-item--account">
              <label>рачун примаоца</label>
              <input
                type="text"
                name=""
                className="bankslip__field-size--xl bankslip-border-double"
                defaultValue={`200-3568260101016-96`}
                readOnly
              />
            </div>

            <div className="bankslip__form-item--model">
              <label>модел</label>
              <input
                type="text"
                name=""
                className="bankslip__field-size--xxs bankslip-border-double"
              />
            </div>

            <div className="bankslip__form-item--reference">
              <label>позив на број (одобрење)</label>
              <input
                type="text"
                name=""
                className="bankslip__field-size--m bankslip-border-double"
                defaultValue={userCartStore?.user.user_id}
                readOnly
              />
            </div>

            <div className="bankslip__form-item--stamp">
              <input type="text" name="" className="bankslip__field-size--s" />
              <label>потпис и печат уплатиоца</label>
            </div>

            <div className="bankslip__form-item--date">
              <input type="text" name="" className="bankslip__field-size--s" />
              <label>место и датум пријема</label>
            </div>

            <div className="bankslip__form-item--date2">
              <input type="text" name="" className="bankslip__field-size--s" />
              <label>датум валуте</label>
            </div>

            <span className="bankslip__form-number">Образац бр. 1</span>
            <input
              type="text"
              className="bankslip__number"
              size={1}
              defaultValue="1"
            />
          </div>
          {/* /.bankslip__inner */}
        </form>
      </div>
      <div className="d-flex justify-content-center gap-3 flex-wrap mt-3">
        <Link to="/cart/payment" className="btn btn-outline-secondary">
          Nazad
        </Link>
        <button
          className="btn btn-outline-secondary"
          onClick={(e) => window.print()}
        >
          Štampaj
        </button>
        <Link
          to="/cart/purchase-confirmation?paymentSelected=bank"
          className="btn btn-success"
        >
          Potvrdi kupovinu
        </Link>
      </div>
    </div>
  );
};

export default PaymentBankPageComponent;
