import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../redux/loader.slicer';
import {
  GetAllAdminArticle,
  GetAllAdminCategory,
  SearchAllAdminArticle,
} from '../services/admin.service';
import { saveArticle } from '../redux/article.slicer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toggleModal } from '../redux/modal.slicer';

const ArticlePageComponent = () => {
  const [article, setArticle] = useState([]);
  const [category, setCategory] = useState([]);
  const [paginationObject, setPaginationObject] = useState({
    offset: 0,
    totalItems: 0,
    limit: 20,
  });
  const limitNum = [5, 10, 15, 20];
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.searchParams) {
      dispatch(toggleLoader(true));
      SearchAllAdminArticle({
        keyword: params.searchParams,
        limit: paginationObject.limit,
        offset: paginationObject.offset,
      })
        .then((res) => {
          setArticle(res.data.article);
          setPaginationObject(res.data.pagination);
        })
        .catch((err) => console.log(err))
        .finally(() => dispatch(toggleLoader(false)));
    } else {
      dispatch(toggleLoader(true));
      GetAllAdminArticle(paginationObject.limit, paginationObject.offset)
        .then((res) => {
          setArticle(res.data.article);
          setPaginationObject(res.data.pagination);
          dispatch(saveArticle(res.data.article)); // cuvamo article u redux
          GetAllAdminCategory()
            .then((res) => {
              setCategory(res.data);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err))
        .finally(() => dispatch(toggleLoader(false)));
    }
  }, [
    dispatch,
    paginationObject.limit,
    paginationObject.offset,
    params.searchParams,
  ]);

  const categoryName = (art) => {
    return category.map((cat) => {
      let name = '';
      if (art.category_id === cat.category_id) {
        name = cat.name;
      }
      return name;
    });
  };

  const renderArticle = () => {
    return article.map((art) => {
      return (
        <tr id={art.article_key} key={art.article_key}>
          <th scope="row">{categoryName(art)}</th>
          <td>{art.article_key}</td>
          <td>{art.name}</td>
          <td>{art.exceptt}</td>
          <td>{art.description}</td>
          <td>
            <select
              className="border-0"
              id="status"
              defaultValue={art.status}
              disabled={true}
            >
              <option value="available">Na stanju</option>
              <option value="visible">Nije dostupno</option>
              <option value="hidden">Sakriveno</option>
            </select>
          </td>
          <td>
            <select
              className="border-0"
              id="is_promoted"
              defaultValue={art.is_promoted}
              disabled={true}
            >
              <option value="0">Ne</option>
              <option value="1">Da</option>
            </select>
          </td>
          <td>{art.price}</td>
          <td>{art.article_price_updated_at}</td>
          <td className="d-flex flex-column gap-3">
            <Link
              className="btn btn-sm btn-outline-warning"
              to={`/dashboard/article/change/${art.article_id}`}
            >
              Izmeni
            </Link>
            <Link
              className="btn btn-sm btn-outline-info"
              to={`/dashboard/article/change-price/${art.article_id}`}
              onClick={() => dispatch(toggleModal(true))}
            >
              Cena
            </Link>
            <Link
              className="btn btn-sm btn-outline-danger"
              to={`/dashboard/article/delete/${art.article_id}`}
              onClick={() => dispatch(toggleModal(true))}
            >
              Obriši
            </Link>
          </td>
        </tr>
      );
    });
  };

  const onClickHandle = (num) => {
    setPaginationObject((prevState) => ({ ...prevState, offset: num }));
  };

  const onClickPrevious = () => {
    if (paginationObject.offset > 0) {
      setPaginationObject((prevState) => ({
        ...prevState,
        offset: prevState.offset - 1,
      }));
    }
  };

  const onClickNext = () => {
    let pagesNumber = Math.ceil(
      paginationObject.totalItems / paginationObject.limit
    );
    if (paginationObject.offset + 1 < pagesNumber) {
      setPaginationObject((prevState) => ({
        ...prevState,
        offset: prevState.offset + 1,
      }));
    }
  };

  const renderPaginationNumber = () => {
    let pagesNumber = Math.ceil(
      paginationObject.totalItems / paginationObject.limit
    );
    const numPagesDisplayed = 5; // Broj brojeva stranica koji će biti prikazan

    let pagesArray = [];
    let currentPage = paginationObject.offset + 1;

    // Logika za prikazivanje samo određenog broja stranica u paginaciji
    if (pagesNumber <= numPagesDisplayed) {
      // Ako je ukupan broj stranica manji ili jednak od broja stranica koji treba prikazati
      pagesArray = [...Array(pagesNumber).keys()].map((num) => num + 1);
    } else {
      // Ako je ukupan broj stranica veći od broja stranica koji treba prikazati
      let startPage = Math.max(
        1,
        currentPage - Math.floor(numPagesDisplayed / 2)
      );
      let endPage = Math.min(pagesNumber, startPage + numPagesDisplayed - 1);

      // Ako smo na početku paginacije
      if (startPage === 1) {
        endPage = numPagesDisplayed;
      }
      // Ako smo na kraju paginacije
      else if (endPage === pagesNumber) {
        startPage = pagesNumber - numPagesDisplayed + 1;
      }

      pagesArray = [...Array(endPage - startPage + 1).keys()].map(
        (num) => startPage + num
      );
    }

    return (
      <>
        {/* Link za navigaciju na prvu stranicu */}
        <li
          className="page-item"
          onClick={() => onClickHandle(0)}
          disabled={paginationObject.offset === 0}
        >
          <p className="page-link" aria-label="First">
            <span aria-hidden="true">««</span>
          </p>
        </li>
        {/* Prikaz brojeva stranica */}
        {pagesArray.map((num) => (
          <li
            key={'page-num-' + num}
            className={
              paginationObject.offset === num - 1
                ? 'active page-item'
                : 'page-item'
            }
            aria-current="page"
            onClick={() => onClickHandle(num - 1)}
          >
            <p className="page-link">{num}</p>
          </li>
        ))}
        {/* Link za navigaciju na poslednju stranicu */}
        <li
          className="page-item"
          onClick={() => onClickHandle(pagesNumber - 1)}
          disabled={paginationObject.offset === pagesNumber - 1}
        >
          <p className="page-link" aria-label="Last">
            <span aria-hidden="true">»»</span>
          </p>
        </li>
      </>
    );
  };


  const renderLimitDropDown = () => {
    return limitNum.map((num, index) => {
      return (
        <li key={index}>
          <p
            className="dropdown-item"
            onClick={() =>
              setPaginationObject((prevState) => ({
                ...prevState,
                limit: num,
                offset: 0,
              }))
            }
          >
            {num}
          </p>
        </li>
      );
    });
  };

  const onSearchSubmit = (e) => {
    e.preventDefault();
    if (!searchValue) return navigate('/dashboard/article/all-article');
    navigate(
      `/dashboard/article/all-article/${searchValue
        .replace(/\s+/g, '-')
        .toLowerCase()}`
    );
    //navigate(`/dashboard/article/${searchValue.replaceAll(' ', '-').toLowerCase()}`);
    //setSearchValue('');
  };

  return (
    <>
      <nav className="navbar bg-body-tertiary">
        <div className="container-fluid justify-content-center ">
          <form
            onSubmit={(e) => onSearchSubmit(e)}
            className="d-flex flex-column gap-2"
            role="search"
          >
            <div className="d-flex gap-3">
              <input
                className="form-control"
                type="search"
                name="keyword"
                placeholder="Pretraži"
                aria-label="Search"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <button className="btn btn-sm btn-outline-danger" type="submit">
                Pretraži
              </button>
            </div>
          </form>
        </div>
      </nav>
      <div className="table-responsive article-category-parent">
        <h2 className="fs-3 text-center mt-3">Proizvodi</h2>
        <div>
          <Link to="/dashboard/article/add">Dodaj proizvod</Link>
        </div>
        <table className="table table-sm table-hover">
          <thead>
            <tr>
              <th scope="col">Kategorija</th>
              <th scope="col">Šifra</th>
              <th scope="col">Ime</th>
              <th scope="col">Kratak opis</th>
              <th scope="col">Opis</th>
              <th scope="col">Status</th>
              <th scope="col">Na promociji</th>
              <th scope="col">Cena</th>
              <th scope="col">Cena promenjena</th>
              <th scope="col">Akcija</th>
            </tr>
          </thead>
          <tbody>{renderArticle()}</tbody>
        </table>
        <div className="d-flex flex-wrap gap-3 justify-content-center mt-3 pagination-holder">
          <div className="dropdown">
            <button
              className="btn btn-sm btn-outline-secondary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Limit {paginationObject.limit}
            </button>
            <ul className="dropdown-menu">{renderLimitDropDown()}</ul>
          </div>
          <nav aria-label="Page navigation">
            <ul className="pagination pagination-sm justify-content-center">
              <li className="page-item" onClick={() => onClickPrevious()}>
                <p className="page-link" aria-label="Previous">
                  <span aria-hidden="true">«</span>
                </p>
              </li>
              {renderPaginationNumber()}
              <li className="page-item" onClick={() => onClickNext()}>
                <p className="page-link" aria-label="Next">
                  <span aria-hidden="true">»</span>
                </p>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default ArticlePageComponent;
