import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import payment from '../assets/img/payment.jpg';

const PaymentPageComponent = () => {
  const userCartStore = useSelector((state) => state.userCartStore.userCart); // Uzima sve potrebno za porudzbinu
  const navigate = useNavigate();

  useEffect(() => {
    if (!userCartStore) {
      return navigate('/');
    }
  }, [userCartStore, navigate]);

  return (
    <div className="container-lg payment">
      <h5 className="text-center text-secondary mt-5 mb-1">
        Izaberite način plaćanja
      </h5>
      <div className="payment-logo">
        <img className="img-fluid" src={payment} alt="Payment" />
      </div>
      <div className="payment-method d-flex gap-3 justify-content-center flex-wrap ">
        <div className="payment-bank">
          <Link to="/cart/payment-bank" className="btn btn-success">
            Plati uplatnicom
          </Link>
        </div>
        <div className="payment-delivery-service">
          <Link to="/cart/delivery-service" className="btn btn-primary">
            Plati kuriru
          </Link>
        </div>
        <div className="payment-card">
          <Link to="/cart/card" className="btn btn-secondary">
            Plati karticom
          </Link>
        </div>
      </div>
      <div className="text-center mt-5">
        <Link to="/cart/pro-invoice" className="btn btn-outline-secondary">
          Nazad
        </Link>
      </div>
    </div>
  );
};

export default PaymentPageComponent;
