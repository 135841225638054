import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalComponents from '../components/modal/Modal.Components';
import { ChangeArticlePrice, GetAdminArticleById } from '../services/admin.service';
import { toggleLoader } from '../redux/loader.slicer';
import { toggleModal } from '../redux/modal.slicer';

const ArticleChangePricePageComponent = () => {
    const [article, setArticle] = useState({});
    const [price, setPrice] = useState({
      article_id: '',
      price: null,
    });
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
      dispatch(toggleLoader(true));
      GetAdminArticleById(params.article_id)
        .then((res) => {
          setArticle(res.data[0]);
          setPrice((prevState) => ({ ...prevState, article_id: params.article_id, price: res.data[0].price }));
        })
        .catch((err) => console.log(err))
        .finally(() => dispatch(toggleLoader(false)));
    }, [dispatch, params.article_id]);

    const handleChangePassword = (e) => {
      let inputValue = e.target.value;
      let copyPrice = { ...price, price: inputValue };
      setPrice(copyPrice);
    };

    const onChangeSubmit = (e) => {
      e.preventDefault();
      ChangeArticlePrice(price)
        .then((res) => {
          toast.success(res.data);
          dispatch(toggleModal(false));
          navigate(`/dashboard/article/category/${article.category_id}#${article.article_key}`);
        })
        .catch((err) => {
          toast.error(err.response.data);
        });
    };

  return (
    <ModalComponents>
      <div className="col-12 col-sm-6 m-auto my-3 text-start">
        <form onSubmit={onChangeSubmit}>
          <div className="mb-3">
            <label htmlFor="price" className="form-label">
              <p className="text-center text-danger">{article.name}</p>
              <p className="text-center text-success">
                Šifra: {article.article_key}
              </p>
              <p className="text-center text-secondary">
                Poslednja promena: {article.article_price_updated_at}
              </p>
              Unesi / Promeni cenu proizvoda:
            </label>
            <input
              type="number"
              className="form-control"
              id="price"
              aria-describedby="price"
              defaultValue={article?.price}
              onChange={(e) => handleChangePassword(e)}
            />
          </div>
          <button type="submit" className="btn btn-danger form-control">
            Sačuvaj
          </button>
          <Link
            to={`/dashboard/article/category/${article.category_id}#${article.article_key}`}
            className="btn btn-outline-secondary form-control mt-3"
          >
            Nazad
          </Link>
        </form>
      </div>
    </ModalComponents>
  );
}

export default ArticleChangePricePageComponent;