import React from 'react';
const termosolar = require('../../src/assets/img/termosolar.jpg');

const AboutUsPageComponent = () => {
  return (
    <div className="container-lg">
      <h1 className="text-center text-secondary fs-3 my-3">O nama</h1>
      <div className="text-center">
        <h2 className="text-danger fs-3 my-3">Djurdjević Termosolar</h2>
        <p>
          Firma Đurđević Termosolar je osnovana na Nikoljdan 19.12.1994. godine,
          kao SZR Đurđević.
        </p>
        <p>
          Osnovna delatnost firme je prodaja i montaža opreme za grejanje i
          klimatizaciju, solarnih sistema i aparata za filtriranje vode
          reverznom osmozom.
        </p>
        <p>Iza nas je oko hiljadu ugrejanih i klimatizovanih objekata.</p>
        <p>
          Pratili smo trendove u grejanju, tako da smo obuhvatili skoro sve
          vrste grejanja i sve vrste energenata.
        </p>
        <p>
          Krenuli smo od kotlova na čvrsto gorivo, pa preko gasa, peleta,
          toplotnih pumpi voda voda / vazduh voda, do solarnih sistema.
        </p>
        <p>
          Potpuno ili većim delom smo učestvovali u klimatizovanju svih
          začajnijih javnih objekata u Kikindi.
        </p>
        <p>
          To su zgrade skupštine opštine, bolnice, suda, kulturnog centra, MUP-a
          itd.
        </p>
        <p>
          Većina ugestiteljskih objekata u Kikindi ima naše aparate za
          filtriranje vode.
        </p>
        <p>Takođe sve više domaćinstava se opredeljuje da budu naši kupci.</p>
        <p>Mi redovno menjamo filtere i obezbeđujemo servis.</p>
        <p>
          Kroz firmu za ovo vreme je prošlo preko 30 radnika, a najviše u jednom
          trenutku smo imali 10 zaposlenih.
        </p>
        <p>
          Trudićemo se da i ubuduće budemo na usluzi našim vernim mušterijama.
        </p>
        <div className="text-secondary">
          <h3 className="fs-4">Podaci</h3>
          <p className="m-0">Tel. 0691022170; 0230306532</p>
          <p className="m-0">Svetosavska 66, 23300 Kikinda</p>
          <p className="m-0">PIB: 113425189</p>
          <p className="m-0">Matični broj: 21864617</p>
          <p className="m-0">Banka Poštanska Štedionica</p>
          <p className="m-0">Tekući račun: 200-3568260101016-96</p>
        </div>
      </div>
      <div className="text-center my-3 m-auto">
        <img
          src={termosolar}
          alt="Termosolar"
          className="img-fluid img-thumbnail"
        />
      </div>
    </div>
  );
};

export default AboutUsPageComponent;
