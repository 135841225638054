import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toggleLoader } from '../redux/loader.slicer';
import { AdminReacceptUserOrder, AdminUserDeleteOrder, GetAdminUser, GetAdminUserHistoryOrders } from '../services/admin.service';

const AdminUserOrderHistoryDetailsPageComponent = () => {
    const [orders, setOrders] = useState([]);
    const [user, setUser] = useState({});
    const [status, setStatus] = useState({ num: 1 });
    let priceArray = [];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
      dispatch(toggleLoader(true));
      GetAdminUserHistoryOrders(params.user_id)
        .then((res) => {
          setOrders(res.data);
        })
        .catch((err) => console.log(err))
        .finally(() => dispatch(toggleLoader(false)));
    }, [dispatch, params.user_id, status]);

    useEffect(() => {
      GetAdminUser(params.user_id)
        .then((res) => {
          setUser(res.data[0]);
        })
        .catch((err) => console.log(err));
    }, [orders, params.user_id]);

    const total = (item) => {
      let newPrice = parseInt(item.price) * parseInt(item.quantity);
      if (item.status !== 'rejected') {
        priceArray.push(newPrice);
      }
    };

    const renderTotalPrice = () => {
      let newPrice = null;
      for (let index = 0; index < priceArray.length; index++) {
        let element = priceArray[index];
        newPrice = newPrice + element;
      }
      return String(newPrice).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    };

    const orderStatus = (status) => {
      let text = null;
      switch (status) {
        case 'shipped':
          text = 'Poslato';
          break;
        case 'rejected':
          text = 'Odbijeno';
          break;
        default:
      }
      return text;
    };

    const reacceptOrder = (order_id, cart_id) => {
      AdminReacceptUserOrder(user.user_id, order_id, cart_id)
        .then((res) => {
          setStatus((prevState) => ({ ...prevState, num: prevState.num + 1 }));
          toast.success(res.data);
          if (orders.length < 1) {
            navigate('/dashboard/orders-history');
          }
        })
        .catch((err) => console.log(err));
    };

    const deleteOrder = (order_id, cart_id) => {
      AdminUserDeleteOrder(user.user_id, order_id, cart_id)
        .then((res) => {
          setStatus((prevState) => ({ ...prevState, num: prevState.num + 1 }));
          toast.success(res.data);
          if (orders.length < 2) {
            navigate('/dashboard/orders-history');
          }
        })
        .catch((err) => console.log(err));
    };

    const renderUserData = () => {
      return (
        <tbody>
          <tr>
            <td className="text-danger">{`${user.first_name} ${user.last_name}`}</td>
          </tr>
          <tr>
            <td className="text-danger">{user.email}</td>
          </tr>
          <tr>
            <td className="text-danger">{user.phone_number}</td>
          </tr>
          <tr>
            <td className="text-danger">{`${user.street} ${user.street_number}`}</td>
          </tr>
          <tr>
            <td className="text-danger">{`${user.postal_code} ${user.city}`}</td>
          </tr>
        </tbody>
      );
    };

    const renderArticlesData = () => {
      return orders.map((item, index) => {
        total(item);
        return (
          <tr key={item.article_key + '_' + index}>
            <th scope="row">{index + 1}</th>
            <td>{item.created_at}</td>
            <td>{item.article_key}</td>
            <td>{`${item.name.substring(0, 50)}`}</td>
            <td>{String(item.price).replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
            <td>{`${item.quantity}`}</td>
            <td>
              {String(item.price * item.quantity).replace(
                /\B(?=(\d{3})+(?!\d))/g,
                '.'
              )}
              ,00
            </td>
            <td
              className={
                item.status === 'shipped' ? 'text-primary' : 'text-danger'
              }
            >
              {orderStatus(item.status)}
            </td>
            <td>
              <button
                onClick={() => reacceptOrder(item.order_id, item.cart_id)}
                className="btn btn-sm btn-outline-secondary print-hide"
              >
                Poništi
              </button>
            </td>
            <td>
              <button
                onClick={() => deleteOrder(item.order_id, item.cart_id)}
                className="btn btn-sm btn-outline-danger print-hide"
              >
                Obriši
              </button>
            </td>
          </tr>
        );
      });
    };

  return (
    <>
      {orders.length > 0 && (
        <div className="container-lg admin-user-orders">
          <div className="text-center">
            <h1 className="fs-3 my-3">Poručilac</h1>
            <h5 className="text-secondary">{orders[0].user_id}</h5>
            <div className="header-line"></div>
            <div className="table-responsive">
              <table className="table">{renderUserData()}</table>
            </div>
          </div>
          <div className="header-line"></div>
          <h2 className="text-center text-secondary fs-4 my-3">
            Proizvodi
          </h2>
          <div className="table-responsive">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Od dana</th>
                  <th scope="col">Šifra</th>
                  <th scope="col">Ime</th>
                  <th scope="col">Cena</th>
                  <th scope="col">Kom</th>
                  <th scope="col">Ukupno</th>
                  <th scope="col">Status</th>
                  <th scope="col" className="print-hide">
                    #
                  </th>
                  <th scope="col" className="print-hide">
                    #
                  </th>
                </tr>
              </thead>
              <tbody>{renderArticlesData()}</tbody>
            </table>
          </div>
          <div className="text-end me-3">
            <h6 className="mt-3">
              <span className="text-secondary">Ukupno uplaćeno:</span>{' '}
              {renderTotalPrice() === 'null' ? 0 : renderTotalPrice()},00 dinara
            </h6>
          </div>
          <div className="d-flex flex-wrap gap-3 mt-3">
            <Link
              className="btn btn-outline-secondary print-hide"
              to={`/dashboard/orders-history#${orders[0].user_id}`}
            >
              Nazad
            </Link>
            <button
              className="btn btn-outline-info print-hide"
              onClick={(e) => window.print()}
            >
              Štampaj
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default AdminUserOrderHistoryDetailsPageComponent;