import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { activateAccount } from '../services/user.service';
import { toast } from 'react-toastify';

const ActivationAccountPageComponent = () => {
  const [message, setMessage] = useState('');
  const { user_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    activateAccount(user_id)
      .then((response) => {
        setMessage(
          'Uspešno aktiviran nalog. Preusmeravanje na stranicu za prijavu...'
        );
        toast.success('Uspešna aktivacija');
      })
      .catch((error) => {
        //console.log(error);
        toast.error(
          error.status === 405 ? error.message : error?.response?.data
        );
      })
      .finally(() => {
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      })
  }, [user_id, navigate]);

  return (
    <div className="d-flex justify-content-center align-content-center flex-column text-center p-3">
      <h1>Aktivacija naloga</h1>
      <p className="text-success">{message}</p>
    </div>
  );
};

export default ActivationAccountPageComponent;
